import { Inject, Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileLayoutService {
  constructor(private platform: Platform, @Inject(DOCUMENT) private doc: Document) {}

  chipsHeight = new Subject<number>();

  setChipsHeight(chipsHeight) {
    document.documentElement.style.setProperty('--chips-height', `${chipsHeight}`);
    this.chipsHeight.next(chipsHeight);
  }

  setPathElementsHeight(elementsHeight) {
    document.documentElement.style.setProperty('--path-height', `${elementsHeight}`);
  }

  compensate() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  addCompensationListener() {
    window.addEventListener('resize', () => {
      this.compensate();
    });
  }
}
