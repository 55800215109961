import { createAction, props } from '@ngrx/store';
import { AutocompleteParams, AutocompleteResult } from '@app/modules/autocomplete-client/autocomplete-api.models';

export const enum AutocompleteActionsTypes {
  FETCH_AUTOCOMPLETE = '[Autocomplete] Fetch Results',
  FETCH_AUTOCOMPLETE_FAILURE = '[Autocomplete] Failure',
  FETCH_AUTOCOMPLETE_SUCCESS = '[Autocomplete] Success',
  CLEAR_AUTOCOMPLETE = '[Autocomplete] Clear Results',
  SET_AUTOCOMPLETE_PARAMS = '[Autocomplete] Set Params'
}

export const fetchAutocompleteResults = createAction(
  AutocompleteActionsTypes.FETCH_AUTOCOMPLETE,
  props<{ searchParams: Partial<AutocompleteParams> }>()
);

export const fetchAutocompleteSuccess = createAction(
  AutocompleteActionsTypes.FETCH_AUTOCOMPLETE_SUCCESS,
  props<{ response: AutocompleteResult }>()
);

export const fetchAutocompleteFailure = createAction(
  AutocompleteActionsTypes.FETCH_AUTOCOMPLETE_FAILURE,
  props<{ fetchAutocompleteError: any }>()
);

export const clearAutocomplete = createAction(AutocompleteActionsTypes.CLEAR_AUTOCOMPLETE);

export const setAutocompleteParams = createAction(
  AutocompleteActionsTypes.SET_AUTOCOMPLETE_PARAMS,
  props<{ searchParams: AutocompleteParams }>()
);
