import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as PlacesAction from '../actions/places.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HereApiService } from '@app/modules/location/services/here-api.service';
import { of } from 'rxjs';
import { DataDogService } from '@app/services/data-dog.service';

@Injectable()
export class PlacesEffects {
  constructor(private actions$: Actions, private hereApiService: HereApiService, private datadog: DataDogService) {}

  loadSearchResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlacesAction.loadPlacesResult),
      mergeMap(({ params }) =>
        this.hereApiService.getAutoSuggestions(params).pipe(
          map(res => {
            const data = res;
            return PlacesAction.loadPlacesResultSuccess({ data });
          }),
          catchError(error => {
            this.datadog.addRumError(error);
            return of(PlacesAction.loadPlacesResultError({ error }));
          })
        )
      )
    )
  );
}
