import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromLayout from '../reducers/layout.reducer';

export const selectLayoutState = createFeatureSelector<fromLayout.LayoutState>(fromLayout.layoutFeatureKey);

export const selectViewContext = createSelector(selectLayoutState, state => {
  return state.view.context;
});

export const selectViewSubContext = createSelector(selectLayoutState, state => {
  return state.view.subContext;
});

export const selectViewPaneOpen = createSelector(selectLayoutState, state => {
  return state.view.paneOpen;
});
