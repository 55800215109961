import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-chip-selection-list',
  templateUrl: './chip-selection-list.component.html',
  styleUrls: ['./chip-selection-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChipSelectionListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  constructor() {}

  // Can be an input if a developer wants to input their own form control instead of requiring a ViewChild to grab this component's form control
  @Input() chipsCtrl: UntypedFormControl;
  @Input() options = [];
  @Input() label = 'Label';
  @Input() isMultiSelect = true;
  @Input() sortByTitle = false;

  @Output() getChips: EventEmitter<any> = new EventEmitter<any>();
  cachedOptions = [];
  chips = [];
  cachedOptions$ = new BehaviorSubject(this.cachedOptions);
  formGroup;
  noPointerEvents = true;
  private onDestroy$ = new Subject<void>();

  @ViewChild('searchBox') searchBox: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  ngOnInit(): void {
    if (!this.chipsCtrl) {
      this.chipsCtrl = new UntypedFormControl();
    }

    this.formGroup = new UntypedFormGroup({
      chipsCtrl: this.chipsCtrl
    });
    this.chipsCtrl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(val => {
      this.getOptionsBySearch(val);
    });
    if (this.chips.length) {
      this.getChips.emit(this.chips);
    }
    this.setSearchBox(this.cachedOptions);
  }

  getOptionsBySearch(val: string) {
    const query = val?.trim().toLowerCase();
    if (!query?.length) {
      this.options = this.cachedOptions;
      return;
    }
    this.options = this.cachedOptions.filter(o => o.title.toLowerCase().includes(query));
  }

  ngOnChanges(changes: SimpleChanges): void {
    let opts = changes.options.currentValue;
    if (changes.options.currentValue.length) {
      if (this.sortByTitle) {
        opts = changes.options.currentValue.slice().sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        });
      } else {
        opts = changes.options.currentValue.slice();
      }
    }
    this.options = opts;
    this.cachedOptions = opts;
    this.cachedOptions$.next(this.cachedOptions);

    this.cachedOptions$.pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      this.setSearchBox(res);
    });
  }

  setSearchBox(options) {
    if (this.searchBox) {
      if (!options.length) {
        this.formGroup?.controls.chipsCtrl.disable();
        this.noPointerEvents = true;
      } else {
        this.formGroup?.controls.chipsCtrl.enable();
        this.noPointerEvents = false;
      }
    }
  }

  ngAfterViewInit() {
    this.setSearchBox(this.cachedOptions);

    if (this.searchBox) {
      this.searchBox.nativeElement.onfocus = event => {
        this.autocomplete._onChange('');
        this.autocomplete.openPanel();
        if (event.target.value?.length) {
          event.target.value = '';
        }
      };
    }

    if (this.chips.length) {
      this.getChips.emit(this.chips);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  selected(event) {
    // if we this is a single choice searchbox, prevent dup choice from showing
    if (!this.isMultiSelect && this.chips.length === 1) {
      this.chips = [];
      this.chips.push({
        title: event.option.viewValue,
        value: event.option.value
      });
      this.getChips.emit(this.chips);
      return;
    }

    // prevent dups
    if (!this.chips.map(c => c?.value).includes(event.option.value)) {
      this.chips.push({
        title: event.option.viewValue,
        value: event.option.value
      });
    }
    this.getChips.emit(this.chips);
  }

  remove(chip) {
    this.chips = this.chips.filter(c => c.value !== chip.value);
    this.getChips.emit(this.chips);
  }
}
