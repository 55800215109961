import { createAction, props } from '@ngrx/store';
import { DetailsSubcontext, ViewContext } from '@app/store/layout/reducers/layout.reducer';

export const enum LayoutActionTypes {
  SET_VIEW_CONTEXT = '[Layout] Set View Context',
  SET_VIEW_SUBCONTEXT = '[Layout] Set View Sub-context',
  SET_VIEW_FILTERS_OPEN = '[Layout] Set View Filters Open',
  SET_VIEW_PANE_OPEN = '[Layout] Set View Pane Open',
  SET_SHOW_ZUI_HEADER = '[Layout] Set Show Zui Header',
  TOGGLE_VIEW_PANE_OPEN = '[Layout] Toggle View Pane Open'
}

export const setViewContext = createAction(LayoutActionTypes.SET_VIEW_CONTEXT, props<{ context: ViewContext }>());

export const setViewSubContext = createAction(
  LayoutActionTypes.SET_VIEW_SUBCONTEXT,
  props<{ subContext: DetailsSubcontext }>()
);

export const setViewFiltersOpen = createAction(
  LayoutActionTypes.SET_VIEW_FILTERS_OPEN,
  props<{ filtersOpen: boolean }>()
);

export const setViewPaneOpen = createAction(LayoutActionTypes.SET_VIEW_PANE_OPEN, props<{ paneOpen: boolean }>());

export const toggleViewPaneOpen = createAction(LayoutActionTypes.TOGGLE_VIEW_PANE_OPEN);
