import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { environment } from '@environments/environment';
import { Subject } from 'rxjs';
import { take, map } from 'rxjs/operators';
import * as deepEqual from 'fast-deep-equal';
import { FilterChangeEvent, FilterDialogState, FilterProps } from '@app/modules/location/models/filter-dialog.model';
import { EntityResource, ToggleFilterEvent } from '@app/store/filters/models/filters.model';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { Zone } from '@app/modules/zones/zones.model';
import { SettingsApiService } from '@app/services/settings-api.service';
import { LeafletZoneService } from '@app/modules/zones/services/leaflet-zone.service';
import { MapSettings } from '@app/modules/location/models/settings.model';
import { LeafletService } from '@app/modules/location/services/leaflet.service';
import { FeatureToggleService } from '@app/modules/feature-toggles/services/feature-toggle.service';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterDialogComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    public locationFacade: LocationFacade,
    public translations: Translations,
    public translateService: TranslateService,
    private leafletZoneService: LeafletZoneService,
    private leafletService: LeafletService,
    private settingsService: SettingsApiService,
    public featureToggleService: FeatureToggleService
  ) {}

  disableButtons = true;
  filterState: FilterDialogState;
  defaultFilter: FilterDialogState;
  divisionsWithLocations: EntityResource[];
  destroyed$ = new Subject();
  isZonarUser: boolean;
  initialPowerOnFilter: boolean;
  zone: Zone;
  zonesEnabled: boolean;
  ngOnInit() {
    this.locationFacade
      .getUserPermissions()
      .pipe(take(1))
      .subscribe(perm => {
        this.isZonarUser = perm.isZonarUser;
      });

    this.locationFacade
      .getAllFilters()
      .pipe(take(1))
      .subscribe(filters => {
        const { filter, defaultFilter } = filters;
        this.initialPowerOnFilter = filter.powerOn;
        this.filterState = <FilterDialogState>{
          powerOn: filter.powerOn,
          company: filter.company?.id,
          divisions: filter.divisions?.map(d => d.id),
          locations: filter.locations?.map(l => l.id),
          zone: filter.zone
        };

        this.defaultFilter = <FilterDialogState>{
          powerOn: defaultFilter.powerOn,
          company: defaultFilter?.company?.id,
          divisions: defaultFilter?.divisions?.map(d => d.id),
          locations: defaultFilter?.locations.map(l => l.id),
          zone: defaultFilter.zone
        };

        this.disableButtons = deepEqual(this.filterState, this.defaultFilter);
      });
  }

  getCurrentCompany() {
    return this.locationFacade.getAllFilters().pipe(
      map(filters => {
        return filters.filter.company;
      })
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  clearFilters() {
    this.getCurrentCompany()
      .pipe(take(1))
      .subscribe(company => {
        this.locationFacade.clearFilters();
        this.locationFacade.setCompanyFilter(company.id);
        this.locationFacade.setAssets({
          companyId: company.id,
          pageSize: environment.liveUpdate.pageSize
        });
        this.close();
      });
  }

  applyFilters() {
    if (this.zone) {
      this.locationFacade.setZoneFilter(this.zone);
      this.leafletZoneService.addZonesToMap(this.leafletService.map);
      this.settingsService.saveSetting(MapSettings.MAP_ZONES, 'true').pipe(take(1)).subscribe();
    }
    this.locationFacade.applyFilters(this.filterState);
    this.close();
  }

  toggleFilterChange(event: ToggleFilterEvent) {
    this.filterState = { ...this.filterState, [event.filter]: event.toggled };
    this.disableButtons = false;
  }

  updateFilterLocations(divisionIds: string[]) {
    if (!this.isZonarUser) {
      // this early return maintains checkbox state of locations when user re-opens dialog
      if (this.filterState.locations?.length) {
        return;
      }
      if (divisionIds?.length === 1) {
        this.locationFacade.getLocationsForLimitedUser(divisionIds as string[]);
      }
    } else {
      this.locationFacade.setDivisionFilter(divisionIds as string[]);
    }
  }

  dropdownFilterChange(event: FilterChangeEvent) {
    const { entity, value } = event;
    this.filterState = { ...this.filterState, [entity]: value };

    switch (entity) {
      case FilterProps.COMPANY:
        const companyValue = Array.isArray(value) ? value[0] : value;
        this.filterState = { ...this.filterState, company: companyValue };
        break;
      case FilterProps.DIVISIONS:
        const divisionIds = Array.isArray(value) ? value : [value];
        this.updateFilterLocations(divisionIds);
        // get an array of the unique divisions after this new division is added
        const uniqueDivisions = [...new Set([...this.filterState.divisions, ...value])];
        this.filterState = { ...this.filterState, divisions: uniqueDivisions };
        break;
      case FilterProps.LOCATIONS:
        // get an array of the unique locations after this new location is added
        const uniqueLocations = [...new Set([...this.filterState.locations, ...value])];
        this.filterState = { ...this.filterState, locations: uniqueLocations };
        break;
    }
    this.disableButtons = false;
  }

  onZoneFilterChange(event) {
    this.filterState = { ...this.filterState, zone: event.id };
    this.zone = event as Zone;
    this.disableButtons = false;
  }
}
