import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureToggleDirective } from './directives/feature-toggle.directive';

@NgModule({
  imports: [CommonModule],
  exports: [FeatureToggleDirective],
  declarations: [FeatureToggleDirective]
})
export class FeatureTogglesModule {}
