<mat-dialog-title fxLayout="row" fxLayoutAlign="space-between">
 <!--
   <h2>Filters</h2> -->
   <h2>{{ translateService.get(translations.appFilterDialog.title) | async | translate }}</h2>

    <button
            class="clear-btn"
            mat-icon-button
            aria-label="Clear"
            (click)="close()">
        <mat-icon>clear</mat-icon>
    </button>
</mat-dialog-title>

<mat-dialog-content class="mat-typography">
  <div class="toggle-filters-container">
    <app-toggle-filters [initialFilterState]="filterState"
                        (toggleFilterChange)="toggleFilterChange($event)"></app-toggle-filters>
  </div>
  <div *appIfFeatureEnabled="'zones-single-filter'" class="filter-container">
    <app-zones-filter (zonefilterChange)="onZoneFilterChange($event)"></app-zones-filter>
  </div>
  <div class="filter-container">
    <app-company-filters (filterChange)="dropdownFilterChange($event)"></app-company-filters>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button color="accent" [disabled]="disableButtons" (click)="clearFilters()">{{ translateService.get(translations.appFilterDialog.clear) | async | translate }}</button>
  <button mat-stroked-button color="accent" [disabled]="disableButtons" (click)="applyFilters()">{{ translateService.get(translations.appFilterDialog.done) | async | translate }}</button>
</mat-dialog-actions>
