import * as permissionsActions from '@app/store/permissions/actions/permissions.actions';
import { createReducer, on } from '@ngrx/store';
import { initialPermissionsState, PermissionsState } from '@app/store/permissions/models/permissions.models';

export const permissionsFeatureKey = 'permissions';

export const reducer = createReducer(
  initialPermissionsState,

  on(permissionsActions.savePermissions, (state: PermissionsState, permissions) => {
    return { ...state, ...permissions };
  }),

  on(permissionsActions.saveEntities, (state: PermissionsState, { companies, divisions }) => {
    return { ...state, companies, divisions };
  })
);
