import { FeatureGroup, LatLng } from 'leaflet';
import { ReverseGeocoderData } from '@app/modules/reverse-geocoder/services/models/reverse-geocoder-response.model';
import { DriverProfile } from '@app/modules/driver/models/driver.models';

export enum PathStatusTypes {
  DRIVING = 'Driving',
  IDLING = 'Idling',
  EQUIPMENT = 'Equipment'
}
export enum PathSpeedUnits {
  MPH = 'MPH',
  KPH = 'KM/H'
}
export interface PathFeature {
  type: string;
  geometry: PathGeometry;
  properties: PathProperties;
}
export interface PathGeometry {
  type: string;
  coordinates: LatLng;
}

export interface PathInput {
  value: boolean;
  pin: string;
  change: boolean;
}

export interface ExtendedPathInput extends PathInput {
  label: string;
}

export interface PathProperties extends ReverseGeocoderData {
  acceleration: number;
  assetId: string;
  companyId: string;
  ecmConnected: boolean;
  geoHash: string;
  heading: number;
  id: string;
  inputs?: { [key: string]: PathInput }[];
  inputsFormatted?: ExtendedPathInput[];
  odometer: number;
  powerOn?: boolean;
  speed: number;
  speedUnits: string;
  state: PathStatusTypes;
  stateOfCharge?: number;
  ztrakBatteryCondition?: number;
  timeStamp: string;
  driverProfile?: DriverProfile;
}

export interface IoCount {
  label: string;
  count: number;
}

export interface PathSummary {
  duration: number;
  avgSpeed: number;
  speedUnits: string;
  startTime: Date;
  endTime: Date;
  distance: number;
  numberOfStops: number;
  ioCounts?: IoCount[];
  powerOffCount?: number;
}

export interface Path {
  summary: PathSummary;
  pointData: FeatureGroup & { features?: PathFeature[] };
}

export enum PathExportHeaders {
  ASSET_NUMBER = 'Asset Number',
  DATE = 'Date',
  TIME = 'Time',
  SPEED = 'Speed',
  HEADING = 'Heading',
  LOG_REASON = 'Log Reason',
  DISTANCE_TRAVELED = 'Distance Traveled',
  LAT = 'Lat',
  LONG = 'Long',
  ADDRESS = 'Address',
  SOURCE = 'Source',
  DRIVER = 'Assigned Driver Name',
  STATE_OF_CHARGE = 'State of Charge',
  BATTERY_CONDITION = 'GPS Battery Life'
}

export interface PathExportPoint {
  [PathExportHeaders.ASSET_NUMBER]: string;
  [PathExportHeaders.DATE]: string;
  [PathExportHeaders.TIME]: string;
  [PathExportHeaders.SPEED]?: number;
  [PathExportHeaders.HEADING]?: string;
  [PathExportHeaders.LOG_REASON]: string;
  [PathExportHeaders.DISTANCE_TRAVELED]: number;
  [PathExportHeaders.LAT]: number;
  [PathExportHeaders.LONG]: number;
  [PathExportHeaders.SOURCE]: string;
  [PathExportHeaders.ADDRESS]: string;
  [PathExportHeaders.STATE_OF_CHARGE]?: number;
  [PathExportHeaders.BATTERY_CONDITION]?: string;
}

export enum PathAssetDataSource {
  ECM = 'ECM',
  GPS = 'GPS'
}

export enum PathExportLogReasons {
  STANDARD = 'Standard',
  POWER_ON = 'Power On',
  POWER_OFF = 'Power Off',
  MOTION_START = 'Motion Start',
  MOTION_STOP = 'Motion Stop'
}
export enum PathTimeLineStatus {
  POWERED_OFF = 'powered-off',
  IDLE = 'idle',
  IN_MOTION = 'in-motion'
}
export interface TimeLinePoint extends PathFeature {
  powerOn: boolean;
  state: string;
  timeStamp: string;
  status: string;
  isFirst?: boolean;
  isLast?: boolean;
  properties: PathProperties;
  hover?: boolean;
  markerSrc: string;
  tripEnd?: boolean;
  tripStart?: boolean;
  setStatusAndMarker?: Function;
  checkMinIdleTime?: Function;
  falsePowerEvent?: boolean;
  isZTrak?: boolean;
}
export interface PoweredOffPoint {
  index: number;
  point: TimeLinePoint;
  nextPoint: TimeLinePoint;
}
export interface AdditionalPoint {
  index: number;
  points: TimeLinePoint[];
}

export interface TimeLine {
  pathPoints: PathFeature[];
  segmentSelected: boolean;
  segmentIndex: number;
}
export interface TimeLinePathPoint {
  pathPoints: TimeLinePoint[];
  segmentSelected: boolean;
  segmentIndex: number;
}

export enum DistanceUnits {
  MILE = 'mi',
  KM = 'km'
}
