import { Component, Input, OnInit } from '@angular/core';
import { OpenEvent, EventSeverity } from '@app/modules/location-client/location-api.models';
import { EventsService } from '@app/modules/events/events.service';

@Component({
  selector: 'app-event-badge',
  templateUrl: './event-badge.component.html',
  styleUrls: ['./event-badge.component.scss']
})
export class EventBadgeComponent implements OnInit {
  @Input() severity: EventSeverity = undefined;
  @Input() label: string = undefined;
  @Input() events: OpenEvent[] = undefined;

  constructor(public es: EventsService) {}

  ngOnInit() {
    if (this.events) {
      this.label = this.es.getLabelFromEvents(this.events);
      this.severity = this.es.getSeverityFromEvents(this.events);
    }
  }
}
