import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpCancelService {
  public pendingLocApiHTTPRequests$: Subject<void> = new Subject<void>();
  public pendingPathApiHTTPRequests$: Subject<void> = new Subject<void>();

  constructor() {}

  public cancelLocApiPendingRequests() {
    this.pendingLocApiHTTPRequests$.next();
  }

  public onCancelLocApiPendingRequests() {
    return this.pendingLocApiHTTPRequests$.asObservable();
  }

  public cancelPathApiPendingRequests() {
    this.pendingPathApiHTTPRequests$.next();
  }

  public onCancelPathApiPendingRequests() {
    return this.pendingPathApiHTTPRequests$.asObservable();
  }
}
