import { FiltersState } from '@app/store/filters/models/filters.model';
import { filtersFeatureKey } from '@app/store/filters/reducers/filters.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectSearchFiltersState = createFeatureSelector<FiltersState>(filtersFeatureKey);

export const selectCompaniesSearchFilterOptions = createSelector(selectSearchFiltersState, (state: FiltersState) => {
  return state.companies;
});
export const selectCompaniesLoadingState = createSelector(selectSearchFiltersState, (state: FiltersState) => {
  return state.companiesLoadingState;
});
export const selectDivisions = createSelector(selectSearchFiltersState, (state: FiltersState) => {
  return state.divisions;
});
export const selectDivisionsLoadingState = createSelector(selectSearchFiltersState, (state: FiltersState) => {
  return state.divisionsLoadingState;
});

export const selectLocationsLoadingState = createSelector(selectSearchFiltersState, (state: FiltersState) => {
  return state.locationsLoadingState;
});

export const selectDivision = createSelector(
  selectSearchFiltersState,
  (state: FiltersState, props: { divisionId: string }) => {
    const division = state.divisions.find(d => d.id === props.divisionId);
    return division;
  }
);

export const selectDivisionsSearchFilterOptions = createSelector(selectSearchFiltersState, (state: FiltersState) => {
  return state.divisions.map(d => ({
    title: d.legacyAccountCode || d.name,
    value: d.id
  }));
});

export const selectLocationsSearchFilterOptions = createSelector(selectSearchFiltersState, (state: FiltersState) => {
  return state.locations.map(d => ({
    title: d.name,
    value: d.id
  }));
});
