import { Directive, ViewContainerRef, TemplateRef, Input, OnDestroy } from '@angular/core';
import { FeatureToggleService } from '@app/modules/feature-toggles/services/feature-toggle.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appIfFeatureEnabled]'
})
export class FeatureToggleDirective implements OnDestroy {
  private hasView = false;
  private onDestroy$ = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private featureToggleService: FeatureToggleService
  ) {}

  @Input() public set appIfFeatureEnabled(feature: string) {
    if (!feature) {
      return;
    }
    this.featureToggleService
      .isFeatureEnabled(feature)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(featureEnabled => {
        if (featureEnabled && !this.hasView) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (!featureEnabled && this.hasView) {
          this.viewContainerRef.clear();
          this.hasView = false;
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
