import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { FiltersComponent } from '@app/modules/shared/components/filters/filters.component';
import { ChipSelectionListComponent } from '@app/modules/shared/components/chip-selection-list/chip-selection-list.component';
import { FilterChangeEvent, FilterProps } from '@app/modules/location/models/filter-dialog.model';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { PermissionsService } from '@zonar-ui/auth';

@Component({
  selector: 'app-company-filters',
  templateUrl: './company-filters.component.html',
  styleUrls: ['./company-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyFiltersComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    public locationFacade: LocationFacade,
    public translations: Translations,
    public translateService: TranslateService,
    public permissionsService: PermissionsService
  ) {}

  @Output() filterChange: EventEmitter<FilterChangeEvent> = new EventEmitter();

  searchFiltersDivisionsCount = new BehaviorSubject(0);

  @ViewChild(FiltersComponent, { static: false })
  filterComponent: FiltersComponent;
  @ViewChildren(ChipSelectionListComponent) chipSelectionListComponents: QueryList<ChipSelectionListComponent>;
  @ViewChild('locationFilter') locationFilter: ChipSelectionListComponent;
  @ViewChild('accountFilter') accountFilter: ChipSelectionListComponent;
  private onDestroy$ = new Subject<void>();

  companyAutocompleteOptions = [];
  accountAutocompleteOptions = [];
  locationAutocompleteOptions = [];
  isZonarUser: boolean;

  ngOnInit() {
    this.locationFacade
      .getCompaniesSearchFilterOptions()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(companies => {
        this.companyAutocompleteOptions = companies;
      });

    this.locationFacade
      .getDivisionsSearchFilterOptions()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(divisions => {
        this.accountAutocompleteOptions = divisions;
        this.searchFiltersDivisionsCount.next(divisions?.length);
      });

    this.locationFacade
      .getLocationsOptionList()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(locations => {
        this.locationAutocompleteOptions = locations;
      });

    // this subscription needed in order to populate a selected location when user reopens dialogue, with the deletion of the "filter" RxJs action in loadLocationsOnSetDivisionsFilter$ in filter.effects, this no longer worked otherwise
    this.locationFacade
      .getAllFilters()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(filters => {
        if (filters.filter.locations.length) {
          const locations = filters.filter.locations.map(loc => {
            return loc.id;
          });
        }
      });
  }

  ngAfterViewInit() {
    // sets the "isZonarUser" boolean for this component
    this.permissionsService
      .getIsZonarUser()
      .pipe(take(1))
      .subscribe(z => (this.isZonarUser = z));

    // various things to do after init to change filters accordingly

    // this subscription persists chips in the filters if a user made selections and closed/reopened filter dialog
    this.locationFacade
      .getAllFilters()
      .pipe(take(1))
      .subscribe(filters => {
        // set division chips if divs already chosen
        if (filters?.filter?.divisions.length) {
          const accounts = filters.filter.divisions.map(loc => {
            return {
              title: loc.name,
              value: loc.id
            };
          });
          if (this.accountFilter) {
            this.accountFilter.chips = accounts;
          }
        }

        // set location chips if locs already chosen
        if (filters?.filter?.locations.length) {
          const locations = filters.filter.locations.map(loc => {
            return {
              title: loc.name,
              value: loc.id
            };
          });

          if (this.locationFilter) {
            this.locationFilter.chips = locations;
          }
        }
      });

    // TODO: this observable will need extra functionality added when we transfer searchable dropdown move to Zonar users
    this.filterChange
      .asObservable()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(filterChangeEmissions => {
        // unset accounts if a company is chosen
        if (filterChangeEmissions.entity === FilterProps.COMPANY) {
          // First, clear out any populated accounts or locations
          this.locationFacade.getDivisionsByCompanyId(filterChangeEmissions.value as string);
        }

        // unset locations filter if accounts filter has 0 or <1 choices
        if (filterChangeEmissions.entity === FilterProps.DIVISIONS) {
          if (filterChangeEmissions.value.length !== 1) {
            if (this.locationFilter) {
              this.locationFilter.chips = [];
              this.locationAutocompleteOptions = [];
            }
          }
        }
      });
  }

  ngOnDestroy() {
    this.resetAll();
  }

  getFilterChanges(formGroupChanges) {
    Object.keys(formGroupChanges)
      .filter(fieldName => formGroupChanges[fieldName])
      .forEach(fieldName => {
        this.filterChange.emit({
          entity: fieldName as FilterProps,
          value: formGroupChanges[fieldName]
        });
      });
  }

  resetAll() {
    this.onDestroy$.next();
  }

  getChips(chips, entity) {
    this.filterChange.emit({
      entity,
      value: chips.map(chip => chip.value),
      options: chips
    });
  }
}
