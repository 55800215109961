import { Injectable } from '@angular/core';
import { ReverseGeocoderService } from '@app/modules/reverse-geocoder/services/reverse-geocoder.service';
import { PathFeature, PathProperties, PathStatusTypes } from '@app/modules/location/models/path-api.model';
import * as util from '@app/modules/shared/utilities/utilities';
import { getCityStateZipText } from '@app/modules/shared/utilities/utilities';
import { DivIcon, featureGroup, FeatureGroup, latLng, LatLng, Marker } from 'leaflet';
import { LeafletMarkerAsset } from '@app/modules/location/models/leaflet-marker.asset';
import { concatMapTo, withLatestFrom } from 'rxjs/operators';
import { filter, timer, Subject } from 'rxjs';
import { TripFeature } from '@app/modules/location/models/trip.model';
import { AssetIoService } from '@app/modules/asset-io/asset-io.service';
import { DriverFacade } from '@app/modules/driver/facade/driver.facade';
import { DriverProfile } from '@app/modules/driver/models/driver.models';
import { UiUtilities } from '@app/services/ui-utilities';
import { LocalizationService } from '@app/services/localization.service';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { EnvironmentService } from '@app/services/environment.service';

interface PathMarkerConfig {
  iconClass: string;
  imgSrc: string;
  altText: string;
  zIndexOffset: number;
}

@Injectable({
  providedIn: 'root'
})
export class PathLayerConfigService {
  constructor(
    private assetIoService: AssetIoService,
    private driverFacade: DriverFacade,
    private envService: EnvironmentService,
    private localizationService: LocalizationService,
    private reverseGeocoderService: ReverseGeocoderService,
    private translateService: TranslateService,
    private translations: Translations,
    private uiUtils: UiUtilities
  ) {
    this.translations.translationsLoadState
      .pipe(filter(loadstate => loadstate === ResourceLoadState.LOAD_SUCCESSFUL))
      .subscribe(() => {
        this.translated = this.translateService.instant([
          this.translations.map.pathLayerConfigService.errorMsg,
          this.translations.map.pathLayerConfigService.startOfTrip,
          this.translations.map.pathLayerConfigService.endOfTrip,
          this.translations.map.pathLayerConfigService.zTrakLoc,
          this.translations.map.pathLayerConfigService.poweredOff,
          this.translations.map.pathLayerConfigService.idling,
          this.translations.map.pathLayerConfigService.driving,
          this.translations.map.pathLayerConfigService.connectionError,
          this.translations.uiUtilities.ztrakBatteryCondition.gpsBatteryLife
        ]);
        this.errorMessage = this.translated[this.translations.map.pathLayerConfigService.errorMsg];
        this.startMarkerConfig.altText = this.translated[this.translations.map.pathLayerConfigService.startOfTrip];
        this.endMarkerConfig.altText = this.translated[this.translations.map.pathLayerConfigService.endOfTrip];
        this.zTrakMarkerConfig.altText = this.translated[this.translations.map.pathLayerConfigService.zTrakLoc];
        this.poweredOffMarkerConfig.altText = this.translated[this.translations.map.pathLayerConfigService.poweredOff];
        this.idlingMarkerConfig.altText = this.translated[this.translations.map.pathLayerConfigService.idling];
        this.inMotionMarkerConfig.altText = this.translated[this.translations.map.pathLayerConfigService.driving];
        this.connectionErrorMarkerConfig.altText =
          this.translated[this.translations.map.pathLayerConfigService.connectionError];
      });
  }
  private translated;

  private placeIcon = '<span class="material-icons">place</span>';
  private nearMeIcon = '<span class="material-icons">near_me</span>';
  private queryBuilderIcon = '<span class="material-icons">query_builder</span>';
  private taskAltIcon = '<span class="material-icons">task_alt</span>';
  private blankIcon = '<span class="material-icons">_</span>';
  private personIcon = '<span class="material-icons">person</span>';
  private evStationIcon = '<span class="material-icons">ev_station</span>';
  private batteryConditionIcon = '<span class="material-icons">battery_full</span>';

  private pathMarkerIconClass = 'path-marker-icon _pendo-synth';
  private pathContainerIconClass = 'path-marker-container _pendo-synth';

  private loadingSkeleton = '<span class="loading-skeleton"></span>';
  private errorMessage = 'An error occurred. Try again later.';

  private addressLineClass = 'address-text';

  private reverseGeocodingDelay = 1000; // milliseconds

  private startMarkerConfig: PathMarkerConfig = {
    iconClass: 'path-marker-start',
    imgSrc: LeafletMarkerAsset.PATH_START,
    altText: 'Start of trip',
    zIndexOffset: 0
  };

  private endMarkerConfig: PathMarkerConfig = {
    iconClass: 'path-marker-end',
    imgSrc: LeafletMarkerAsset.PATH_END,
    altText: 'End of trip',
    zIndexOffset: 0
  };

  private zTrakMarkerConfig: PathMarkerConfig = {
    iconClass: 'path-marker-ztrak',
    imgSrc: LeafletMarkerAsset.GREY_DOT,
    altText: 'ZTrak location',
    zIndexOffset: 0
  };

  private poweredOffMarkerConfig: PathMarkerConfig = {
    iconClass: 'path-marker-powered-off',
    imgSrc: LeafletMarkerAsset.POWERED_OFF,
    altText: 'Powered off',
    zIndexOffset: 9999
  };

  private idlingMarkerConfig: PathMarkerConfig = {
    iconClass: 'path-marker-idling',
    imgSrc: LeafletMarkerAsset.IDLING,
    altText: 'Idling',
    zIndexOffset: -9999
  };

  private inMotionMarkerConfig: PathMarkerConfig = {
    iconClass: 'path-marker-in-motion',
    imgSrc: '', // the imgSrc for in-motion icons is dynamic. it changes based on the heading of the asset
    altText: 'Driving',
    zIndexOffset: 0
  };

  private connectionErrorMarkerConfig: PathMarkerConfig = {
    iconClass: 'path-marker-idling',
    imgSrc: LeafletMarkerAsset.CONNECTION_ERROR,
    altText: 'Connection error',
    zIndexOffset: -9999
  };

  private ioMarkerImgSrc = [
    LeafletMarkerAsset.IO_1,
    LeafletMarkerAsset.IO_2,
    LeafletMarkerAsset.IO_3,
    LeafletMarkerAsset.IO_4,
    LeafletMarkerAsset.IO_5
  ];

  private sharedIoMarkerConfig = {
    iconClass: 'path-marker-io',
    zIndexOffset: 1
  };

  onMouseOver$ = new Subject();

  recentPathGroupOptions = {
    pointToLayer: (feature, latlng) => {
      const { properties } = feature;

      if (feature.isZTrak) {
        return this.createZTrakPathMarker(latlng);
      }

      if (feature.isFirst) {
        return this.createPathStartMarker(latlng);
      }

      if (feature.isLast) {
        return this.createPathEndMarker(latlng);
      }

      if (properties.powerOn === false && !feature.falsePowerEvent) {
        return this.createPathPoweredOffMarker(latlng);
      }

      if (properties.state === PathStatusTypes.IDLING) {
        const marker = this.createPathIdlingMarker(latlng);

        marker.setOpacity(0);

        marker.on('popupopen', e => {
          marker.setOpacity(1);
        });

        marker.on('popupclose', e => {
          marker.setOpacity(0);
        });

        return marker;
      }
    },
    coordsToLatLng(coords: [number, number] | [number, number, number]): LatLng {
      return new LatLng(coords[0], coords[1], coords[2]);
    },
    onEachFeature: (feature: PathFeature, layer) => {
      layer.bindPopup(this.getRecentPathPopupLoading(feature.properties));

      layer.on('popupopen', () => {
        this.onMouseOver$.next(feature);
        this.reverseGeocodePopup(layer, feature, false);
      });

      layer.on('mouseover', () => {
        layer.openPopup();
      });
    }
  };

  private createPathMarker = (latlng: LatLng, pathMarkerConfig: PathMarkerConfig): Marker => {
    const { iconClass, imgSrc, altText, zIndexOffset } = pathMarkerConfig;
    return new Marker(latlng, {
      icon: new DivIcon({
        html: `<img class="${this.pathMarkerIconClass} ${iconClass}" src="${imgSrc}" alt="${altText}"/>`,
        className: `${this.pathContainerIconClass}`,
        popupAnchor: [0, -14]
      }),
      zIndexOffset: zIndexOffset
    });
  };

  private createPathStartMarker = (latlng: LatLng): Marker => {
    return this.createPathMarker(latlng, this.startMarkerConfig);
  };

  private createPathEndMarker = (latlng: LatLng): Marker => {
    return this.createPathMarker(latlng, this.endMarkerConfig);
  };

  private createZTrakPathMarker = (latlng: LatLng): Marker => {
    return this.createPathMarker(latlng, this.zTrakMarkerConfig);
  };

  private createPathPoweredOffMarker = (latlng: LatLng): Marker => {
    return this.createPathMarker(latlng, this.poweredOffMarkerConfig);
  };

  private createPathIdlingMarker = (latlng: LatLng): Marker => {
    return this.createPathMarker(latlng, this.idlingMarkerConfig);
  };

  private createPathConnectionErrorMarker = (latlng: LatLng): Marker => {
    return this.createPathMarker(latlng, this.connectionErrorMarkerConfig);
  };

  createPathInMotionMarker = (latlng: LatLng, heading): Marker => {
    const compassIcon = this.uiUtils.getCompassforIcon(heading);
    const suffix = this.envService.getEnvironment()?.region === util.EUROPE ? 'VDO-' : '';
    const imgSrc = `${LeafletMarkerAsset.IN_MOTION_ROOT}${suffix}${compassIcon}.png`;
    return this.createPathMarker(latlng, { ...this.inMotionMarkerConfig, imgSrc });
  };

  getIoMarkerConfig(i: number, label: string): PathMarkerConfig {
    return {
      ...this.sharedIoMarkerConfig,
      imgSrc: this.ioMarkerImgSrc[i],
      altText: `IO ${i + 1}: ${label}`
    };
  }

  createIoMarker = (latlng: LatLng, i: number, label: string): Marker => {
    const ioMarkerConfig = this.getIoMarkerConfig(i, label);
    return this.createPathMarker(latlng, ioMarkerConfig);
  };

  createIoFeatureGroups = (features: TripFeature[]): FeatureGroup[] => {
    const ioMarkers = [];
    features.forEach(feature => {
      feature.properties.inputsFormatted.forEach((io, i) => {
        if (io.change) {
          const latlng = latLng(feature.geometry.coordinates[0], feature.geometry.coordinates[1]);
          const popup = this.getRecentPathIoPopup(feature.properties);
          const marker = this.createIoMarker(latlng, i, io.label).bindPopup(popup);

          ioMarkers[i] = ioMarkers[i] || [];
          ioMarkers[i].push(marker);
        }
      });
    });

    return ioMarkers.map(markers => featureGroup(markers));
  };

  private getPopupStatusLine = (properties: PathProperties): string => {
    if (!properties.powerOn) {
      return `<p>${this.nearMeIcon}${this.translated[this.translations.map.pathLayerConfigService.poweredOff]}</p>`;
    }

    if (properties.state === PathStatusTypes.IDLING) {
      return `<p>${this.nearMeIcon}${this.translated[this.translations.map.pathLayerConfigService.idling]}</p>`;
    }

    if (properties.state === PathStatusTypes.DRIVING) {
      const speed = this.uiUtils.formatSpeed(properties.speed);
      const compass = this.uiUtils.degToCompass(properties.heading);
      return `<p>${this.nearMeIcon}${compass} - ${speed}</p>`;
    }
  };

  private getPopupDateTimeLine = (properties: PathProperties): string => {
    const dateTimeText = this.localizationService.getLocalizedTime(properties.timeStamp, { seconds: true, date: true });
    return `<p>${this.queryBuilderIcon}${dateTimeText}</p>`;
  };

  private getPopupStateOfChargeLine = (properties: PathProperties): string => {
    const stateOfChargeText = util.getStateOfChargeText(properties.stateOfCharge);
    return stateOfChargeText?.length > 0 ? `<p>${this.evStationIcon}${stateOfChargeText}</p>` : '';
  };

  private getPopupBatteryConditionLine = (properties: PathProperties): string => {
    const gpsBatteryLifeText = this.translated[this.translations.uiUtilities.ztrakBatteryCondition.gpsBatteryLife];
    const batteryConditionText = this.uiUtils.getTranslatedBatteryCondition(properties.ztrakBatteryCondition);
    return `<p>${this.batteryConditionIcon}${gpsBatteryLifeText}: ${batteryConditionText}</p>`;
  };

  private getRecentPathPopup = (properties: PathProperties, addressLines: string, driverName: string): string => {
    const notZTrak = properties.state !== PathStatusTypes.EQUIPMENT;
    const statusLine = notZTrak ? this.getPopupStatusLine(properties) : '';
    const dateTimeLine = this.getPopupDateTimeLine(properties);
    const stateOfChargeLine = notZTrak ? this.getPopupStateOfChargeLine(properties) : '';
    const batteryConditionLine =
      properties.ztrakBatteryCondition != null ? this.getPopupBatteryConditionLine(properties) : '';
    return driverName + statusLine + dateTimeLine + addressLines + stateOfChargeLine + batteryConditionLine;
  };

  getRecentPathPopupLoading = (properties: PathProperties): string => {
    const notZTrak = properties.state !== PathStatusTypes.EQUIPMENT;
    const addressLine =
      `<p class="${this.addressLineClass}">${this.placeIcon}${this.loadingSkeleton}</p>` +
      `<p class="${this.addressLineClass}">${this.blankIcon}${this.loadingSkeleton}</p>`;
    const driverName = notZTrak ? `<p >${this.personIcon}${this.loadingSkeleton}</p>` : '';
    return this.getRecentPathPopup(properties, addressLine, driverName);
  };

  private getRecentPathPopupSuccess = (properties: PathProperties): string => {
    const addressText = this.uiUtils.getAddressText(properties);
    const cityStateZipText = getCityStateZipText(properties);
    const addressLine =
      `<p class="${this.addressLineClass}">${this.placeIcon}${addressText}</p>` +
      `<p class="${this.addressLineClass}">${this.blankIcon}${cityStateZipText}</p>`;
    const driverName = this.getDriverNameText(properties.driverProfile);

    return this.getRecentPathPopup(properties, addressLine, driverName);
  };

  private getRecentPathPopupError = (properties: PathProperties): string => {
    const addressLine = `<p>${this.placeIcon}${this.errorMessage}</p>`;
    return this.getRecentPathPopup(properties, addressLine, '');
  };

  private getPopupActiveIosLine = (properties: PathProperties): string => {
    const ioString = this.assetIoService.getIOString(properties.inputsFormatted);
    return `<p>${this.taskAltIcon}${ioString}</p>`;
  };

  private getRecentPathIoPopup = (properties: PathProperties): string => {
    const activeIosLine = this.getPopupActiveIosLine(properties);
    const statusLine = this.getPopupStatusLine(properties);
    const dateTimeLine = this.getPopupDateTimeLine(properties);
    return activeIosLine + statusLine + dateTimeLine;
  };

  reverseGeocodePopup = (marker: Marker, pathFeature: PathFeature, useDelay: Boolean) => {
    const lat = pathFeature.geometry.coordinates[0] || pathFeature.geometry.coordinates.lat;
    const lng = pathFeature.geometry.coordinates[1] || pathFeature.geometry.coordinates.lng;
    const delayMillis = useDelay ? this.reverseGeocodingDelay : 0;

    const subscription = timer(delayMillis)
      .pipe(
        concatMapTo(this.reverseGeocoderService.getReverseGeocode(lat, lng)),
        withLatestFrom(this.driverFacade.getDriverAssetProfile())
      )
      .subscribe(
        ([reverseGeocoderResponse, driverAssetProfiles]) => {
          const timestamp = pathFeature.properties.timeStamp;
          const geoDataProperties = reverseGeocoderResponse.data[0];
          const sortedProfiles = this.driverFacade.sortProfilesForDay(driverAssetProfiles, timestamp);
          const driverassetsProfile = this.driverFacade.getDriverProfileAtTimestamp(sortedProfiles, timestamp);
          marker.bindPopup(
            this.getRecentPathPopupSuccess({
              ...pathFeature.properties,
              ...geoDataProperties,
              driverProfile: driverassetsProfile
            })
          );
        },
        error => {
          marker.bindPopup(this.getRecentPathPopupError(pathFeature.properties));
        }
      );

    marker.on('popupclose', () => {
      subscription.unsubscribe();
    });
  };

  createPathMarkers(latlng: LatLng, properties: PathProperties): Marker {
    const marker = !properties.powerOn
      ? this.createPathPoweredOffMarker(latlng)
      : this.createPathPoweredOnMarkers(latlng, properties);
    return marker;
  }

  private createPathPoweredOnMarkers(latlng: LatLng, properties: PathProperties): Marker {
    const marker =
      properties.state == PathStatusTypes.DRIVING
        ? this.createPathInMotionMarker(latlng, properties.heading)
        : this.createPathIdlingMarker(latlng);
    return marker;
  }

  private getDriverNameText = (driverProfile: DriverProfile): string => {
    const driverFirstName = driverProfile?.legacyFirstName;
    const driverLastName = driverProfile?.legacyLastName;
    let driverName = '';
    if (driverFirstName && driverLastName) {
      driverName = `${driverFirstName} ${driverLastName}`;
    }
    if (driverFirstName && !driverLastName) {
      driverName = driverFirstName;
    }
    if (!driverFirstName && driverLastName) {
      driverName = driverLastName;
    }
    if (driverName) {
      const driverAssocitionSrcText = this.driverFacade.getDriverAssociationSrcTsText(driverProfile);
      const infoHtml = `<span class="tooltip-icon"><span class="material-icons">info_outline</span><span class="tooltip-text">${driverAssocitionSrcText}</span></span>`;
      return `<p>${this.personIcon}${driverName}${infoHtml}</p>`;
    }
    return driverName;
  };
}
