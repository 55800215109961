import { Injectable } from '@angular/core';
import { PermissionsService } from '@zonar-ui/auth';
import { DivisionMappingService } from '@app/services/division-mapping.service';
import { DivisionCacheService } from '@app/services/DivisionCacheService';
import { AutocompleteApiService } from '@app/modules/autocomplete-client/autocomplete-api.service';
import { AutocompleteParams, AutocompleteResult } from '@app/modules/autocomplete-client/autocomplete-api.models';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AssetsParams } from '@app/modules/location-client/location-api.models';
import { deduplicateArray, extractIds, intersection } from '@app/modules/shared/utilities/utilities';
import { environment as env } from '@environments/environment';
import { Store } from '@ngrx/store';
import { selectCurrentFilter } from '@app/store/filters/selectors/filters.selectors';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteSearchService {
  constructor(
    private autocompleteApi: AutocompleteApiService,
    private permissions: PermissionsService,
    private divisionMapper: DivisionMappingService, // For external users to fetch divisions
    private divisionCache: DivisionCacheService, // for Internal
    private store: Store
  ) {}

  executeAutocompleteSearch(
    searchParams: Partial<AutocompleteParams>,
    assetParams: AssetsParams
  ): Observable<AutocompleteResult> {
    return this.permissions.getIsZonarUser().pipe(
      switchMap(isZonarUser => {
        if (isZonarUser) {
          return this.fetchAutocompleteForInternalUser(searchParams, assetParams);
        } else {
          return this.fetchAutocompleteForCustomerUser(searchParams, assetParams);
        }
      }),
      switchMap(results => {
        return this.createResultsFromApiRequests(results);
      }),
      catchError((error, _) => {
        return EMPTY;
      })
    );
  }

  /**
   * Returns the set of API results | autocomplete response based on parameters for Zonar users
   * @param searchParams
   * @param assetParams
   */
  fetchAutocompleteForInternalUser(
    searchParams: Partial<AutocompleteParams>,
    assetParams: AssetsParams
  ): Observable<any[]> {
    if (assetParams.divisionIds?.length) {
      // If we specify division IDs just fetch the assets for the given ids, as these are locations
      return this.fetchAutocompleteDivisionIds(searchParams, assetParams.divisionIds, assetParams.companyId);
    } else if (assetParams.accountDivisions?.length) {
      // Otherwise, we recursively traverse the divisions
      const accounts = assetParams.accountDivisions.map(d =>
        this.divisionCache.divisions.find(account => account.id === d)
      );
      const accountIds = accounts.map(a => a?.id);
      const children = accounts.map(a => a.children).reduce((curr, acc) => curr.concat(acc), []);
      const dedupedParentsAndChildren = deduplicateArray([...accountIds, ...children]);

      return this.fetchAutocompleteDivisionIds(searchParams, dedupedParentsAndChildren, assetParams.companyId);
    } else {
      // Or failing any parameters we will grab all the things based on the params.
      return this.fetchAutocompleteCompanyId(searchParams, assetParams.companyId).pipe(map(result => [result]));
    }
  }

  /**
   * Returns the set of API results | autocomplete results based on parameters for customer users
   * @param searchParams
   * @param assetParams
   */
  fetchAutocompleteForCustomerUser(
    searchParams: Partial<AutocompleteParams>,
    assetParams: AssetsParams
  ): Observable<any[]> {
    if (assetParams?.divisionIds?.length || assetParams.accountDivisions?.length) {
      const divisionsToFetch = this.getDivisionsToFetch(assetParams.divisionIds, assetParams.accountDivisions);
      const deduplicatedDivisionsAndChildren = deduplicateArray(divisionsToFetch);
      return this.fetchAutocompleteDivisionIds(searchParams, deduplicatedDivisionsAndChildren, assetParams.companyId);
    } else {
      return this.fetchAutocompleteCompanyId(searchParams, assetParams.companyId).pipe(map(result => [result]));
    }
  }

  fetchAutocompleteDivisionIds(searchParams, divisionIds, companyId): Observable<AutocompleteResult[]> {
    const params: AutocompleteParams = { ...searchParams, divisionIds, companyId };
    return this.fetchAutocomplete(params).pipe(map(results => [results]));
  }

  fetchAutocompleteCompanyId(searchParams, companyId): Observable<AutocompleteResult> {
    const params: AutocompleteParams = { ...searchParams, companyId };
    return this.fetchAutocomplete(params);
  }

  fetchAutocomplete(params: AutocompleteParams): Observable<AutocompleteResult> {
    let currentFilter;
    this.store.select(selectCurrentFilter).subscribe(filter => {
      currentFilter = filter;
    });

    if (currentFilter.locations?.length && params.divisionIds?.length) {
      const divisions = currentFilter.divisions?.map(d => d.id);
      params.divisionIds = deduplicateArray([...params.divisionIds, ...divisions]);
    }
    return this.autocompleteApi.getAutocomplete({ ...params, pageSize: env.autocompleteApi.pageSize });
  }

  createResultsFromApiRequests(
    results: Array<AutocompleteResult> | Array<Array<AutocompleteResult>>
  ): Observable<AutocompleteResult> {
    if (!Array.isArray(results[0])) {
      return of(results[0]);
    }
    const temp = results[0].reduce(
      (acc, val) => {
        acc.drivers.push(...val.drivers);
        acc.assets.push(...val.assets);
        return acc;
      },
      { assets: [], drivers: [] }
    );
    return of({ ...temp, nextPageToken: '' });
  }

  /**
   *
   * @param paramDivisions
   * @param accountDivisions
   * @return list of divisions to fetch in paginated division flow.
   */
  getDivisionsToFetch(paramDivisions?: string[], accountDivisions?: string[]): string[] {
    // This is fun!! first if we have account divisions(account code selected
    let returnValue;
    if (paramDivisions?.length && accountDivisions?.length) {
      const availableAccounts = accountDivisions;
      const childDivisionIds = extractIds(this.divisionMapper.getChildDivisionsForParentIds(accountDivisions));
      // This is essentially a check against whether the user is location locked. the first case is the false case.
      if (availableAccounts.length) {
        returnValue = [...availableAccounts, ...intersection(paramDivisions, childDivisionIds)];
      } else {
        // User is location locked, only fetch for children
        returnValue = [...intersection(paramDivisions, childDivisionIds)];
      }
    } else if (paramDivisions?.length) {
      // if just specified divisions, get all specified divisions and their children
      const paramChildren = extractIds(this.divisionMapper.getChildDivisionsForParentIds(paramDivisions));
      returnValue = [...paramDivisions, ...paramChildren];
    } else if (accountDivisions?.length) {
      // If we only specify an account to filter, get all authorized accounts and their children
      const children = extractIds(this.divisionMapper.getChildDivisionsForParentIds(accountDivisions));
      const allowedAccounts = extractIds(this.divisionMapper.getDivisionsForIds(accountDivisions)); // if loc locked, this will be empty
      returnValue = [...allowedAccounts, ...children];
    } else {
      // this actually should never happen, but logically TS doesn't know this.
      returnValue = [];
    }
    return returnValue;
  }
}
