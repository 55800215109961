// Angular
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Params, Router } from '@angular/router';

// RxJS
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

// ngrx
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

// App
import { AppState } from '@app/store';
import * as AssetActions from '@app/store/asset/actions/assets.actions';
import * as AssetsActions from '@app/store/asset/actions/assets.actions';
import { selectCurrentFilter } from '@app/store/filters/selectors/filters.selectors';
import { Filter } from '@app/store/filters/models/filters.model';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { selectQueryParams, selectRouteParams } from '@app/store/router/selectors/router.selectors';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import {
  selectAllAssets,
  selectAssetsLoadState,
  selectChosenAsset,
  selectRecentPathStartTime
} from '@app/store/asset/selectors/assets.selectors';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { disallowedQueryParams } from '@app/store/router/models/router.models';
import { DetailsSubcontext } from '@app/store/layout/reducers/layout.reducer';
import { selectViewSubContext } from '@app/store/layout/selectors/layout.selectors';
import * as LayoutActions from '@app/store/layout/actions/layout.actions';
import { pipe } from 'rxjs';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private locationFacade: LocationFacade,
    private location: Location
  ) {}

  assetsRoute = '/assets';
  companyId: string = null;

  queryParamsFromFilter = (filter: Filter): Params => {
    const queryParams = {};
    Object.entries(filter).forEach(arr => {
      const k = arr[0];
      const v = arr[1];

      // filter for any disallowed query params
      if (disallowedQueryParams.includes(k)) return;

      if (Array.isArray(v)) {
        // in the case of searchTerms, v will be an array of strings
        if (typeof v[0] === 'string') {
          queryParams[k.toString()] = [...v];
          // in the case of divisions and locations, v will be an array of objects
        } else {
          const ids = v.map(obj => obj?.id);
          queryParams[k.toString()] = ids;
        }
        // in the case of company, v will be an object
      } else if (typeof v === 'object' && v?.id) {
        queryParams[k.toString()] = v.id;
        // if the value is a boolean
      } else if (typeof v === 'boolean') {
        queryParams[k.toString()] = v;
        // if the value is a non-empty string
      } else if (v?.length > 0) {
        queryParams[k.toString()] = v;
      }
    });
    return queryParams;
  };

  updateUrl = (url: string, queryParams: Params): void => {
    let queryParamsHandling;
    // simplifies asset history url by just putting recentPathStartTime param in and nothing else since we don't need all the other information, i.e. powerOn, company, etc
    if (queryParams.recentPathStartTime) {
      queryParamsHandling = '';
    } else {
      queryParamsHandling = 'merge';
    }
    const urlWithParams = this.router
      .createUrlTree([url], {
        queryParams: queryParams,
        queryParamsHandling
      })
      .toString();

    if (queryParams.recentPathStartTime) {
      this.router.navigateByUrl(urlWithParams);
      return;
    }

    // this if statement prevents a bug where when a user logs in, the map does not load because we would otherwise keep routing to /assets?...whatever/company=foo multiple times, which confuses the app and breaks the map init process. A refresh would work, but obviously, we do not want to force users to refresh again after logging in

    if (!queryParams.company) {
      return;
    }

    // if we do have a company param, it means we are on list view and just need to change the url accordingly with location.go and not route to it. Due to the seletcAllFilters selector, we get to this point in code ~6 times depending on changes and you would be ROUTED 6x to the list view, whereas location go just changes the URL.
    if (queryParams.company) {
      this.location.go(urlWithParams);
      return;
    }

    this.router.navigateByUrl(urlWithParams);
  };

  updateAppStateFromUrl$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        switchMap(() => {
          // first, we wait for assets to finish loading after the navigation event
          return this.store.pipe(
            select(selectAssetsLoadState),
            filter(assetsLoadState => Boolean(assetsLoadState === ResourceLoadState.LOAD_SUCCESSFUL)),
            first()
          );
        }),
        pipe(first()),
        concatLatestFrom(() => [
          this.store.select(selectAllAssets),
          this.store.select(selectRouteParams),
          this.store.select(selectQueryParams)
        ]),
        tap(([action, assets, routeParams, queryParams]) => {
          const viewSubContextIsSet =
            routeParams?.viewSubContext && Object.values(DetailsSubcontext).includes(routeParams.viewSubContext);
          // if the url route params contains viewSubContext, set the app viewSubContext
          if (viewSubContextIsSet) {
            this.locationFacade.setViewSubContext(routeParams?.viewSubContext);
          }
          // if this is the asset detail history view and query params contain recentPathStartDate, set app recentPathStartDate
          if (
            viewSubContextIsSet &&
            routeParams.viewSubContext === DetailsSubcontext.HISTORY &&
            routeParams?.assetId &&
            queryParams?.recentPathStartTime
          ) {
            this.locationFacade.setRecentPathStartTime(new Date(queryParams.recentPathStartTime));
          }
        })
      );
    },
    { dispatch: false }
  );

  updateUrlOnLoadAssets$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AssetActions.loadAssets),
        concatLatestFrom(() => [this.store.pipe(select(selectCurrentFilter)), this.store.select(selectRouteParams)]),
        map(([_, filter, routeParams]) => {
          const queryParams = this.queryParamsFromFilter(filter);
          const url = this.assetsRoute;

          // if statement prevents bug for zonar user where if they are on asset details view, the selectCurrentFilter state gets dispatched and goes through this effect where it routes back to list view unintentionally
          if (!routeParams?.assetId) {
            this.updateUrl(url, queryParams);
          }

          return { url, queryParams };
        })
      );
    },
    { dispatch: false }
  );

  updateUrlOnFilterChange$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AssetActions.loadAssetsOnUpdatedFiltersSuccess),
        concatLatestFrom(() => [this.store.pipe(select(selectCurrentFilter)), this.store.select(selectRouteParams)]),
        map(([_, filter, routeParams]) => {
          const queryParams = this.queryParamsFromFilter(filter);
          const url = this.assetsRoute;

          // if statement prevents bug for zonar user where if they are on asset details view, the selectCurrentFilter state gets dispatched and goes through this effect where it routes back to list view unintentionally
          if (!routeParams?.assetId) {
            this.updateUrl(url, queryParams);
          }

          return { url, queryParams };
        })
      );
    },
    { dispatch: false }
  );

  updateUrlOnPathDateChange$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AssetActions.setRecentPathStartTime),
        concatLatestFrom(() => [this.store.select(selectRouteParams)]),
        map(([action, routeParams]) => {
          if (routeParams?.assetId) {
            const queryParams = {
              recentPathStartTime: action.startTime
            };
            const url = `${this.assetsRoute}/${routeParams?.assetId}/history`;

            this.updateUrl(url, queryParams);
            return { url, queryParams };
          }
        })
      );
    },
    { dispatch: false }
  );

  updateUrlOnAssetSelected$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AssetsActions.loadSelectedAsset),
        concatLatestFrom(() => [
          this.store.pipe(select(selectCurrentFilter)),
          this.store.pipe(select(selectChosenAsset))
        ]),
        map(([_, filter, selectedAsset]) => {
          const queryParams = this.queryParamsFromFilter(filter);

          if (selectedAsset?.assetId) {
            const url = `${this.assetsRoute}/${selectedAsset.assetId}/live`;
            // since we don't need /live to have all the extra queryParam bloat ,we can just bypass updateUrl to go to /live without extra params
            this.router.navigateByUrl(url);

            return { url, queryParams };
          } else {
            const url = this.assetsRoute;

            this.updateUrl(url, queryParams);
            return { url, queryParams };
          }
        })
      );
    },
    { dispatch: false }
  );

  updateUrlOnViewSubContextUpdate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LayoutActions.setViewSubContext),
        concatLatestFrom(() => [
          this.store.pipe(select(selectCurrentFilter)),
          this.store.pipe(select(selectViewSubContext)),
          this.store.select(selectRouteParams),
          this.store.select(selectRecentPathStartTime)
        ]),
        map(([_, filter, viewSubContext, routeParams, recentPathStartTime]) => {
          let queryParams = this.queryParamsFromFilter(filter);

          if (routeParams?.assetId) {
            // restting queryParams for history leaves out queryParams bloat from the URL with powerOn, sortAttribute,company params, etc
            if (viewSubContext === DetailsSubcontext.HISTORY) {
              queryParams = {
                recentPathStartTime: recentPathStartTime
              };
            }

            const url = `${this.assetsRoute}/${routeParams.assetId}/${viewSubContext}`;

            this.updateUrl(url, queryParams);
            return { url, queryParams };
          } else {
            const url = this.assetsRoute;

            this.updateUrl(url, queryParams);
            return { url, queryParams };
          }
        })
      );
    },
    { dispatch: false }
  );
}
