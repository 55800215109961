import { Injectable } from '@angular/core';
import { CurrentCompanyFacade } from '@app/modules/zones/facade/current-company.facade';
import { Asset } from '@app/modules/location-client/location-api.models';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilKeyChanged,
  filter,
  map,
  merge,
  mergeMap,
  Observable,
  of
} from 'rxjs';
import { DivisionMappingService } from '@app/services/division-mapping.service';
import { PermissionsService } from '@zonar-ui/auth';

@Injectable({
  providedIn: 'root'
})

// a facade service that gets various info in NGRX state to pass to LeafletZonesService to determine when we render zones
export class DataForZones {
  constructor(
    private currentCompanyFacade: CurrentCompanyFacade,
    private divMappingSvc: DivisionMappingService,
    private permsService: PermissionsService
  ) {}

  selectedAsset$: BehaviorSubject<Asset> = new BehaviorSubject(null);

  getCurrentCompanyId() {
    return merge(
      // get the company id from list view
      this.currentCompanyFacade.getCurrentCompanyId(),

      // get the company id when loading asset history or asset live details view first
      this.selectedAsset$.pipe(
        filter(asset => Boolean(asset?.companyId)),
        distinctUntilKeyChanged('companyId'),
        map(asset => {
          return asset.companyId;
        })
      )
    );
  }

  getDivisionIds(): Observable<string[]> {
    return combineLatest([
      this.permsService.getIsPermissionsLoaded(),
      this.permsService.getIsZonarUser(),
      this.permsService.getDivisionMap()
    ]).pipe(
      filter(([permsLoaded, isZonar, divMap]) => {
        const rval = permsLoaded && !!Object.keys(divMap).length;
        return rval;
      }),
      mergeMap(([_, isZonarUser, divisionMap]) => {
        const divisionIds: string[] = [];

        // if (!isZonarUser) {
        Object.keys(divisionMap).forEach(divId => {
          if (divisionIds.indexOf(divId) === -1) {
            divisionIds.push(divId);
          }
          if (
            divisionMap[divId].type === 'LEGACY_LOCATION' &&
            divisionMap[divId].parentId &&
            divisionIds.indexOf(divisionMap[divId].parentId) === -1
          ) {
            divisionIds.push(divisionMap[divId].parentId);
          }
        });
        // }
        return of(divisionIds);
      })
    );
  }
}
