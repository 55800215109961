import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DetailsSubcontext } from '@app/store/layout/reducers/layout.reducer';
import { ZonarUiNotificationsService } from '@zonar-ui/notifications';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';

@Component({
  selector: 'app-view-switcher',
  templateUrl: './view-switcher.component.html',
  styleUrls: ['./view-switcher.component.scss']
})
export class ViewSwitcherComponent implements OnChanges {
  constructor(
    private notifications: ZonarUiNotificationsService,
    public translateService: TranslateService,
    public translations: Translations
  ) {}

  @Output() subContextChange = new EventEmitter<any>();

  liveSubContext = DetailsSubcontext.LIVE;
  historySubContext = DetailsSubcontext.HISTORY;

  selectedSubContext = null;

  @Input() subContextView: DetailsSubcontext = this.liveSubContext;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.subContextView?.currentValue) {
      this.subContextView = changes.subContextView.currentValue;
    } else {
      // not sure why i still needed to set this despite making it a default setting already, but the default just wouldn't work
      this.subContextView = this.liveSubContext;
    }
  }

  handleSubcontextChange(event) {
    this.notifications.dismiss();
    this.subContextChange.emit(event.value);
  }
}
