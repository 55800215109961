import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FilterDialogComponent } from '@app/modules/shared/components/filter-dialog/filter-dialog.component';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-locations-page',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationsComponent implements OnInit {
  constructor(public locationFacade: LocationFacade, public dialog: MatDialog) {}

  config = new MatDialogConfig();

  ngOnInit() {
    this.config.maxWidth = '100vw';
    this.config.maxHeight = '100vh';
    this.config.autoFocus = false;
    this.config.panelClass = 'filter-dialog';

    this.locationFacade.loadPermissions();
    this.locationFacade
      .getUserPermissions()
      .pipe(
        filter(perms => perms.readCompanies === false && perms.companies.length > 0),
        take(1)
      )
      .subscribe(perms => {
        this.locationFacade.setLimitedUserCompanyAndDivisions({
          companyId: perms.companies[0],
          divisions: perms.divisions
        });
      });

    this.addVisibilityChangeListener();
  }

  dialogConfig = mobile => {
    this.config.height = mobile ? '100vh' : 'auto';
    this.config.width = mobile ? '100vw' : '50vw';
    return this.config;
  };

  openFilterDialog(mobile = false): void {
    this.locationFacade.setViewFiltersOpen(true);
    const dialogRef = this.dialog.open(FilterDialogComponent, this.dialogConfig(mobile));
    dialogRef.afterClosed().subscribe(() => {
      this.locationFacade.setViewFiltersOpen(false);
    });
  }

  handleVisibilityStateChange(visibilityState: DocumentVisibilityState): void {
    if (visibilityState === 'hidden') {
      this.locationFacade.stopAssetsPolling();
    }

    if (visibilityState === 'visible') {
      this.locationFacade.startAssetsPolling();
    }
  }

  addVisibilityChangeListener() {
    document.addEventListener('visibilitychange', () => {
      this.handleVisibilityStateChange(document.visibilityState);
    });
  }
}
