import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataForZones } from '@app/services/data-for-zones.service';
import { environment } from '@environments/environment';
import { Observable, map, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZonesFilterService {
  url = `${environment.coreEntityApiBase.url}`;

  constructor(private dataForZones: DataForZones, private http: HttpClient) {}

  getCurrentCompanyId(): Observable<string> {
    return this.dataForZones.getCurrentCompanyId().pipe(
      map(companyId => {
        return companyId;
      })
    );
  }

  getZoneById(id: string) {
    return this.http.get(`${this.url}/zones/${id}`).pipe(
      map(resp => {
        return resp;
      })
    );
  }
}
