import { Pipe, PipeTransform } from '@angular/core';
import { EventSeverity } from '@app/modules/location-client/location-api.models';
import { ZonarUiLozengeTypes } from '@zonar-ui/lozenge';

@Pipe({ name: 'severityToType' })
export class SeverityToTypePipe implements PipeTransform {
  private severityMap = {
    [EventSeverity.CRITICAL]: ZonarUiLozengeTypes.ERROR,
    [EventSeverity.WARNING]: ZonarUiLozengeTypes.WARNING,
    [EventSeverity.INFO]: ZonarUiLozengeTypes.INFORMATION
  };

  transform(severity: EventSeverity): ZonarUiLozengeTypes {
    return this.severityMap[severity];
  }
}
