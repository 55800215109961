import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { Observable, map, filter, distinctUntilKeyChanged } from 'rxjs';
import { selectAssetParams } from '@app/store/asset/selectors/assets.selectors';

@Injectable({
  providedIn: 'root'
})
export class CurrentCompanyFacade {
  constructor(private store: Store<AppState>) {}

  getCurrentCompanyId(): Observable<string> {
    return this.store.select(selectAssetParams).pipe(
      filter(assetParams => Boolean(assetParams?.companyId)),
      distinctUntilKeyChanged('companyId'),
      map(assetParams => assetParams.companyId)
    );
  }
}
