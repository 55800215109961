import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-asset-list-item-skeleton',
  templateUrl: './asset-list-item-skeleton.component.html',
  styleUrls: ['./asset-list-item-skeleton.component.scss']
})
export class AssetListItemSkeletonComponent {
  constructor() {}
}
