import { createAction, props } from '@ngrx/store';
import { AutoSuggestionResponse, AutoSuggestRequest } from '@app/modules/location/models/auto-suggestion.model';

export const enum PlacesActionsTypes {
  CLEAR_PLACES_RESULT = '[Places] Clear Places Result',
  LOAD_PLACES_RESULT = '[Places] Load Places Result',
  LOAD_PLACES_RESULT_FAILURE = '[Places] Load Places Result Failure',
  LOAD_PLACES_RESULT_SUCCESS = '[Places] Load Places Result Success'
}
export const clearPlacesResult = createAction(PlacesActionsTypes.CLEAR_PLACES_RESULT);
export const loadPlacesResult = createAction(
  PlacesActionsTypes.LOAD_PLACES_RESULT,
  props<{ params: AutoSuggestRequest }>()
);

export const loadPlacesResultSuccess = createAction(
  PlacesActionsTypes.LOAD_PLACES_RESULT_SUCCESS,
  props<{ data: AutoSuggestionResponse }>()
);

export const loadPlacesResultError = createAction(
  PlacesActionsTypes.LOAD_PLACES_RESULT_FAILURE,
  props<{ error: any }>()
);
