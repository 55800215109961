import { Component, OnDestroy, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { take, takeUntil, tap, delay } from 'rxjs/operators';
import { ToggleFilterEvent } from '@app/store/filters/models/filters.model';
import { FilterDialogState } from '@app/modules/location/models/filter-dialog.model';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-toggle-filters',
  templateUrl: './toggle-filters.component.html',
  styleUrls: ['./toggle-filters.component.scss']
})
export class ToggleFiltersComponent implements OnDestroy, OnInit {
  @Output() toggleFilterChange: EventEmitter<ToggleFilterEvent> = new EventEmitter<ToggleFilterEvent>();
  @Input() initialFilterState: FilterDialogState;

  constructor(private translations: Translations, private translateService: TranslateService) {
    this.translateService
      .get([this.translations.appToggleFilters.poweredOnToggle])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(translated => {
        this.toggleFilterPrompts = [translated[this.translations.appToggleFilters.poweredOnToggle]];
      });
  }

  toggleFilterProps = ['powerOn'];
  toggleFilters: ToggleFilterEvent[] = [];
  toggleFilterPrompts;
  private onDestroy$ = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    this.toggleFilterProps.forEach(prop =>
      this.toggleFilters.push({ filter: prop, toggled: this.initialFilterState[prop] })
    );
  }
}
