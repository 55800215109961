<mat-form-field class="zui-chip-selection-list" appearance="outline" [class.no-pointer-events]="noPointerEvents">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let chip of chips"
        [removable]="true"
        (removed)="remove(chip)"
        [selectable]="true">
          {{ chip.title }}
          <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        (click)="autocomplete.openPanel()"
        #searchBox
        [matAutocomplete]="auto"
        #chipInput
        [matChipInputFor]="chipList"
        [formControl]="chipsCtrl">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option
      *ngFor="let option of options"
      [value]="option.value"
      >
        {{ option.title }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
