<div data-e2e-timeline-control class="timeline-control-container leaflet-control"  id="timelineControl">
   <!-- the null check here is included as part of ZTT-1200 if a user goes to asset/assetId/history directly. For some reason,
   dispatching the recent path action was not triggering loading state so skeletons were not properly showing on app init.
   It was easier just to do it this way instead of trying to track down how state was working with loading state -->
   <div *ngIf='(locationFacade.getRecentPathLoadState() | async) === loading || (locationFacade.getRecentPathLoadState() | async) === null' class='path-skeleton-frame'>
      <div class='skeleton-container' fxLayout='row' fxLayoutAlign='space-between center' >
         <div fxFlex='85'>
               <ngx-skeleton-loader></ngx-skeleton-loader>
         </div>
         <div fxFlex='15'>
         <button mat-raised-button class="view-trip" disabled="true">{{ translateService.get(translations.map.timelineControl.viewTrip) | async | translate }}</button>

         </div>
      </div>
   </div>
   <div *ngIf='(locationFacade.getRecentPathLoadState() | async) === loaded  || (locationFacade.getRecentPathLoadState() | async) === loadFailure' >
      <div *ngIf="points">
         <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="87" class="time-line">
               <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="time-line-bar-container" >
                  <div fxFlex="100"  >
                     <div fxLayout="row" fxLayoutAlign="space-between center"  (mouseleave)="reset()">
                        <div *ngFor="let el of points;let index=index; let last=last; let first=first" class="time-line-point" >
                           <div class="time-line-bar {{el.status}}" (mouseover)='onHover(el,index)' ></div>
                           <div *ngIf="hoverIndex===index" [ngStyle]="{background: 'url(' + el.markerSrc + ') '}" [ngClass]="{'on-hover': hoverIndex===index}"></div>
                        <span class="timestamp" *ngIf="!(first ||last ) && hoverIndex===index"> {{ getLocalizedTimestamp(el.properties.timeStamp) }}</span>
                        </div>
                     </div>
                  </div>
                  <div fxFlex="50" class="time start">
                  <span [ngClass]="{ 'show-time': this.showStartTime, 'hide-time':!this.showStartTime }"> {{startTimeStamp}} </span>
                  </div>
                  <div fxFlex="50" class="time end">
                     <span [ngClass]="{ 'show-time': this.showEndTime, 'hide-time':!this.showEndTime }"> {{endTimeStamp}} </span>
                  </div>
               </div>
            </div>
            <div fxFlex="13">
               <button mat-raised-button color="accent" class="view-trip" [disabled]="!segmentSelected" (click)="viewEntireTrip()" >{{ translateService.get(translations.map.timelineControl.viewTrip) | async | translate }}</button>
            </div>
         </div>
      </div>
   </div>
</div>
