<div class="leaflet-control leaflet-control-incident" id="incidentControl">
  <a class="leaflet-control-incident-button leaflet-control-pill"
    (click)="onClick($event)"
    role="button"
    [matTooltip]="translateService.get(translations.map.incidentControl.tooltip) | async | translate" 
    fxLayoutAlign="start center">
    <zui-icon icon="image_search"></zui-icon>
    <span class="leaflet-control-incident-button-label" fxShow.xs="false">
      {{ translateService.get(translations.map.incidentControl.locator) | async | translate }}
    </span>
  </a>
</div>