import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy, ScrollStrategy } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

// code adapted from https://stackblitz.com/edit/overlay-progress-spinner

@Injectable()
export class OverlayService {
  constructor(private ngOverlay: Overlay) {}

  createOverlay(config: OverlayConfig): OverlayRef {
    return this.ngOverlay.create(config);
  }

  attachTemplatePortal(overlayRef: OverlayRef, templateRef: TemplateRef<any>, vcRef: ViewContainerRef) {
    const templatePortal = new TemplatePortal(templateRef, vcRef);
    overlayRef.attach(templatePortal);
  }

  positionGloballyCenter(): PositionStrategy {
    return this.ngOverlay.position().global().centerHorizontally().centerVertically();
  }

  blockScrollStrategy(): ScrollStrategy {
    return this.ngOverlay.scrollStrategies.block();
  }
}
