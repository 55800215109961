import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { createReducer, on } from '@ngrx/store';
import {
  AutocompleteAssetsResponse,
  AutocompleteDriversResponse,
  AutocompleteParams
} from '@app/modules/autocomplete-client/autocomplete-api.models';
import { AutocompleteActions } from '@app/store/autocomplete/actions';

export const autocompleteFeatureKey = 'autocomplete';

export interface AutocompleteState {
  searchParams?: Partial<AutocompleteParams>;
  assets: AutocompleteAssetsResponse[];
  drivers: AutocompleteDriversResponse[];
  autocompleteLoadState: string;
  fetchAutocompleteError?: object;
}

export const initialState: AutocompleteState = {
  searchParams: null,
  assets: [],
  drivers: [],
  autocompleteLoadState: ResourceLoadState.INITIAL,
  fetchAutocompleteError: null
};

export const reducer = createReducer(
  initialState,

  on(AutocompleteActions.fetchAutocompleteResults, (state, { searchParams }) => {
    return { ...state, searchParams, autocompleteLoadState: ResourceLoadState.LOADING };
  }),

  on(AutocompleteActions.fetchAutocompleteSuccess, (state, { response }) => {
    return {
      ...state,
      assets: response?.assets ? response.assets : [],
      drivers: response?.drivers ? response.drivers : [],
      fetchAutocompleteError: null,
      autocompleteLoadState: ResourceLoadState.LOAD_SUCCESSFUL
    };
  }),

  on(AutocompleteActions.fetchAutocompleteFailure, (state, { fetchAutocompleteError }) => {
    return { ...state, fetchAutocompleteError, autocompleteLoadState: ResourceLoadState.LOAD_FAILURE };
  }),

  on(AutocompleteActions.clearAutocomplete, state => {
    return { ...initialState };
  }),

  on(AutocompleteActions.setAutocompleteParams, (state, { searchParams }) => {
    return { ...state, searchParams };
  })
);
