import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPlatform from '../reducers/platform.reducer';
import { Device } from '../reducers/platform.reducer';

export const selectPlatformState = createFeatureSelector<fromPlatform.PlatformState>(fromPlatform.platformFeatureKey);

export const selectDevice = createSelector(selectPlatformState, state => {
  return state.device;
});

export const selectIsMobile = createSelector(selectPlatformState, ({ device }) => {
  const mobileDevices = [Device.IOS, Device.ANDROID];
  return mobileDevices.includes(device);
});

export const selectOrientation = createSelector(selectPlatformState, state => {
  return state.orientation;
});
