import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { DriverAsset } from '../models/driver.models';

@Injectable({
  providedIn: 'root'
})
export class DriverAssetService {
  url = `${environment.coreEntityApiBase.url}/driverassets`;
  constructor(private http: HttpClient) {}

  getDriverDetails(assetId: string, startTimestamp: string, endTimestamp: string): Observable<Array<DriverAsset>> {
    const params = { assetId, startTimestamp, endTimestamp, embed: 'driverProfile', status: 'ANY', timeWindow: 'true' };
    const httpParams = new HttpParams({ fromObject: params as any });
    return this.http.get(this.url, { params: httpParams }) as Observable<Array<DriverAsset>>;
  }
}
