import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import 'moment-duration-format';
import {
  PathFeature,
  PathStatusTypes,
  PathTimeLineStatus,
  TimeLinePoint,
  PoweredOffPoint,
  AdditionalPoint,
  TimeLine,
  TimeLinePathPoint
} from '@app/modules/location/models/path-api.model';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { LeafletService } from '@app/modules/location/services/leaflet.service';
import { LeafletMarkerAsset } from '@app/modules/location/models/leaflet-marker.asset';
import { EUROPE, formatTimestamp } from '@app/modules/shared/utilities/utilities';
import { LocalizationService } from '@app/services/localization.service';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { EnvironmentService } from '@app/services/environment.service';

@Component({
  selector: 'app-timeline-control',
  templateUrl: './timeline-control.component.html',
  styleUrls: ['./timeline-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineControlComponent implements OnDestroy, OnInit {
  constructor(
    public locationFacade: LocationFacade,
    public leafletService: LeafletService,
    private changeDetectorRef: ChangeDetectorRef,
    private envService: EnvironmentService,
    private localizationService: LocalizationService,
    public translateService: TranslateService,
    public translations: Translations
  ) {}

  formatTemplateTimestamp = formatTimestamp;
  segmentSelected: boolean = false;
  onDestroy$ = new Subject<void>();
  timeFormat = 'hh:mm A';
  startTimeStamp: string = '';
  endTimeStamp: string = '';
  minIdleSec: number = 30000;
  loading = ResourceLoadState.LOADING;
  loaded = ResourceLoadState.LOAD_SUCCESSFUL;
  loadFailure = ResourceLoadState.LOAD_FAILURE;
  points: TimeLinePoint[];
  hoverIndex: number;
  showStartTime: boolean = true;
  showEndTime: boolean = true;

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getLocalizedTimestamp(timestamp: string) {
    return this.localizationService.getLocalizedTime(timestamp, { date: false, seconds: false });
  }
  ngOnInit() {
    this.getPathPoints().subscribe((timeLinePathPoint: TimeLinePathPoint) => {
      this.reset();
      this.segmentSelected = timeLinePathPoint.segmentSelected;
      if (this.segmentSelected) {
        this.handleSegmentPoints(timeLinePathPoint);
      } else {
        this.handlePoints(timeLinePathPoint.pathPoints);
      }
    });

    this.onMouseOver().subscribe((feature: PathFeature) => {
      if (this.points && feature && feature.properties) {
        this.hoverIndex = this.points.findIndex(x => x.properties.id === feature.properties.id);
        this.toggleTime();
        this.changeDetectorRef.detectChanges();
      }
    });
  }
  onMouseOver() {
    return this.leafletService.onMouseover$.pipe(takeUntil(this.onDestroy$));
  }
  getPathPoints() {
    return this.leafletService.timeLinePoints$.pipe(
      map((timeLine: TimeLine) => {
        const pathPoints = timeLine.pathPoints;
        const timeLinePoints: TimeLinePoint[] = pathPoints.map((el, i: number) => {
          const obj: TimeLinePoint = this.createTimeLinePoint(el, this.envService);
          if (el.properties.powerOn) {
            obj.setStatusAndMarker();
          }
          if (obj.status == PathTimeLineStatus.IDLE) {
            obj.checkMinIdleTime(pathPoints[i + 1]);
          }
          if (obj.isFirst) {
            this.startTimeStamp = this.getLocalizedTimestamp(obj.timeStamp);
          }
          if (obj.isLast) {
            this.endTimeStamp = this.getLocalizedTimestamp(obj.timeStamp);
          }
          return obj;
        });
        return { ...timeLine, pathPoints: timeLinePoints };
      }),
      takeUntil(this.onDestroy$)
    );
  }

  createTimeLinePoint = (timeLinePoint, envService?) => ({
    ...timeLinePoint,
    powerOn: timeLinePoint.properties.powerOn,
    state: timeLinePoint.properties.state,
    timeStamp: timeLinePoint.properties.timeStamp,
    status: PathTimeLineStatus.POWERED_OFF,
    markerSrc: LeafletMarkerAsset.POWERED_OFF,
    setStatusAndMarker() {
      if (timeLinePoint.properties.state == PathStatusTypes.DRIVING) {
        this.status = PathTimeLineStatus.IN_MOTION;
        this.markerSrc =
          envService.getEnvironment().region !== EUROPE
            ? LeafletMarkerAsset.IN_MOTION
            : LeafletMarkerAsset.IN_MOTION_VDO;
      } else {
        this.status = PathTimeLineStatus.IDLE;
        this.markerSrc = LeafletMarkerAsset.IDLING;
      }
      return this;
    },
    checkMinIdleTime(nextPoint) {
      const diff = moment(nextPoint?.properties?.timeStamp).diff(moment(timeLinePoint.properties.timeStamp));
      if (diff < this.minIdleSec) {
        this.status = PathTimeLineStatus.IN_MOTION;
        this.markerSrc =
          envService.getEnvironment().region !== 'EU' ? LeafletMarkerAsset.IN_MOTION : LeafletMarkerAsset.IN_MOTION_VDO;
      }
      return this;
    }
  });

  onHover(el: TimeLinePoint, i: number) {
    this.hoverIndex = i;
    this.toggleTime();
    this.leafletService.onHoverTimeline(el);
  }
  handlePoints(points) {
    const additionalPoints: AdditionalPoint[] = [];
    const poweredOffPoints: number[] = this.indexOfAll(points, 'timeSincePreviousTrip');
    const poweredOffPtObjArray: PoweredOffPoint[] = poweredOffPoints?.map((pt: number) => {
      return { index: pt, point: points[pt - 1], nextPoint: points[pt] };
    });
    poweredOffPtObjArray?.forEach(obj => {
      const additionalPt: string[] = this.splitTime(
        moment.utc(obj.point.timeStamp),
        moment.utc(obj.nextPoint.timeStamp)
      );
      const newPoints: TimeLinePoint[] = additionalPt?.map((pt: string) => {
        const properties = { ...obj.point.properties, timeStamp: pt };
        return { ...obj.point, properties: properties, timeStamp: pt };
      });
      additionalPoints.push({ index: obj.index, points: newPoints });
    });
    this.addPoweredOffPoints(points, additionalPoints);
  }

  addPoweredOffPoints(points: TimeLinePoint[], additionalPoints: AdditionalPoint[]) {
    additionalPoints = additionalPoints.reverse();
    additionalPoints
      .filter(el => el && el.points)
      .forEach((el: AdditionalPoint) => {
        points.splice(el.index - 1, 1, ...el.points);
      });
    this.points = points;
    this.changeDetectorRef.detectChanges();
  }

  splitTime = (startTime, endTime, interval = 60): string[] => {
    const result = [startTime.format()];
    let time = startTime.add(interval, 's');
    while (time.isBetween(startTime, endTime, undefined, [])) {
      result.push(moment.utc(time).format());
      time = time.add(interval, 's');
    }
    return result;
  };

  indexOfAll = (arr: TimeLinePoint[], key: string) => arr.reduce((acc, el, i) => (el[key] ? [...acc, i] : acc), []);

  handleStartEndTime(pathPoints: TimeLinePoint[]) {
    this.startTimeStamp = this.getLocalizedTimestamp(pathPoints[0].properties.timeStamp);
    this.endTimeStamp = this.getLocalizedTimestamp(pathPoints[pathPoints.length - 1].properties.timeStamp);
  }

  handleSegmentPoints(timeLinePathPoint: TimeLinePathPoint) {
    this.points = timeLinePathPoint.pathPoints;
    if (timeLinePathPoint.segmentIndex) this.points.shift();
    this.handleStartEndTime(this.points);
    this.changeDetectorRef.detectChanges();
  }

  viewEntireTrip() {
    this.leafletService.handleViewEntireTripControlClick();
  }

  toggleTime() {
    if (this.hoverIndex > -1) {
      if (this.hoverIndex == 0 || this.points.length - 1 === this.hoverIndex) {
        this.showTimestamps();
      } else {
        const percent = (this.hoverIndex / this.points.length) * 100;
        this.showStartTime = !(percent < 10);
        this.showEndTime = !(percent > 85);
      }
    } else {
      this.reset();
    }
  }

  reset() {
    this.hoverIndex = -1;
    this.showTimestamps();
  }

  showTimestamps() {
    this.showStartTime = true;
    this.showEndTime = true;
  }
}
