import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { PlatformModule } from '@app/modules/platform/platform.module';
import { UserInfoRoutes } from '@app/modules/user-info/routes/user-info.routes';
import { environment } from '@environments/environment';
import { permissions } from '@environments/shared';
import { ZonarUiAnalyticsService, ZonarUIAnalyticsTokens, ZonarUiTestingTokens } from '@zonar-ui/analytics';
import { ZonarUiAuthModule, ZonarUiPermissionsModule } from '@zonar-ui/auth';
import { FourOhFourRoutes, ZonarUICoreModule, ZonarUiFaviconModule } from '@zonar-ui/core';
import { ZonarUiIconModule } from '@zonar-ui/icon';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { ZonarUiNotificationsModule } from '@zonar-ui/notifications';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@zonar-ui/i18n';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    ZonarUICoreModule,
    ZonarUiFaviconModule,
    ZonarUIMaterialModule,
    ZonarUiNotificationsModule,
    ZonarUiIconModule,
    HttpClientModule,
    HttpClientJsonpModule,
    PlatformModule,
    FlexLayoutModule,
    AppRoutingModule,
    UserInfoRoutes,
    FourOhFourRoutes,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    ZonarUICoreModule,
    ZonarUiFaviconModule,
    ZonarUIMaterialModule,
    ZonarUiNotificationsModule,
    ZonarUiIconModule,
    HttpClientModule,
    HttpClientJsonpModule,
    PlatformModule,
    FlexLayoutModule,
    AppRoutingModule,
    UserInfoRoutes,
    FourOhFourRoutes
  ],
  providers: [
    // Analytics settings
    { provide: ZonarUIAnalyticsTokens.ID, useValue: environment.googleTagManager.id },
    { provide: ZonarUIAnalyticsTokens.AUTH, useValue: environment.googleTagManager.auth },
    { provide: ZonarUIAnalyticsTokens.ENVIRONMENT, useValue: environment.googleTagManager.environment },
    { provide: ZonarUiTestingTokens.CONTAINER_ID, useValue: null },
    { provide: 'pendoParams', useValue: null },
    ZonarUiAnalyticsService,
    // Auth settings
    { provide: 'applicationId', useValue: environment.auth.applicationId },
    { provide: 'environment', useValue: environment.authEnv },
    { provide: 'defaultPermPrefix', useValue: permissions.defaultPermPrefix },
    { provide: 'routePermissions', useValue: permissions.routes },
    { provide: 'redirectUrl', useValue: permissions.defaultRedirectUrl },
    { provide: 'logoutReturnToUrl', useValue: environment.appUrl },
    ZonarUiAuthModule,
    ZonarUiPermissionsModule
  ]
})
export class CoreModule {}
