import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

import { of, Subject } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';

import { ZonarUiIconModule } from '@zonar-ui/icon';

import { DialogComponent } from '@app/modules/shared/components/dialog/dialog.component';
import { TranslateModule, TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';

@Component({
  selector: 'app-asset-list-info',
  standalone: true,
  imports: [
    CommonModule,
    DialogComponent,
    FlexModule,
    ZonarUiIconModule,
    TranslateModule,
    MatButtonModule,
    MatCardModule
  ],
  templateUrl: './asset-list-info.component.html',
  styleUrls: ['./asset-list-info.component.scss']
})
export class AssetListInfoComponent implements OnDestroy, OnInit {
  public translated: any;
  private onDestroy$ = new Subject<void>();

  constructor(public translateService: TranslateService, public translations: Translations) {}

  ngOnInit(): void {
    this.translations.translationsLoadState
      .pipe(
        filter(loadstate => loadstate === ResourceLoadState.LOAD_SUCCESSFUL),
        mergeMap(_ => {
          return of(
            this.translateService.instant([
              this.translations.appAssetList.assetList,
              this.translations.appAssetList.assetListInfoText
            ])
          );
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(translations => {
        this.translated = translations;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
