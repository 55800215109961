import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { switchMap, of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavConfigService {
  translated;

  constructor(public translateService: TranslateService, public translations: Translations) {}

  private SIDENAV_PARAMS_NA = {
    expanded: true,
    footerOffset: true,
    hideChangeCompanyButton: false,
    hideLogoutButton: false,
    lockExpansion: false,
    mobileOpened: true,
    useDefaultHelpItem: true,
    enableContentResizing: true
  };

  private SIDENAV_PARAMS_EU = {
    expanded: false,
    footerOffset: false,
    hideChangeCompanyButton: true,
    hideLogoutButton: true,
    lockExpansion: true,
    mobileOpened: false,
    useDefaultHelpItem: false
  };

  private SIDENAV_HEADER_CONFIG = {
    displayName: true,
    avatarImageUrl: false
  };

  private SIDENAV_FOOTER_CONFIG = {
    items: []
  };

  getSidenavFooterConfig() {
    return this.SIDENAV_FOOTER_CONFIG;
  }

  getSidenavHeaderConfig() {
    return this.SIDENAV_HEADER_CONFIG;
  }

  // TODO: determine whether we want to rip out EU logic (timing dependent). If so, can deprecate this method.
  // We will be able to remove some strings from our translation files - Sidenav will have to internally manage them.
  getSidenavMenuConfig$(region: string = environment.region): Observable<{}> {
    return this.translateService
      .get([
        this.translations.sidenav.activityFeed,
        this.translations.sidenav.maps,
        this.translations.sidenav.vdoDashboard
      ])
      .pipe(
        switchMap(translated => {
          this.translated = translated;
          const vdoLink = environment.euUrl?.vdoDashboardUrl ? environment.euUrl.vdoDashboardUrl : '';
          const SIDENAV_MENU_CONFIG_EU = [
            {
              text: this.translated[this.translations.sidenav.vdoDashboard],
              icon: 'dashboard',
              route: vdoLink,
              routerLink: vdoLink,
              appId: '',
              children: [],
              isExpanded: false
            },
            {
              text: this.translated[this.translations.sidenav.maps],
              icon: 'map',
              route: `${environment.appUrl}`,
              routerLink: `${environment.appUrl}`,
              appId: `${environment.auth.applicationId}`,
              children: [],
              isExpanded: false
            }
          ];
          if (region == 'EU') {
            return of(SIDENAV_MENU_CONFIG_EU);
          }
        })
      );
  }

  getSidenavParams(region: string = environment.region) {
    if (region == 'EU') return this.SIDENAV_PARAMS_EU;
    return this.SIDENAV_PARAMS_NA;
  }
}
