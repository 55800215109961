import { EntityResource } from '@app/store/filters/models/filters.model';

export enum FilterProps {
  COMPANY = 'company',
  DIVISIONS = 'divisions',
  LOCATIONS = 'locations',
  ZONES = 'zones'
}
export interface FilterDialogState {
  powerOn: boolean;
  company: string;
  divisions: string[];
  locations: string[];
  driver?: EntityResource;
  zone?: EntityResource;
}

export const initialFilterDialogState: FilterDialogState = {
  powerOn: undefined,
  company: null,
  divisions: [],
  locations: [],
  driver: null,
  zone: null
};

export interface FilterChangeEvent {
  entity: FilterProps;
  value: string | string[];
  options?: { title: string; value: string }[];
}
