import { Component } from '@angular/core';

@Component({
  selector: 'app-address-list-item-skeleton',
  templateUrl: './address-list-item-skeleton.component.html',
  styleUrls: ['./address-list-item-skeleton.component.scss']
})
export class AddressListItemSkeletonComponent {
  constructor() {}
}
