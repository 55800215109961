import { Injectable } from '@angular/core';
import { IDivision } from '@zonar-ui/auth/lib/models/company.model';

@Injectable({
  providedIn: 'root'
})
export class DivisionCacheService {
  constructor() {}
  divisions: IDivision[];
}
