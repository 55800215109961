import { Injectable } from '@angular/core';
import { AppState } from '@app/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, distinctUntilChanged, mergeMap, switchMap } from 'rxjs/operators';
import { AutocompleteActions } from '@app/store/autocomplete/actions';
import { selectAllFilters } from '@app/store/filters/selectors/filters.selectors';
import { buildAssetsParams, objectShallowEquality } from '@app/modules/location-client/utilities';
import { AutocompleteSearchService } from '@app/services/autocomplete-search.service';
import { EMPTY, of } from 'rxjs';
import { selectAssetParams } from '@app/store/asset/selectors/assets.selectors';

@Injectable()
export class AutocompleteEffects {
  constructor(
    private actions$: Actions,
    private acSearchSvc: AutocompleteSearchService,
    private store: Store<AppState>
  ) {}

  fetchResults$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AutocompleteActions.fetchAutocompleteResults),
      concatLatestFrom(() => [this.store.select(selectAllFilters), this.store.select(selectAssetParams)]),
      distinctUntilChanged((prev, next) => objectShallowEquality(prev, next)),
      switchMap(([{ searchParams }, filters, assetsParams]) => {
        const assetParams = buildAssetsParams(assetsParams, filters);
        return this.acSearchSvc.executeAutocompleteSearch(searchParams, assetParams);
      }),
      mergeMap(response => {
        return of(AutocompleteActions.fetchAutocompleteSuccess({ response }));
      }),
      catchError((error, _) => {
        this.store.dispatch(AutocompleteActions.fetchAutocompleteFailure({ fetchAutocompleteError: error }));
        return EMPTY;
      })
    );
  });
}
