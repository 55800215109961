import { Injectable } from '@angular/core';
import { PendoService } from '@zonar-ui/analytics';
import { isLocalDevEnv } from '@app/modules/shared/utilities/utilities';

export enum TrackedEvents {
  ZONES_ENABLED_ON_APP_LOAD = 'Zones Enabled On Load',
  ZONES_DISABLED_ON_APP_LOAD = 'Zones Disabled On Load',
  ZONES_ENABLED_ON_CLICK = 'Zones Enabled On Click',
  ZONES_DISABLED_ON_CLICK = 'Zones Disabled On Click'
}

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class EventTrackingFacade {
  constructor(private trackingService: PendoService) {}

  trackEvent(event: string, metadata?: any): void {
    if (isLocalDevEnv()) {
      console.log(`EventTrackingFacade.trackEvent - EVENT: ${event} METADATA:`, metadata);
    } else {
      // TODO: move this logic into zonar-ui-analytics
      if (window?.pendo) {
        this.trackingService.trackEvent(event, metadata);
      } else {
        window.setTimeout(() => {
          this.trackEvent(event, metadata);
        }, 10);
      }
    }
  }
}
