import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { environment, environment as env } from '@environments/environment';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { NotificationService } from '@app/services/notification.service';

@Injectable()
export class ServerOfflineRetryNotification implements HttpInterceptor {
  constructor(private notificationService: NotificationService, private facade: LocationFacade) {}

  waitTime = 60000;
  // If we need to ignore certain urls, uncomment and update the array below
  // bypassedUrls = ["some url to bypass"];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If we need to ignore certain urls, uncomment the below if block
    // if (this.bypassedUrls.some(bypassUrl => req.url.startsWith(bypassUrl))) {
    //   return next.handle(req);
    // }

    return next.handle(req).pipe(
      timeout(this.waitTime),
      map(response => {
        if (!(response instanceof HttpResponse && response.url.includes(env.locationApi.url))) {
          return response;
        }

        return response;
      }),
      catchError(err => {
        if (err instanceof TimeoutError) {
          // TODO: determine how we want to handle timeout errors, and implement as described here:
          //  https://jira.zonarsystems.net/browse/ZTT-1059
          // this.notificationService.openPoorNetworkConnectionNotification();
        }

        // we  need to set the error states for assets and company so spinner can stop showing
        this.facade.setAssetsError(err);
        this.facade.setCompaniesError();

        return throwError(err);
      })
    );
  }
}
