import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, forkJoin, merge, of, throwError, timer } from 'rxjs';
import { catchError, filter, map, mergeMap, retry, switchMap, takeUntil } from 'rxjs/operators';
import {
  Asset,
  AssetsCountResult,
  GetAssetsResult,
  GetEventsResult,
  PostBodyConfig,
  PostBody
} from '@app/modules/location-client/location-api.models';
import { DivisionMappingService } from '@app/services/division-mapping.service';
import { DataDogService } from '@app/services/data-dog.service';
import { ServerOfflineService } from '@app/services/server-offline.service';

export const ASSETS_ENDPOINT = `${environment.locationApi.url}/assets`;

@Injectable({
  providedIn: 'root'
})
export class LocationApiService {
  constructor(
    private http: HttpClient,
    private divisionMapper: DivisionMappingService,
    private datadog: DataDogService,
    private serverOfflineService: ServerOfflineService
  ) {}

  // private helper function wrapping post to facilitate recursive calls for pagination
  _assetsPost(body: PostBody) {
    const accessControlHeader = new HttpHeaders().set('Access-Control-Max-Age', '86400'); // TODO are we expecting this on the backend?
    return this.http.post(ASSETS_ENDPOINT, body, { headers: accessControlHeader }) as Observable<GetAssetsResult>;
  }

  // private helper function to get all children of a list of divisions, and flatten the whole collection
  _getDivisionsAndChildrenAsSet(divisionIds: string[]): string[] {
    // Ensure divisionIds is not null or undefined
    if (!divisionIds) {
      return [];
    }

    // Get child divisions safely
    const childDivisions = this.divisionMapper.getChildDivisionsForParentIds(divisionIds);

    // Ensure childDivisions is not null or undefined
    if (!childDivisions) {
      return [...new Set(divisionIds)];
    }

    const childDivisionIds = childDivisions.map(cd => cd.id);

    // Combine and deduplicate the division IDs
    return [...new Set([...divisionIds, ...childDivisionIds])];
  }

  sendAssetsPostRequest(body: PostBody, fetchAllPages: boolean): Observable<Asset[]> {
    if (Array.isArray(body.divisionIds) && body.divisionIds.length) {
      body.divisionIds = this._getDivisionsAndChildrenAsSet(body.divisionIds);
    }
    // Check for pageSize in the provided body or fallback to env var
    const pageSize = body.pageSize || environment.apiRequestPageSize;

    if (fetchAllPages) {
      return this.getAssetsCount(body).pipe(
        map(response => {
          const { count: totalCount } = response;
          const pageCount = Math.ceil(totalCount / pageSize);
          return { totalCount, pageCount, pageSize };
        }),
        mergeMap(({ totalCount, pageCount, pageSize }) => {
          if (totalCount < 1) {
            return of([]); // Return empty array if no assets
          }
          // Create an array of observables for each page request, each with its own retry logic
          const pageRequests = Array.from({ length: pageCount }, (_, index) => {
            const offset = index * pageSize;
            return this._assetsPost({ ...body, offset, pageSize }).pipe(
              map(res => res.assets),
              retry(3), // Retry individual page fetch up to 3 times
              catchError(err => {
                console.error(`Error fetching page ${index + 1}:`, err);
                return of([]); // Return empty array in case of failure
              })
            );
          });

          // Use forkJoin to execute all requests in parallel
          return forkJoin(pageRequests).pipe(map(pagesOfAssets => pagesOfAssets.flat()));
        }),
        catchError(err => {
          console.error('Error after processing all pages:', err);
          return throwError(err); // Rethrow or handle the error as needed
        })
      );
    }

    // If not fetching all pages, return a single page with retry logic applied to individual request
    return this._assetsPost({ ...body, pageSize }).pipe(
      map(res => res.assets),
      retry(3), // Retry the single page fetch up to 3 times
      catchError(err => {
        console.error('Error after all retries:', err);
        return throwError(err);
      })
    );
  }

  getAssets(postBodyConfig: PostBodyConfig, fetchAllPages: boolean = false): Observable<Asset[]> {
    const body: PostBody = { ...postBodyConfig };
    return this.sendAssetsPostRequest(body, fetchAllPages);
  }

  getAssetsCount(postBodyConfig: PostBodyConfig): Observable<AssetsCountResult> {
    const url = `${ASSETS_ENDPOINT}/count`;
    const body: PostBody = { ...postBodyConfig };
    if (Array.isArray(body.divisionIds) && body.divisionIds.length) {
      body.divisionIds = this._getDivisionsAndChildrenAsSet(body.divisionIds);
    }
    return this.http.post(url, body) as Observable<AssetsCountResult>;
  }

  getAssetsByRadius(centerLatLon: [lat: number, lon: number], postBodyConfig: PostBodyConfig): Observable<Asset[]> {
    const body: PostBody = { ...postBodyConfig };
    body.circle = [centerLatLon[0], centerLatLon[1], environment.nearbyAssets.searchRadiusMeters];
    body.pageSize = environment.nearbyAssets.assetsToReturn;
    return this.sendAssetsPostRequest(body, false);
  }

  // NOTE: used by asset details view, used by current history componenent (for deeplinks), used by selected asset service
  getAssetById(assetId: string): Observable<Asset> {
    const url = `${ASSETS_ENDPOINT}/${assetId}`;
    return this.http.get(url) as Observable<Asset>;
  }

  // TODO: needs to be updated to be functional once events endpoint is added to new API
  // method is not commented out because other things depend on it,
  // and it is too time-consuming to refactor / comment those
  getOpenEventsByAssetId(assetId: string, params = null): Observable<GetEventsResult> {
    const url = `NO_CURRENT_ENDPOINT/${assetId}`;
    return this.http.get(url, { params }) as Observable<GetEventsResult>;
  }

  /**
   * Calls the provided callback on the pollingInterval provided
   * @param callback the callback function to call on an interval
   * @param pollingInterval the interval to call the callback function
   * @param cancelObs an observable to watch which will cancel the polling when it emits
   *
   * In order to properly cancel polling, recommend using tap to push to a second observable which can be used for cancelObs
   * example:
   * ```
   * foo$ = BehaviorSubject<any>('foo');
   * bar$ = BehaviorSubject<any>('bar');
   * cancelObs$ = Subject<Boolean>();
   *
   * foo$.pipe(
   * combineLatestWith(bar$),
   * tap(() => cancelObs$.next(true)),
   * switchMap(([f, b]) => polling(() => foobar(f, b), 500, cancelObs$)))
   * .subscribe...
   * ```
   */
  polling<T>(callback: () => Observable<T>, pollingInterval: number, cancelObs: Observable<any>): Observable<T> {
    return this.serverOfflineService.onlineStatus$.pipe(
      filter(isOnline => isOnline),
      switchMap(() =>
        timer(0, pollingInterval).pipe(
          switchMap(() => callback()),
          takeUntil(merge(cancelObs, this.serverOfflineService.onlineStatus$.pipe(filter(isOnline => !isOnline))))
        )
      )
    );
  }
}
