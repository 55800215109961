import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@environments/environment';
import { DriverProfle } from '@app/modules/driver/models/driver.models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DriverApiService {
  constructor(private http: HttpClient) {}

  DRIVER_PROFILES = 'driverprofiles';

  getDriverProfile(profileId: string): Observable<DriverProfle> {
    const url = `${env.coreEntityApiBase.url}/${this.DRIVER_PROFILES}/${profileId}`;
    return this.http.get(url) as Observable<DriverProfle>;
  }
}
