import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPlaces from '../reducers/places.reducer';

export const placesState = createFeatureSelector<fromPlaces.placesState>(fromPlaces.placesFeatureKey);

export const selectPlacesLoadError = createSelector(placesState, state => {
  return state.placesLoadError;
});

export const selectPlacesLoadState = createSelector(placesState, state => {
  return state.placesLoadState;
});

export const selectPlacesResults = createSelector(placesState, state => {
  return state.placesResult;
});
