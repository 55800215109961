import { Injectable } from '@angular/core';
import {
  GTCxEventData,
  EventSeverity,
  GetAssetsResult,
  GetEventsResult,
  OpenEvent
} from '@app/modules/location-client/location-api.models';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { LocationApiService } from '@app/modules/location-client/location-api.service';
import { EventMetadata, EventDetails } from '@app/modules/events/events.model';
import { catchError, expand, reduce, take, tap } from 'rxjs/operators';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import * as moment from 'moment';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { EnvironmentService } from '@app/services/environment.service';
import { GetLocaleService } from '@app/services/get-locale.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(
    private locationApiService: LocationApiService,
    private translateService: TranslateService,
    private translations: Translations,
    private getLocalesService: GetLocaleService,
    private envService: EnvironmentService
  ) {
    this.translateService
      .get([this.translations.eventsService.criticalEvents, this.translations.eventsService.unableToLoad])
      .pipe(take(1))
      .subscribe(translated => {
        this.criticalIssues = translated[this.translations.eventsService.criticalEvents];
        this.unableToLoad = translated[this.translations.eventsService.unableToLoad];
      });
  }
  public eventsLoadingStatus$ = new BehaviorSubject<ResourceLoadState>(ResourceLoadState.INITIAL);
  criticalIssues;
  unableToLoad;
  browserLocale = this.getLocalesService.browserLocale;
  l10n = 'l10n';
  dateFormat = this.envService.getEnvironmentProperty(this.l10n)[this.browserLocale].dateFormat;
  timeFormatWithSeconds = this.envService.getEnvironmentProperty(this.l10n)[this.browserLocale].timeFormatWithSeconds;

  getLabelFromEvents(events: OpenEvent[]): string {
    if (events && events.length > 0) {
      const criticalCount = events.reduce((a, c) => +a + (c.severity === EventSeverity.CRITICAL ? 1 : 0), 0);
      if (criticalCount === 1) {
        return events.find(e => +e.severity === EventSeverity.CRITICAL).title;
      }
      if (criticalCount > 1) {
        return `${criticalCount} ${this.criticalIssues}`;
      }
      // we could put checks for WARNING and INFO here at some point
    }
    return null;
  }

  getSeverityFromEvents(events: OpenEvent[]): EventSeverity {
    if (events && events.length > 0) {
      const maxSeverity = events.reduce((a, c) => Math.min(+c.severity, +a), 99);
      return maxSeverity as EventSeverity;
    }
    return EventSeverity.INFO;
  }

  getEventTimestamp(event): string {
    let timeStamp = event.startTime ?? event.openEventInsertTs ?? this.unableToLoad;
    if (timeStamp != this.unableToLoad) {
      timeStamp = moment.utc(timeStamp).local().format(`${this.dateFormat} ${this.timeFormatWithSeconds}`);
    }

    return timeStamp;
  }

  getEventMetadata(event: GTCxEventData): EventMetadata[] {
    if (!event.jsonData.metadata || event.jsonData.metadata.length === 0) {
      return [];
    }
    return event.jsonData.metadata.map(m => ({ displayLabel: m.label, value: m.value }));
  }

  getOpenEventsDetails(openEvents: GTCxEventData[]): EventDetails[] {
    if (!openEvents || openEvents.length === 0) {
      return [];
    }
    return openEvents
      .filter(event => event.severity === EventSeverity.CRITICAL)
      .map(event => ({
        title: event.jsonData.title,
        timeStamp: this.getEventTimestamp(event),
        details: this.getEventMetadata(event)
      }));
  }

  fetchOpenEventsForAsset(assetId: string): Observable<GTCxEventData[]> {
    const params = {
      pageSize: 50
    };

    this.eventsLoadingStatus$.next(ResourceLoadState.LOADING);
    return this.locationApiService.getOpenEventsByAssetId(assetId, params).pipe(
      expand(res => {
        if (res.nextPageToken && res.nextPageToken !== '') {
          return this.locationApiService.getOpenEventsByAssetId(assetId, {
            ...params,
            nextPageToken: res.nextPageToken
          });
        } else {
          return EMPTY as Observable<GetAssetsResult>;
        }
      }),
      reduce((acc: GTCxEventData[], res: GetEventsResult) => acc.concat(res.events), []),
      catchError((err, _) => {
        this.eventsLoadingStatus$.next(ResourceLoadState.LOAD_FAILURE);
        return EMPTY;
      })
    );
  }
}
