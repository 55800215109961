import { divIcon, point } from 'leaflet';
import { MarkerIconService } from '@app/modules/location/services/marker-icon.service';
import { BehaviorSubject } from 'rxjs';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { Injectable } from '@angular/core';
import { tileLayerBaseOptions } from './leaflet-config.service';

@Injectable({
  providedIn: 'root'
})
export class ClusterLayerConfigService {
  constructor(private markerIconService: MarkerIconService) {}
  pinClickAsset$ = new BehaviorSubject<ViewableAsset>(null);

  clusterGroupOptions = {
    showCoverageOnHover: false,
    zoomToBoundsOnClick: false,
    spiderfyOnMaxZoom: false,
    removeOutsideVisibleBounds: true,
    maxClusterRadius: zoom => {
      if (zoom >= tileLayerBaseOptions.maxZoom - 1) {
        return 1;
      }
      return 80;
    },
    iconCreateFunction: cluster => {
      return divIcon({
        className: 'marker-cluster-container',
        iconSize: point(24, 24),
        html: `<div class="marker-cluster-icon">${cluster.getChildCount()}</div>`
      });
    },
    pointToLayer: (geoJson, latlng) => {
      return this.markerIconService.fetchGeoJsonMarker(
        geoJson,
        latlng,
        geoJson.properties.assetName,
        geoJson.properties.positionMultiplier
      );
    },
    onEachFeature: (feature, layer) => {
      if (feature?.properties?.assetId) {
        layer.on('click', () => {
          this.pinClickAsset$.next(feature.properties);
        });
      }
    }
  };
}
