import { Component } from '@angular/core';
import { LocationFacade } from '@app/modules/location/facade/location.facade';

@Component({
  selector: 'app-desktop-sidebar',
  templateUrl: './desktop-sidebar.component.html',
  styleUrls: ['./desktop-sidebar.component.scss']
})
export class DesktopSidebarComponent {
  constructor(public locationFacade: LocationFacade) {}
}
