import { IDivisionMap } from '@zonar-ui/auth/lib/models/company.model';

export enum Perms {
  READ_COMPANIES = 'read:companies',
  READ_COMPANY = 'read:company'
}

export interface PermissionsState {
  readCompany: boolean;
  readCompanies: boolean;
  isZonarUser: boolean;
  companies: string[];
  divisions: string[];
  divisionMap: IDivisionMap;
  locations: any[];
  requestStartTime?: number;
}

export const initialPermissionsState: PermissionsState = {
  readCompanies: false,
  readCompany: false,
  isZonarUser: false,
  companies: [],
  divisions: [],
  divisionMap: {},
  locations: [],
  requestStartTime: 0
};
