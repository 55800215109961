import { Injectable } from '@angular/core';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';

@Injectable({
  providedIn: 'root'
})
export class GeoJsonGenerationService {
  constructor() {}

  assetsToGeoJson(assets: ViewableAsset[]): any {
    const results = [];
    Object.values(assets).map(asset => {
      const feature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [asset.longitude, asset.latitude]
        },
        properties: {
          ...asset
        }
      };
      results.push(feature);
    });
    return results;
  }
}
