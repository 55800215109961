import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserInfoComponent } from '@app/modules/user-info/components/user-info/user-info.component';
import { AuthGuard } from '@auth0/auth0-angular';

const USER_INFO_ROUTES: Routes = [
  {
    path: 'user-info',
    component: UserInfoComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(USER_INFO_ROUTES)],
  exports: [RouterModule]
})
export class UserInfoRoutes {}
