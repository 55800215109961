import { createReducer, on } from '@ngrx/store';
import * as LayoutActions from '../actions/layout.actions';
import { SortAttribute } from '@app/store/filters/models/filters.model';

export const layoutFeatureKey = 'layout';

export enum ViewContext {
  DETAILS = 'details',
  LIST = 'list',
  MOBILE_MAP = 'mobileMap',
  SPLIT_VIEW = 'splitView'
}

export enum DetailsSubcontext {
  LIVE = 'live',
  HISTORY = 'history'
}

export interface ViewState {
  context: ViewContext;
  subContext: DetailsSubcontext;
  filtersOpen: boolean;
  paneOpen: boolean;
}

// TODO: this can be removed/refactored in favor of ViewState since that is all it contains now
export interface LayoutState {
  view: ViewState;
}

export const initialState: LayoutState = {
  view: {
    context: ViewContext.LIST,
    subContext: DetailsSubcontext.LIVE,
    filtersOpen: false,
    paneOpen: true
  }
};

export const reducer = createReducer(
  initialState,

  on(LayoutActions.setViewContext, (state, { context }) => {
    return { ...state, view: { ...state.view, context } };
  }),

  on(LayoutActions.setViewSubContext, (state, { subContext }) => {
    return { ...state, view: { ...state.view, subContext } };
  }),

  on(LayoutActions.setViewFiltersOpen, (state, { filtersOpen }) => {
    return { ...state, view: { ...state.view, filtersOpen } };
  }),

  on(LayoutActions.setViewPaneOpen, (state, { paneOpen }) => {
    return { ...state, view: { ...state.view, paneOpen } };
  }),

  on(LayoutActions.toggleViewPaneOpen, state => {
    const paneOpen = !state.view.paneOpen;
    return { ...state, view: { ...state.view, paneOpen } };
  })
);
