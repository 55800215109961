import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import * as DriverActions from '@app/store/driver/actions/driver.actions';
import * as driverStateSelectors from '@app/store/driver/selectors/driver.selector';
import { map } from 'rxjs/operators';
import { getDateTimeText, toTitleCase } from '@app/modules/shared/utilities/utilities';
import { DriverProfile } from '@app/modules/driver/models/driver.models';
import { Observable, of } from 'rxjs';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';

@Injectable({
  providedIn: 'root'
})
export class DriverFacade {
  constructor(private store: Store<AppState>) {}

  unknownSourceText = 'Unknown Source'; // ZTT-2920 to translate
  unknownTsText = 'Unknown Time'; // ZTT-2920 to translate

  setDriverProfileById(driverProfileId) {
    this.store.dispatch(DriverActions.loadDriverProfile({ driverProfileId }));
  }

  getDriverProfile() {
    return this.store.select(driverStateSelectors.selectDriverProfile);
  }

  getDriverProfileLoadState() {
    return this.store.select(driverStateSelectors.selectDriverProfileLoadState);
  }

  clearDriverProfile() {
    return this.store.dispatch(DriverActions.clearDriverProfile());
  }

  formatDriverAssociationText(driverChangeSource: string): string {
    return driverChangeSource ? toTitleCase(driverChangeSource) : this.unknownSourceText;
  }
  getDriverEventTimestamp(driverEventTs: string, dateTimeFormat = 'MM/DD/YYYY hh:mm:ss A'): string {
    return driverEventTs ? getDateTimeText(driverEventTs, dateTimeFormat) : this.unknownTsText;
  }

  getDriverAssociationSourceAndTime(asset: ViewableAsset, dateTimeFormat = 'MM/DD/YYYY hh:mm:ss A') {
    const driverChangeApplicationName = this.formatDriverAssociationText(asset.driverChangeSource);
    const dateTimeText = this.getDriverEventTimestamp(asset.driverEventTs, dateTimeFormat);
    return of(`${driverChangeApplicationName} at ${dateTimeText}`);
  }

  getDriverAssetProfileLoadState() {
    return this.store.select(driverStateSelectors.selectDriverAssetProfileLoadState);
  }

  getDriverAssetProfile(): Observable<DriverProfile[]> {
    return this.store.select(driverStateSelectors.selectDriverAssetProfile);
  }

  /**
   * Checks against each driver association in store, and keeps the most recent applicable
   * association event, per driver, for the trip period determined by start/end timestamps.
   * @param startTimestamp Timestamp for the beginning of the Trip
   * @param endTimestamp Timestamp for the end of the Trip
   */
  getDriverAssetProfilesForTrip(startTimestamp: string, endTimestamp: string): Observable<DriverProfile[]> {
    return this.store.select(driverStateSelectors.selectDriverAssetProfile).pipe(
      map(profiles => {
        let profilesForTrip = [...profiles];
        // sort into reverse chronological order for later logic
        profilesForTrip.sort((a, b) =>
          a.startTimestamp > b.startTimestamp ? -1 : a.startTimestamp < b.startTimestamp ? 1 : 0
        );
        // keep associations from the same day only
        const startTsDate = new Date(startTimestamp).getDate();
        profilesForTrip = profilesForTrip.filter(p => new Date(p.startTimestamp).getDate() == startTsDate);
        // filter out associations starting after the endTimestamp
        profilesForTrip = profilesForTrip.filter(p => p.startTimestamp < endTimestamp);
        // if there are multiple associations starting before the startTimestamp, keep only the latest of these
        const latestPriorToTrip = profilesForTrip.findIndex(p => p.startTimestamp < startTimestamp);
        if (latestPriorToTrip != -1) profilesForTrip.splice(latestPriorToTrip + 1);
        // keep only the latest association for a given driver, by recursively checking whether an association already exists with that driver
        profilesForTrip = profilesForTrip.reduce(
          (prev, curr) => (prev.findIndex(driver => driver.id == curr.id) >= 0 ? [...prev] : [...prev, curr]),
          []
        );
        // put them back in true chronological order
        profilesForTrip.reverse();

        return profilesForTrip;
      })
    );
  }

  /**
   * Takes an array of DriverProfiles and returns an array which has been filtered to include only those with a startTimestamp matching
   * the day of the timestamp param, and then sorts them into reverse-chronological order.
   * @param profiles Array of DriverProfiles
   * @param timestamp Timestamp to match the day of
   */
  sortProfilesForDay(profiles: DriverProfile[], timestamp: string): DriverProfile[] {
    const dateCheck = new Date(timestamp).getDate();
    profiles = profiles.filter(p => new Date(p.startTimestamp).getDate() == dateCheck);
    profiles.sort((a, b) => (a.startTimestamp > b.startTimestamp ? -1 : a.startTimestamp < b.startTimestamp ? 1 : 0));
    return profiles;
  }

  /**
   * Takes a reverse-chronological sorted array of DriverProfiles and returns the Profile
   * which was the most recently associated prior to the provided timestamp
   * @param profiles Array of DriverProfiles
   * @param timestamp Timestamp to match the driver against
   */
  getDriverProfileAtTimestamp(profiles: DriverProfile[], timestamp: string): DriverProfile {
    return profiles.find(p => p.startTimestamp < timestamp);
  }

  setDriverAssetProfile(asset: ViewableAsset) {
    this.store.dispatch(DriverActions.loadDriverAssetProfile({ asset }));
  }

  setDriverAssetProfileDirect(driverAssetProfile) {
    this.store.dispatch(DriverActions.loadDriverAssetProfileSuccess({ driverAssetProfile }));
  }

  setDriverAssetProfileError(driverAssetProfileFailure) {
    this.store.dispatch(DriverActions.loadDriverAssetProfileFailure({ driverAssetProfileFailure }));
  }

  getDriverAssociationSrcTsText(driverProfile: DriverProfile) {
    const source = driverProfile.sourceLabel ? driverProfile.sourceLabel : driverProfile.source;
    const driverChangeApplicationName = this.formatDriverAssociationText(source);
    const dateTimeText = this.getDriverEventTimestamp(driverProfile.startTimestamp);
    return `${driverChangeApplicationName} at ${dateTimeText}`;
  }
}
