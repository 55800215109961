import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ZonesFilterService } from './service/zones-filter.service';
import { Subject, take, takeUntil } from 'rxjs';
import { LocationFacade } from '@app/modules/location/facade/location.facade';

@Component({
  selector: 'app-zones-filter',
  templateUrl: './zones-filter.component.html',
  styleUrls: ['./zones-filter.component.scss']
})
export class ZonesFilterComponent implements OnInit, OnDestroy {
  companyId: string;
  label: string = 'Zone';
  private onDestroy$ = new Subject<void>();
  selectedZone: { id: string; name: string };
  @Output() zonefilterChange: EventEmitter<any> = new EventEmitter();

  constructor(private zonesFilterService: ZonesFilterService, private locationFacade: LocationFacade) {}
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    this.zonesFilterService
      .getCurrentCompanyId()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(companyId => {
        this.companyId = companyId;
      });

    this.locationFacade
      .getAllFilters()
      .pipe(take(1))
      .subscribe(filters => {
        if (filters.filter.zone) {
          const { id, name } = filters.filter.zone;
          this.selectedZone = { id, name };
        }
      });
  }

  onChangeSelectedZone(event: { id: string; name: string }) {
    this.selectedZone = event;
    this.zonesFilterService
      .getZoneById(event.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(zone => {
        this.zonefilterChange.emit(zone);
      });
  }
}
