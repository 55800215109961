<div class="user-info-page" fxLayout="column" fxLayoutGap="2em">

    <mat-card class="user-info-container" class="mat-elevation-z6">

        <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title>User Info</mat-card-title>
            <button mat-icon-button [cdkCopyToClipboard]="user$|async|json">
                <mat-icon>file_copy</mat-icon>
                <span>Copy User Info</span>
            </button>
        </mat-card-header>

        <mat-card-content>
            <pre>{{user$|async|json}}</pre>
        </mat-card-content>

    </mat-card>

    <mat-card class="user-profile-container" class="mat-elevation-z7">
        <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title>User's Map Profiles</mat-card-title>
            <button mat-icon-button [cdkCopyToClipboard]="userProfile$|async|json">
                <mat-icon>file_copy</mat-icon>
                <span>Copy User Profiles</span>
            </button>
        </mat-card-header>

        <mat-card-content>
            <pre>{{userProfile$|async|json}}</pre>
        </mat-card-content>
    </mat-card>

    <mat-card class="user-policy-container" class="mat-elevation-z7">
      <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title>User's Group Policies</mat-card-title>
        <button mat-icon-button [cdkCopyToClipboard]="userPolicy$|async|json">
          <mat-icon>file_copy</mat-icon>
          <span>Copy User Policies</span>
        </button>
      </mat-card-header>

      <mat-card-content>
        <pre>{{userPolicy$|async|json}}</pre>
      </mat-card-content>
    </mat-card>
</div>

