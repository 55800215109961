import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DataDogService } from '@app/services/data-dog.service';
import { environment } from '@environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor() {}

  PRODUCTION_NA = 'production-na';
  PRODUCTION_EU = 'production-eu';

  handleError(error: Error | HttpErrorResponse): void {
    if (this.showConsoleErrors()) {
      console.error(error);
    }
  }

  showConsoleErrors(): boolean {
    return environment.name !== this.PRODUCTION_NA && environment.name !== this.PRODUCTION_EU;
  }
}
