<ng-container *ngFor="let key of formGroupKeys">
  <zui-searchable-dropdown
        [label]="formGroupData[key].label"
        [z_formGroup]="filtersFormGroup"
        [selectOptionsControlName]="formGroupData[key].selectOptionsControlName"
        [placeholder]="formGroupData[key].placeholder"
        [options]="formGroupData[key].options"
        [sortByStringTitle]="formGroupData[key].sortByStringTitle"
        [isMultiple]="formGroupData[key].isMultiple"
        [z_disabled]="formGroupData[key].options.length === 0"
  >
  </zui-searchable-dropdown>
</ng-container>


