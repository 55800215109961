import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ZonarUiIconModule } from '@zonar-ui/icon';
import { SharedModule } from '@app/modules/shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MapContentSelectorComponent } from './components/map-content-selector/map-content-selector.component';
import { MobileContainerComponent } from './components/mobile-container/mobile-container.component';
import { RotateMessageComponent } from '@app/modules/mobile/components/rotate-message/rotate-message.component';

@NgModule({
  declarations: [MapContentSelectorComponent, MobileContainerComponent, RotateMessageComponent],
  imports: [CommonModule, FlexLayoutModule, MatToolbarModule, SharedModule, ZonarUiIconModule],
  exports: [MobileContainerComponent, RotateMessageComponent]
})
export class MobileModule {}
