import { createReducer, on } from '@ngrx/store';
import * as DriverProfileActions from '@app/store/driver/actions/driver.actions';
import { DriverProfle } from '@app/modules/driver/models/driver.models';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { DriverAsset } from '@app/modules/driver/models/driver.models';

export const driverFeatureKey = 'drivers';

export interface DriverState {
  driverProfile: DriverProfle;
  driverProfileFailure: any;
  driverProfileLoadState: ResourceLoadState;
  driverAssetProfile: DriverAsset[];
  driverAssetProfileFailure: any;
  driverAssetProfileLoadState: ResourceLoadState;
}

export const initialState: DriverState = {
  driverProfile: null,
  driverProfileFailure: null,
  driverProfileLoadState: ResourceLoadState.INITIAL,
  driverAssetProfile: [],
  driverAssetProfileFailure: null,
  driverAssetProfileLoadState: ResourceLoadState.LOADING
};

export const reducer = createReducer(
  initialState,

  on(DriverProfileActions.loadDriverProfileSuccess, (state, { driverProfile }) => {
    return {
      ...state,
      driverProfile,
      driverProfileLoadState: ResourceLoadState.LOAD_SUCCESSFUL
    };
  }),

  on(DriverProfileActions.loadDriverProfileFailure, (state, { driverProfileFailure }) => {
    return {
      ...state,
      driverProfileFailure,
      driverProfileLoadState: ResourceLoadState.LOAD_FAILURE
    };
  }),

  on(DriverProfileActions.clearDriverProfile, _ => {
    return { ...initialState };
  }),

  on(DriverProfileActions.loadDriverAssetProfileSuccess, (state, { driverAssetProfile }) => {
    return {
      ...state,
      driverAssetProfile,
      driverAssetProfileLoadState: ResourceLoadState.LOAD_SUCCESSFUL
    };
  }),

  on(DriverProfileActions.loadDriverAssetProfileFailure, (state, { driverAssetProfileFailure }) => {
    return {
      ...state,
      driverAssetProfileFailure,
      driverAssetProfileLoadState: ResourceLoadState.LOAD_FAILURE
    };
  }),

  on(DriverProfileActions.loadDriverAssetProfile, state => {
    return {
      ...state,
      driverAssetProfileLoadState: ResourceLoadState.LOADING
    };
  })
);
