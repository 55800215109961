<div class="view-switcher-container">
  <mat-button-toggle-group [(value)]="subContextView" (change)="handleSubcontextChange($event)">
    <mat-button-toggle data-e2e-view-switcher-live value="{{liveSubContext}}" id="toggle-live" aria-label="Select live view">
      {{ translateService.get(translations.appViewSwitcher.live) | async | translate }}
    </mat-button-toggle>
    <mat-button-toggle data-e2e-view-switcher-history value="{{historySubContext}}" id="toggle-history" aria-label="Select historical view">
      {{ translateService.get(translations.appViewSwitcher.history) | async | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
