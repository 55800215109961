import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { DataDogService } from '@app/services/data-dog.service';
import * as DriverActions from '@app/store/driver/actions/driver.actions';
import { DriverApiService } from '@app/modules/driver/services/driver-api.service';
import { switchMap, map, catchError, filter } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { CoreCompanyApiService } from '@app/services/core-company-api.service';
import { environment as env } from '@environments/environment';
import { DriverAssetService } from '@app/modules/driver/services/driver-asset.service';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { selectRecentPathStartTime, selectChosenAsset } from '@app/store/asset/selectors/assets.selectors';

import * as moment from 'moment';

@Injectable()
export class DriverEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private datadog: DataDogService,
    private companyApiService: CoreCompanyApiService,
    private driverApiService: DriverApiService,
    private driverAssetService: DriverAssetService
  ) {}

  isTestingDriverProfileError = false;
  private isTestingHomeLocationError = false;

  loadDriverProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriverActions.loadDriverProfile),
      filter(({ driverProfileId }) => Boolean(driverProfileId)),
      switchMap(({ driverProfileId }) => {
        return this.driverApiService.getDriverProfile(driverProfileId).pipe(
          switchMap(driverProfile => {
            // for testing purposes only
            if (!env.production) {
              if (this.isTestingDriverProfileError) {
                return throwError('test error');
              }

              if (this.isTestingHomeLocationError) {
                driverProfile.homeLocationId = 'not_valid_id';
              }
            }

            if (driverProfile?.homeLocationId) {
              return this.companyApiService.getLocation(driverProfile.companyId, driverProfile.homeLocationId).pipe(
                map(response => {
                  const homeLocation = response.body.name;
                  driverProfile = {
                    ...driverProfile,
                    homeLocation
                  };

                  return driverProfile;
                }),
                catchError(companyApiFailure => {
                  this.datadog.addRumError(companyApiFailure);

                  // even if homeLocation fetch fails, we would have a driver profile at this point and should still send that to the store
                  return of(driverProfile);
                })
              );
            } else {
              return of(driverProfile);
            }
          }),
          map(driverProfile => {
            return DriverActions.loadDriverProfileSuccess({ driverProfile });
          }),
          catchError(driverProfileFailure => {
            this.datadog.addRumError(driverProfileFailure);
            return of(DriverActions.loadDriverProfileFailure({ driverProfileFailure }));
          })
        );
      })
    );
  });

  loadDriverAssetProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DriverActions.loadDriverAssetProfile),
      switchMap(action => {
        const startTimeBacktoZero = moment(action.asset.startTime).startOf('day').toDate();
        const endTime = moment(startTimeBacktoZero).endOf('day').toDate();
        return this.driverAssetService
          .getDriverDetails(action.asset.assetId, startTimeBacktoZero.toISOString(), endTime.toISOString())
          .pipe(
            switchMap(driverAssetProfile => {
              return of(driverAssetProfile);
            }),
            map(driverAssetProfile => {
              return DriverActions.loadDriverAssetProfileSuccess({ driverAssetProfile });
            }),
            catchError(driverAssetProfileFailure => {
              this.datadog.addRumError(driverAssetProfileFailure);
              return of(DriverActions.loadDriverAssetProfileFailure({ driverAssetProfileFailure }));
            })
          );
      })
    );
  });
}
