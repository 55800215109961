import { Injectable, Inject } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetLocaleService {
  constructor(@Inject('Window') window: Window) {
    this.window = window;
    this.browserLocale = this.getBrowserLocales()[0];

    if (!(this.browserLocale in environment.l10n)) {
      this.browserLocale = environment.i18n.defaultLanguage;
    }
  }
  window;
  browserLocale: string;
  // taken from https://phrase.com/blog/posts/detecting-a-users-locale/
  getBrowserLocales() {
    // works for IE
    const browserLocales =
      this.window.navigator.languages === undefined
        ? [this.window.navigator.language]
        : this.window.navigator.languages;

    if (!browserLocales) {
      return [environment.i18n.defaultLanguage];
    }

    return browserLocales;
  }
}
