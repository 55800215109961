<div class="leaflet-control map-options" id="mapOptionsControl">
    <a 
    class="map-options-button leaflet-control-pill" 
    fxLayoutAlign="start center" 
    (mouseenter)="toggleMapOptionsMenu()"
    *ngIf="!isMapOptionsMenuOpen">
        <zui-icon icon="layers"></zui-icon>
        <span class="map-options-button-icon"></span>
        <span class="map-options-button-label" fxShow.xs="false">{{
            translateService.get(translations.map.layersControl.layers) | async | translate }}</span>
    </a>
    <div class="map-options-popup-panel" (mouseleave)="toggleMapOptionsMenu()" *ngIf="isMapOptionsMenuOpen">
     <app-map-options-menu class="map-options-menu"
        [layer] = "layer"
        (layerChange) = "onLayerChange($event)"
        [checkboxesVisible]="checkboxesVisible"
        [clusterEnabled] = "clusterEnabled"
        (clusterEnabledChange) = "onClusterEnabledChange($event)"
        [incidentsEnabled] = "incidentsEnabled"
        (incidentsEnabledChange) = "onIncidentsEnabledChange($event)"
        [trafficEnabled] = "trafficEnabled"
        (trafficEnabledChange) = "onTrafficEnabledChange($event)"
        [zonesEnabled] = "zonesEnabled"
        (zonesEnabledChange) = "onZonesEnabledChange($event)"
        [classicLayerSelected]="classicLayerSelected"
        [satelliteLayerSelected]="satelliteLayerSelected"
    ></app-map-options-menu>
    </div>
</div>