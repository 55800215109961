import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, throwError } from 'rxjs';
import { ReverseGeocoderResponse } from './models/reverse-geocoder-response.model';
@Injectable({
  providedIn: 'root'
})
export class ReverseGeocoderService {
  url = `${environment.apiBase.url}/geocoder/api/v1/reverse`;
  constructor(private http: HttpClient) {}

  getReverseGeocode(latitude: number, longitude: number): Observable<ReverseGeocoderResponse> {
    const params = { latitude, longitude, 'ngsw-bypass': true };
    const httpParams = new HttpParams({ fromObject: params as any });
    return this.http.get(this.url, { params: httpParams }) as Observable<ReverseGeocoderResponse>;
  }

  getBulkReverseGeocode(latLongs: { latitude: number; longitude: number }[]) {
    const formData = new FormData();
    formData.set('points', JSON.stringify(latLongs));
    return this.http.post(this.url, formData) as Observable<ReverseGeocoderResponse>;
  }
}
