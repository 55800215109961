<div class="navigation-container" fxLayout="row"
fxLayoutAlign="space-between center">
    <button 
        mat-button 
        class="close-btn" 
        (click)="handleCloseButtonClick()"
        data-cy="nav-container-close-btn">
        <mat-icon>close</mat-icon>
        <span>{{ translateService.get(translations.appSelectedAssetNavigation.close) | async | translate }}</span>
    </button>
    <app-view-switcher
        (subContextChange)="handleSubContextChange($event)"
        [subContextView]="subContextView"
    ></app-view-switcher>
</div>