import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFilters from '../models/filters.model';
import { filtersFeatureKey } from '@app/store/filters/reducers/filters.reducer';

export const selectFiltersState = createFeatureSelector<fromFilters.FiltersState>(filtersFeatureKey);

export const selectAllFilters = createSelector(selectFiltersState, state => state);

export const selectDefaultFilter = createSelector(selectFiltersState, state => state.defaultFilter);

export const selectCurrentFilter = createSelector(selectFiltersState, state => state.filter);

export const selectCompany = createSelector(selectFiltersState, state => {
  return state.filter.company;
});

export const selectDivisions = createSelector(selectFiltersState, state => {
  return state.filter.divisions;
});

export const selectLocations = createSelector(selectFiltersState, state => {
  return state.filter.locations;
});
