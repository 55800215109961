import { DomUtil } from 'leaflet';

const followingClass = 'following';
const notFollowingClass = 'not-following';
const hiddenClass = 'hidden';

const imgSrc = '../../../../../../../assets/images/check_box_checked.svg';

(function () {
  function defineLeafletFollowAsset(L) {
    L.Control.FollowAsset = L.Control.extend({
      followDisplayText: 'Follow Asset',
      followingDisplayText: 'Following Asset',
      onAdd(map) {
        const container = L.DomUtil.create('div', `leaflet-control-follow-asset ${hiddenClass}`);
        const img = L.DomUtil.create('img', 'follow-asset-control-img', container);
        const p = L.DomUtil.create('p', 'follow-asset-control-text', container);
        this.pElement = p;
        img.src = imgSrc;

        container.addEventListener('click', () => {
          this.fire('click');
        });

        container.addEventListener('touchstart', () => {
          this.fire('touchstart');
        });

        return container;
      }
    });

    L.extend(L.Control.FollowAsset.prototype, L.Evented.prototype);

    L.Control.FollowAsset.prototype.setDisplayFollowing = function () {
      const container = this.getContainer();
      DomUtil.removeClass(container, hiddenClass);
      DomUtil.removeClass(container, notFollowingClass);
      DomUtil.addClass(container, followingClass);
      this.pElement.innerHTML = this.followingDisplayText;
    };

    L.Control.FollowAsset.prototype.setDisplayNotFollowing = function () {
      const container = this.getContainer();
      DomUtil.removeClass(container, hiddenClass);
      DomUtil.removeClass(container, followingClass);
      DomUtil.addClass(container, notFollowingClass);
      this.pElement.innerHTML = this.followDisplayText;
    };

    L.Control.FollowAsset.prototype.setDisplayHidden = function () {
      const container = this.getContainer();
      DomUtil.removeClass(container, followingClass);
      DomUtil.removeClass(container, notFollowingClass);
      DomUtil.addClass(container, hiddenClass);
      this.pElement.innerHTML = '';
    };

    L.control.followAsset = function (opts, followText, followingText) {
      const followControl = new L.Control.FollowAsset(opts);
      followControl.followDisplayText = followText;
      followControl.followingDisplayText = followingText;
      return followControl;
    };
  }

  if (typeof define === 'function' && define.amd) {
    // Try to add leaflet.loading to Leaflet using AMD
    define(['leaflet'], function (L) {
      defineLeafletFollowAsset(L);
    });
  } else {
    // Else use the global L
    defineLeafletFollowAsset(L);
  }
})();
