<app-progress-spinner
    class="main-app-spinner"
    [class.mobile]="(locationFacade.getIsMobile() | async) === true"
    *ngIf="currentView !== views.LIST && loading"
>
</app-progress-spinner>

<app-rotate-message *ngIf="showRotationMessage"></app-rotate-message>

<!-- To prevent bugs, we must be careful with how we conditionally render the leaflet map and it's parent containers -->
<!-- We use style.visibility on the parent container, because if we were to use fxShow or display=none, the map      -->
<!-- instance cannot correctly calculate its dimensions after an orientation change.                                 -->
<!-- If we were to use use ngIf, the map would be completely removed from the dom, which will cause leaflet errors   -->

<div class="mobile-layout-container" [style.visible]="showRotationMessage ? 'hidden' : 'visible'" fxFill fxLayout="column">
  <div fxLayout="column" class="mobile-header-container">
    <app-map-header 
      class="map-header-mobile" 
      (filterClick)="handleOpenFilterDialog.emit()"
      [isAssetSelected]="(locationFacade.getIsAssetSelected() | async)"
    ></app-map-header>

    <app-filter-chip-list class="filter-chip-mobile-container" [chipValues]="getChipValues()"
      (chipRemoved)="handleChipRemoved($event)" (moreFiltersClick)="handleFilter()"
      (filterChipsHeight)="handleChipsHeight($event)" fxLayout="column"></app-filter-chip-list>
  </div>
  <app-mobile-container
    [isMapShown]="!showRotationMessage" 
    [text]="text"
    (toggleMobileViewContext)="toggleMobileViewContext()"
  >  
    <app-asset-list 
      *ngIf="currentView===views.LIST && !showRotationMessage"
      [filtersState]="locationFacade.getAllFilters()"
      (listUpdated)="handleAssetListUpdate()"
      class="content-pane">
    </app-asset-list>
  </app-mobile-container>
</div>