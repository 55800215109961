// Angular
import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges, OnInit } from '@angular/core';

import { AutocompleteFacade } from '@app/modules/location/facade/autocomplete.facade';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { DetailsSubcontext } from '@app/store/layout/reducers/layout.reducer';

export enum OptionIcons {
  ASSET = 'local_shipping',
  DRIVER = 'person',
  SEARCH = 'search'
}

@Component({
  selector: 'app-map-header',
  templateUrl: './map-header.component.html',
  styleUrls: ['./map-header.component.scss']
})
export class MapHeaderComponent implements OnChanges {
  constructor(private autocompleteFacade: AutocompleteFacade) {}

  @Input() isAssetSelected: boolean;
  @Input() asset: ViewableAsset;
  @Input() subContextView: DetailsSubcontext;
  @Output() filterClick = new EventEmitter<boolean>();
  @Output() subContextChange = new EventEmitter<DetailsSubcontext>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.asset?.currentValue) {
      this.asset = changes.asset.currentValue;
    }
  }

  handleFilter() {
    this.filterClick.emit(true);
  }

  closeButtonClicked() {
    this.autocompleteFacade.clearAutocompleteResults();
  }

  handleSubContextChange(event) {
    this.subContextChange.emit(event);
  }
}
