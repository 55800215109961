export enum LeafletMarkerAsset {
  PATH_START = 'assets/leaflet/Origin.png',
  PATH_END = 'assets/leaflet/Destination.png',
  POWERED_OFF = 'assets/leaflet/PoweredOff.png',
  IDLING = 'assets/leaflet/Idling.png',
  IN_MOTION = 'assets/leaflet/InMotion-N.png',
  IN_MOTION_VDO = 'assets/leaflet/InMotion-VDO-N.png',
  IN_MOTION_ROOT = 'assets/leaflet/InMotion-',
  IN_MOTION_ROOT_VDO = 'assets/leaflet/InMotion-VDO-',
  GREY_DOT = 'assets/leaflet/GreyDot.svg',
  IO_1 = 'assets/leaflet/IO-1.png',
  IO_2 = 'assets/leaflet/IO-2.png',
  IO_3 = 'assets/leaflet/IO-3.png',
  IO_4 = 'assets/leaflet/IO-4.png',
  IO_5 = 'assets/leaflet/IO-5.png',
  CONNECTION_ERROR = 'assets/leaflet/ConnectionError.png'
}
