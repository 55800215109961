import { createReducer } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

export const routerFeatureKey = 'router';

export interface RouterState {
  router: fromRouter.RouterReducerState<any>;
}

export const initialState = <RouterState>{};

export const reducer = createReducer(initialState);
