import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Device, Orientation } from '@app/modules/platform/store/reducers/platform.reducer';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Platform as CdkPlatform } from '@angular/cdk/platform';
import * as PlatformActions from '../actions/platform.actions';

@Injectable()
export class PlatformEffects {
  constructor(
    private actions$: Actions,
    private breakpointObserver: BreakpointObserver,
    private platform: CdkPlatform
  ) {}

  landscapeBreakpoint$ = this.breakpointObserver.observe([
    Breakpoints.HandsetLandscape,
    Breakpoints.TabletLandscape,
    Breakpoints.WebLandscape
  ]);

  readonly maxDiff = 200;

  setOrientation$ = createEffect(() =>
    this.landscapeBreakpoint$.pipe(
      map(_ => {
        const width = document.defaultView.innerWidth;
        const height = document.defaultView.innerHeight;
        let orientation = Orientation.PORTRAIT;
        if (width - height > this.maxDiff) {
          orientation = Orientation.LANDSCAPE;
        }
        return PlatformActions.setOrientation({ orientation });
      })
    )
  );

  setDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      map(() => {
        if (this.platform.IOS) {
          return Device.IOS;
        }
        if (this.platform.ANDROID) {
          return Device.ANDROID;
        }
        return Device.WEB;
      }),
      map(device => {
        return PlatformActions.setDevice({ device });
      })
    )
  );
}
