import { createAction, props, union } from '@ngrx/store';
import { CompanyDataSearchParams, Division } from '@app/core/models/zonar-accounts.model';
import { DropdownOption } from '@zonar-ui/searchable-dropdown';

export const enum SearchFiltersActionTypes {
  GET_COMPANIES = '[Filters] Get Companies',
  SAVE_COMPANIES = '[Filters] Save Companies',
  GET_COMPANIES_SUCCESS = '[Filters] Get Companies Success',
  GET_COMPANIES_FAILURE = '[Filters] Get Companies Failure',
  SET_COMPANIES_PARAMS = '[Filters] Set Params',
  RESET_COMPANIES_PARAMS = '[Filters] Reset Params',

  GET_DIVISIONS = '[Filters] Get Divisions',
  GET_DIVISIONS_SUCCESS = '[Filters] Get Divisions Success',
  GET_DIVISIONS_FAILURE = '[Filters] Get Divisions Failure',
  SAVE_DIVISIONS = '[Filters] Save Divisions',
  CLEAR_DIVISIONS = '[Filters] Clear Divisions',

  GET_LOCATIONS = '[Filters] Get Locations',
  GET_LOCATIONS_SUCCESS = '[Filters] Get Locations Success',
  GET_LOCATIONS_FAILURE = '[Filters] Get Locations Failure',
  SAVE_LOCATIONS = '[Filters] Save Locations',
  CLEAR_LOCATIONS = '[Filters] Clear Locations',

  RESET_SEARCH_FILTERS_STATE = '[Filters] Reset Search Filters State',
  RESET_SEARCH_FILTERS_DIVISION_STATE = '[Filters] Reset Division State',

  GET_DIVISIONS_FOR_LIMITED_USER = '[Filters] Get Company and Divisions for Limited User',
  GET_LOCATIONS_FOR_LIMITED_USER = '[Filters] Get Locations for Limited User'
}

export const getCompanies = createAction(
  SearchFiltersActionTypes.GET_COMPANIES,
  props<{ params?: CompanyDataSearchParams }>()
);

export const saveCompanies = createAction(
  SearchFiltersActionTypes.SAVE_COMPANIES,
  props<{ companies: DropdownOption[] }>()
);

export const getCompaniesSuccess = createAction(SearchFiltersActionTypes.GET_COMPANIES_SUCCESS);

export const getCompaniesFailure = createAction(
  SearchFiltersActionTypes.GET_COMPANIES_FAILURE,
  props<{ error: any }>()
);

export const getSearchFiltersDivisions = createAction(
  SearchFiltersActionTypes.GET_DIVISIONS,
  props<{ companyId: string; params?: CompanyDataSearchParams }>()
);

export const getSearchFiltersDivisionsSuccess = createAction(
  SearchFiltersActionTypes.GET_DIVISIONS_SUCCESS,
  props<{ divisions: Division[] }>()
);

export const getSearchFiltersDivisionsFailure = createAction(
  SearchFiltersActionTypes.GET_DIVISIONS_FAILURE,
  props<{ error: any }>()
);

export const clearSearchFiltersDivisions = createAction(SearchFiltersActionTypes.CLEAR_DIVISIONS);

export const getSearchFiltersLocations = createAction(
  SearchFiltersActionTypes.GET_LOCATIONS,
  props<{
    companyId: string;
    legacyAccountCode: string;
    params?: CompanyDataSearchParams;
  }>()
);

export const getSearchFiltersLocationsSuccess = createAction(SearchFiltersActionTypes.GET_LOCATIONS_SUCCESS);

export const getSearchFiltersLocationsFailure = createAction(
  SearchFiltersActionTypes.GET_LOCATIONS_FAILURE,
  props<{ error: any }>()
);

export const saveSearchFiltersLocations = createAction(
  SearchFiltersActionTypes.SAVE_LOCATIONS,
  props<{ locations: Division[] }>()
);

export const clearSearchFiltersLocations = createAction(SearchFiltersActionTypes.CLEAR_LOCATIONS);

export const resetSearchFiltersState = createAction(SearchFiltersActionTypes.RESET_SEARCH_FILTERS_STATE);

export const resetSearchFiltersDivisionsState = createAction(
  SearchFiltersActionTypes.RESET_SEARCH_FILTERS_DIVISION_STATE
);

export const setCompaniesParams = createAction(
  SearchFiltersActionTypes.SET_COMPANIES_PARAMS,
  props<{ params: CompanyDataSearchParams }>()
);
export const saveDivisions = createAction(SearchFiltersActionTypes.SAVE_DIVISIONS, props<{ divisions: string[] }>());

export const resetCompaniesParams = createAction(SearchFiltersActionTypes.RESET_COMPANIES_PARAMS);

export const setLimitedUserCompanyAndDivisions = createAction(
  SearchFiltersActionTypes.GET_DIVISIONS_FOR_LIMITED_USER,
  props<{ companyId: string; divisions: string[] }>()
);

export const setLocationsForLimitedUser = createAction(
  SearchFiltersActionTypes.GET_LOCATIONS_FOR_LIMITED_USER,
  props<{ locations: string[] }>()
);

const allActions = union({
  getCompanies,
  saveCompanies,
  getCompaniesSuccess,
  getCompaniesFailure,
  setCompaniesParams,
  resetCompaniesParams,
  getSearchFiltersDivisions,
  getSearchFiltersDivisionsSuccess,
  getSearchFiltersDivisionsFailure,
  getSearchFiltersLocations,
  getSearchFiltersLocationsSuccess,
  getSearchFiltersLocationsFailure,
  saveSearchFiltersLocations,
  clearSearchFiltersLocations,
  resetSearchFiltersState,
  resetSearchFiltersDivisionsState,
  setLimitedUserCompanyAndDivisions,
  setLocationsForLimitedUser
});

export type SearchFiltersActionsUnion = typeof allActions;
