import { Injectable, NgZone } from '@angular/core';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { NotificationService } from './notification.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServerOfflineService {
  onlineStatus$ = new BehaviorSubject<boolean>(navigator.onLine);

  constructor(private notifications: NotificationService, private facade: LocationFacade) {}

  showNotificationForOfflineStatus() {
    window.addEventListener('offline', () => {
      this.notifications.openPoorNetworkConnectionNotification();
      this.updateOnlineStatus(false);
      this.facade.stopAssetsPolling();
    });
  }

  refreshAppForOnlineStatus() {
    window.addEventListener('online', () => {
      this.notifications.dismiss();
      this.updateOnlineStatus(true);
      this.facade.startAssetsPolling();
    });
  }

  private updateOnlineStatus(status) {
    this.onlineStatus$.next(status);
  }
}
