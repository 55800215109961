import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';

import { DesktopModule } from '@app/modules/desktop/desktop.module';
import { MobileModule } from '@app/modules/mobile/mobile.module';
import { SharedModule } from '@app/modules/shared/shared.module';
import { MarkerIconService } from '@app/modules/location/services/marker-icon.service';

// routes
import { LocationRoutingModule } from '@app/modules/location/location.routes';

// interceptor
import { ServerOfflineRetryNotification } from '@app/interceptors/server-offline-retry-notification.interceptor';

// pages
import { LocationsComponent } from '@app/modules/location/containers/locations/locations.component';

// pattern library components
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { ZonarUiPermissionsModule } from '@zonar-ui/auth';
import { ZonarUiProgressSpinnerModule } from '@zonar-ui/progress-spinner';

// components
import { DesktopLocationContainerComponent } from './containers/desktop-location-container/desktop-location-container.component';
import { MobileLocationContainerComponent } from './containers/mobile-location-container/mobile-location-container.component';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environments/environment';
import * as fromLayout from '@app/store/layout/reducers/layout.reducer';
import { OverlayService } from '@app/modules/location/services/overlay.service';
import { PathLayerConfigService } from './services/leaflet-layer-configs/path-layer-config.service';

@NgModule({
  declarations: [LocationsComponent, DesktopLocationContainerComponent, MobileLocationContainerComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    LocationRoutingModule,
    MobileModule,
    DesktopModule,
    ZonarUIMaterialModule,
    ZonarUiPermissionsModule,
    ZonarUiProgressSpinnerModule,
    SharedModule,
    StoreModule.forFeature(fromLayout.layoutFeatureKey, fromLayout.reducer),
    StoreDevtoolsModule.instrument({
      logOnly: !environment.devTools
    })
  ],
  providers: [
    MarkerIconService,
    OverlayService,
    PathLayerConfigService,
    { provide: HTTP_INTERCEPTORS, useClass: ServerOfflineRetryNotification, multi: true }
  ],
  exports: [LocationsComponent, DesktopLocationContainerComponent, MobileLocationContainerComponent]
})
export class LocationModule {}
