import { ErrorHandler, NgModule } from '@angular/core';
import { GlobalErrorHandler } from '@app/modules/error-handler/global-error-handler';

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ]
})
export class ErrorHandlerModule {}
