import { createAction, props } from '@ngrx/store';
import { Device, Orientation } from '@app/modules/platform/store/reducers/platform.reducer';

export const enum PlatformActionTypes {
  SET_DEVICE = '[Layout] Set Device',
  SET_ORIENTATION = '[Layout] Set Orientation'
}

export const setDevice = createAction(PlatformActionTypes.SET_DEVICE, props<{ device: Device }>());

export const setOrientation = createAction(PlatformActionTypes.SET_ORIENTATION, props<{ orientation: Orientation }>());
