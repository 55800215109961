import { createReducer, on } from '@ngrx/store';
import * as PlatformActions from '@app/modules/platform/store/actions/platform.actions';

export const platformFeatureKey = 'platform';

export enum Device {
  WEB = 'web',
  IOS = 'ios',
  ANDROID = 'android'
}

export enum Orientation {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape'
}

export interface PlatformState {
  device: Device;
  orientation: Orientation;
}

export const initialState: PlatformState = {
  device: null,
  orientation: null
};

export const reducer = createReducer(
  initialState,

  on(PlatformActions.setDevice, (state, { device }) => {
    return { ...state, device };
  }),

  on(PlatformActions.setOrientation, (state, { orientation }) => {
    return { ...state, orientation };
  })
);
