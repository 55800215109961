import { createAction, props } from '@ngrx/store';
import { IDivisionMap } from '@zonar-ui/auth/lib/models/company.model';

export const enum PermissionsActionTypes {
  LOAD_PERMISSIONS = '[Perms] Load Permissions',
  SAVE_PERMISSIONS = '[Perms] Save Permissions',
  SAVE_ENTITIES = '[Perms] Save Entities'
}

export const loadPermissions = createAction(PermissionsActionTypes.LOAD_PERMISSIONS);
export const savePermissions = createAction(
  PermissionsActionTypes.SAVE_PERMISSIONS,
  props<{
    readCompanies: boolean;
    readCompany: boolean;
    isZonarUser: boolean;
    companies: any[];
    divisions: any[];
    divisionMap: IDivisionMap;
    locations: any[];
    requestStartTime?: number;
  }>()
);
export const saveEntities = createAction(
  PermissionsActionTypes.SAVE_ENTITIES,
  props<{ companies: string[]; divisions: string[] }>()
);
