import { Injectable } from '@angular/core';
import { HereTrafficService } from '@app/modules/location/services/here-traffic.service';
import { switchMap } from 'rxjs/operators';
import { filter, Observable, of } from 'rxjs';
import { Icon, LatLngBounds, Polyline } from 'leaflet';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';

declare var L; // eslint-disable-line no-var

export const incidentGroupConfig = {
  // Lower zoom numbers are less zoomed-in. This must be 11 as the incident API can only return max height/width of 1 degree
  minZoom: 11,
  maxZoom: 20
};

// to display in the leaflet icon, we must use the unicode for icons with a space in the name (eg: 'car crash')
export enum IncidentIcons {
  accident = '&#xebf2;', // ('car crash') indicates that there has been a collision
  construction = 'construction', // indicates that building or roadworks are taking place
  congestion = 'traffic', // indicates that there has been a build-up of vehicles
  disabledVehicle = '&#xebf1;', // ('minor crash') indicates that a vehicle is unable to move and is obstructing the road
  massTransit = '&#xe98f;', // ('bus alert') indicates that an event is present related to public transit
  plannedEvent = 'campaign', // indicates that an organised event is taking place causing disruption
  roadHazard = '&#xebfc;', // ('remove road') indicates that there are dangerous objects on the surface of the road
  roadClosure = '&#xebfc;', // ('remove road') indicates that the road has been closed, for example, by the police
  weather = '&#xf60b;', // ('weather mix') indicates that weather conditions are causing disruptions
  laneRestriction = '&#xebfc;', // ('remove road') indicates that some of the lanes have access restrictions
  other = 'warning' // indicates that an incident not explainable with the types above has occurred
}

@Injectable({
  providedIn: 'root'
})
export class IncidentsService {
  private noPreviewText: string;

  constructor(
    private hereTraffic: HereTrafficService,
    private translateService: TranslateService,
    private translations: Translations
  ) {
    this.translations.translationsLoadState
      .pipe(filter(loadstate => loadstate === ResourceLoadState.LOAD_SUCCESSFUL))
      .subscribe(() => {
        this.noPreviewText = this.translateService.instant(this.translations.map.incidentsService.noPreview);
      });
  }

  getDivIcon(matIconText: string, criticality: string): Icon {
    return L.divIcon({
      className: 'incident-icon-div',
      html: `<div class='incident-icon-inner-div incident-${criticality}'><i class='material-icons'>${matIconText}</i></div>`
    });
  }

  getTooltipContent(item: any, matIconText: string): string {
    let details = item.incidentDetails.description.value;
    let summary = item.incidentDetails.summary.value;
    // if the details is just a repitition of the summary, delete it
    if (details == summary) {
      details = '';
    }
    // even if there are details, summary is often repeated at the end of the description - if so, remove it and the preceding ' - '
    else if (details.indexOf(summary)) {
      details = details.substring(0, details.indexOf(summary) - 3);
    }
    return `<p class='incident-summary'><i class='material-icons'>${matIconText}</i> - ${summary}</p><p class='incident-details'>${details}</p>`;
  }

  getIncidentPolyline(item: any): Polyline {
    // total length of <~220 is too short to render, so return w/o polyline in this case.
    // If the getIncidentMarkers sees this undefined, it will add a 'no preview' <p> to the tooltip
    if (item.location.length <= 220) return;
    const geometry = item.location.shape.links.map(l =>
      l.points.reduce((a, c, _) => {
        return a.concat([[c.lat, c.lng]]);
      }, [])
    );
    // Known bug / future TODO: there are times when HERE misreports a length >220, but the points are actually too close together for Leaflet to render.
    // This will result in a popup without the 'no preview' message, but for which no polyline is visible.
    // Have not been able to figure out a good test / fix for this.
    return new L.Polyline(geometry, {
      className: `incident-polyline incident-polyline-${item.incidentDetails.criticality}`
    });
  }

  getIncidentMarkers(bounds: LatLngBounds): Observable<Array<object>> {
    return this.hereTraffic.getIncidents(bounds).pipe(
      switchMap(incidents => {
        const markers = {};
        const polylines = {};
        if (incidents && incidents.results.length) {
          const items = incidents.results;
          items.map(item => {
            // originalId is recorded even on subsequent updates when the id will change
            if (!item.incidentDetails.originalId) {
              return;
            }

            const id = item.incidentDetails.originalId;
            // for now, we are defaulting to 0th point in 0th link for the marker position
            const point = item.location.shape.links[0].points[0];
            const startLatLng = new L.LatLng(point.lat, point.lng);

            const matIconText = IncidentIcons[item.incidentDetails.type];
            let ttContent = this.getTooltipContent(item, matIconText);
            let popupClass = 'incident-popup';
            const polyline = this.getIncidentPolyline(item);
            if (polyline == undefined)
              ttContent = ttContent.concat(`<p class='incident-no-polyline'>${this.noPreviewText}</p>`);
            else {
              polylines[id] = polyline;
              const endLatLng = (polyline.getLatLngs()[0] as Array<any>).pop();
              // this logic will only work for N hemisphere - if we need to support S hemisphere we need to add a case for -lat values
              const endsNorth = endLatLng.lat > startLatLng.lat;
              popupClass += endsNorth ? ' incident-popup-bottom' : ' incident-popup-top';
            }

            const mkr = L.marker(startLatLng, { icon: this.getDivIcon(matIconText, item.incidentDetails.criticality) });
            mkr.bindPopup(ttContent, { className: popupClass }).setZIndexOffset(-500);
            markers[id] = mkr;
          });
        }
        return of([markers, polylines]);
      })
    );
  }
}
