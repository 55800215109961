/// <reference types='leaflet.locatecontrol' />
/// <reference types='@runette/leaflet-fullscreen' />
/// <reference types='@types/leaflet-polylinedecorator' />

import { Injectable, OnDestroy } from '@angular/core';
import { GeoJsonGenerationService } from '@app/modules/location/services/geo-json-generation.service';
import {
  FeatureGroup,
  featureGroup,
  FitBoundsOptions,
  GeoJSON,
  latLng,
  LatLng,
  latLngBounds,
  LeafletMouseEvent,
  Map,
  marker,
  Marker,
  MarkerClusterGroup,
  Polyline
} from 'leaflet';
import { LeafletConfigService } from '@app/modules/location/services/leaflet-layer-configs/leaflet-config.service';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { MarkerIconService } from '@app/modules/location/services/marker-icon.service';
import { Subject } from 'rxjs';
import { DetailsSubcontext } from '@app/store/layout/reducers/layout.reducer';
import { PlatformFacade } from '@app/modules/platform/facade/platform.facade';

import { selectViewContext, selectViewSubContext } from '@app/store/layout/selectors/layout.selectors';

import 'leaflet.markercluster';
import 'leaflet.markercluster.freezable';
import 'leaflet-polylinedecorator';

import { debounceTime, distinctUntilKeyChanged, filter, first, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { incidentGroupConfig, IncidentsService } from '@app/modules/location/services/incidents.service';
import { ClusterLayerConfigService } from '@app/modules/location/services/leaflet-layer-configs/cluster-layer-config.service';
import {
  getHeading,
  interpolateTime,
  reverseLongLats,
  validGpsData,
  ZoomScales
} from '@app/modules/shared/utilities/utilities';
import { selectRecentPathLoadState, selectSelectedPathSegment } from '@app/store/asset/selectors/assets.selectors';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { PathFeature, PathProperties, PathStatusTypes } from '@app/modules/location/models/path-api.model';
import { PathLayerConfigService } from '@app/modules/location/services/leaflet-layer-configs/path-layer-config.service';
import { clearRecentPathSelectedSegment } from '@app/store/asset/actions/assets.actions';
import { TripFeature } from '@app/modules/location/models/trip.model';
import { Position } from '../models/auto-suggestion.model';
import { HttpCancelService } from '@app/services/http-cancel.service';
import { DataDogService } from '@app/services/data-dog.service';
import { SettingsApiService } from '@app/services/settings-api.service';
import { MapSettings } from '../models/settings.model';

import { Zone } from '@app/modules/zones/zones.model';
import { LeafletZoneService } from '@app/modules/zones/services/leaflet-zone.service';
import { objectShallowEquality } from '@app/modules/location-client/utilities';

declare let L;
interface TimeLineControl {
  setDisplayVisible(): any;
  setDisplayHidden(): any;
}
interface BindPopUp {
  marker: Marker;
  pathFeature: PathFeature;
}

@Injectable({
  providedIn: 'root'
})
export class LeafletService implements OnDestroy {
  constructor(
    private leafletConfigService: LeafletConfigService,
    private markerIconService: MarkerIconService,
    private platformFacade: PlatformFacade,
    private store: Store<AppState>,
    private incidentsService: IncidentsService,
    private geoJson: GeoJsonGenerationService,
    private clusterLayerConfigService: ClusterLayerConfigService,
    private pathLayerConfigService: PathLayerConfigService,
    private httpCancelService: HttpCancelService,
    private dataDog: DataDogService,
    private settingsService: SettingsApiService,
    private leafletZoneService: LeafletZoneService
  ) {
    // this code adapted from https://stackoverflow.com/questions/48291870/how-to-add-custom-ui-to-leaflet-map
    // it adds additional leaflet control anchor points in addition to the standard bottom/top|right/left
    L.Map.include({
      _initControlPos: function () {
        const corners = (this._controlCorners = {}),
          l = 'leaflet-',
          container = (this._controlContainer = L.DomUtil.create('div', l + 'control-container', this._container));

        function createCorner(vSide, hSide) {
          const className = `${l}${vSide} ${l}${hSide}`;

          corners[`${vSide}${hSide}`] = L.DomUtil.create('div', className, container);
        }

        createCorner('top', 'left');
        createCorner('top', 'right');
        createCorner('bottom', 'left');
        createCorner('bottom', 'right');

        createCorner('top', 'center');
        createCorner('middle', 'center');
        createCorner('middle', 'left');
        createCorner('middle', 'right');
        createCorner('bottom', 'center');
      }
    });

    this.platformFacade
      .getIsMobile()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(isMobile => {
        this.isMobile = isMobile;
        this.fitBoundsOptions = isMobile
          ? leafletConfigService.mobileFitBoundsOptions
          : leafletConfigService.desktopFitBoundsOptions;
        this.fitClusterBoundsOptions = { ...this.fitBoundsOptions, maxZoom: 19 };
      });

    this.store
      .select(selectViewContext)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(view => {
        this.viewContext = view;
        if (this.viewContext === 'list' && this.map?.getZoom() > leafletConfigService.defaultBoundingZoom) {
          this.map.setZoom(leafletConfigService.defaultBoundingZoom);
        }
      });

    this.store
      .select(selectRecentPathLoadState)
      .pipe(withLatestFrom(this.store.select(selectViewSubContext)), takeUntil(this.onDestroy$))
      .subscribe(([loadState, viewSubContext]) => {
        if (loadState === ResourceLoadState.LOADING || viewSubContext === DetailsSubcontext.HISTORY) {
          this.configureHistoryViewMap();
        } else {
          this.restoreLiveMap();
        }

        // In the course of working ZTT-1200, this hack is needed in case user goes to asset/assetId/history directly so that they do not get their request canceled on history view init
        if (viewSubContext !== DetailsSubcontext.HISTORY) {
          this.cancelPendingRequest(viewSubContext);
        }
      });

    this.store
      .select(selectSelectedPathSegment)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(s => {
        if (s === null) {
          this.hideViewEntireTripControl();
        }
        this.selectedSegment = s;
        this.showRecentPathLayer(this.currentPath);
      });

    this.store
      .select(selectViewSubContext)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(sc => (this.viewSubContext = sc));

    this.pathLayerConfigService.onMouseOver$.pipe(takeUntil(this.onDestroy$)).subscribe((feature: PathFeature) => {
      this.onMouseover$.next(feature);
    });

    this.onBindPopup();
  }

  arrowFrequency = 0.08; // show directional arrow every features.length * arrowFrequency points
  arrowIconClassName = 'arrow-icon';
  arrowIconMarkers = [];
  assets: ViewableAsset[] = [];
  assetsHolderWhileHistory: ViewableAsset[] = [];
  clusterLayer: MarkerClusterGroup;
  currentPath: FeatureGroup<any> & { features?: TripFeature[] };
  fitBoundsOptions: FitBoundsOptions;
  fitClusterBoundsOptions: FitBoundsOptions;
  followAssetControl: any;
  followAssetEnabled = false;
  followingAssetZoom = 17;
  highlightAsset: ViewableAsset;
  highlightMarker: Marker;
  incidentGroup: FeatureGroup = featureGroup([]);
  incidentIds: Array<string> = [];
  ioFeatureGroups: FeatureGroup[] = [];
  isMobile: boolean;
  locationControlObserver: MutationObserver;
  map: Map;
  nearbyAssets: Array<ViewableAsset> = null;
  pathLayer: GeoJSON;
  timelineControl: TimeLineControl;
  viewEntireTripControl: any;
  tripSegments: Array<TripFeature[]> = [[]];
  selectedAsset: ViewableAsset = null;
  selectedAssetMarker: Marker;
  selectedAssetWhileHistory: ViewableAsset;
  selectedLocationMarker: Marker;
  selectedSegment: number = null;
  viewContext: string;
  viewSubContext: DetailsSubcontext;
  private onDestroy$ = new Subject();
  timeLinePoints$ = new Subject();
  onMouseover$ = new Subject();
  onBindPopup$ = new Subject<BindPopUp>();
  pathMarker: Marker;
  currentFeatures: TripFeature[];
  clusterGroupUpdateCount = 0;

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    this.locationControlObserver?.disconnect();
  }

  configureHistoryViewMap() {
    if (this.viewSubContext === DetailsSubcontext.HISTORY) {
      this.assetsHolderWhileHistory = this.assets;
      this.selectedAssetWhileHistory = this.selectedAsset;
      this.clearAssetMarker();
      this.refreshMap([]);
      this.followAssetControl?.setDisplayHidden();
      this.hideViewEntireTripControl();
      this.tripSegments = [];
      this.currentPath = null;
    }
  }

  restoreLiveMap() {
    this.assets = this.assetsHolderWhileHistory.length ? this.assetsHolderWhileHistory : this.assets;
    this.setSelectedAsset(this.selectedAssetWhileHistory);
    this.updateFollowAsset();
    this.hideViewEntireTripControl();
    this.hideTimelineControl();
    this.showClusterLayer();
  }

  clearAssetMarker(): void {
    this.clearSelectedAsset();
    this.clearHighlightMarker();
  }

  updateFollowAsset(): void {
    if (this.viewContext != 'details') {
      this.followAssetControl?.setDisplayHidden();
      return;
    }
    if (!this.followAssetEnabled) {
      this.followAssetControl?.setDisplayNotFollowing();
    } else {
      this.followAssetControl?.setDisplayFollowing();
    }
  }

  showViewEntireTripControl(): void {
    if (this.isMobile) this.viewEntireTripControl?.setDisplayVisible();
  }

  hideViewEntireTripControl(): void {
    this.viewEntireTripControl?.setDisplayHidden();
  }

  showTimelineControl(): void {
    this.timelineControl?.setDisplayVisible();
  }

  hideTimelineControl(): void {
    this.timelineControl?.setDisplayHidden();
  }

  receiveMap(map: Map): void {
    // save reference to the map
    this.map = map;
    // assign event listeners
    this.map.on('moveend', this.handleMoveEnd);

    // add follow-asset control to map
    this.createFollowAssetControl(map);

    // add view entire trip control to map
    this.createViewEntireTripControl(map);

    // add timeline control to map
    this.timelineControl = this.createTimelineControl(map);

    // add cluster layer to map
    this.clusterLayer = this.createClusterGroup();
    this.clusterLayer.addTo(map);

    // add incident feature group to map
    this.incidentGroup.addTo(map);

    this.pathLayer = new GeoJSON<any>();

    // immediately load the map bounds into state
    this.handleMoveEnd();
  }

  createFollowAssetControl(map) {
    this.leafletConfigService.getTranslatedControlOptions$('followAsset').subscribe(translated => {
      this.followAssetControl = L.control
        .followAsset({ position: 'bottomright' }, translated.followText, translated.followingText)
        .addTo(map)
        .on('click', () => {
          this.handleFollowAssetControlClick();
        });
    });
  }

  createViewEntireTripControl(map) {
    this.leafletConfigService.getTranslatedControlOptions$('viewEntireTrip').subscribe(translated => {
      this.viewEntireTripControl = L.control
        .viewEntireTrip({ position: 'bottomcenter' }, translated)
        .addTo(map)
        .on('click', () => this.handleViewEntireTripControlClick());
    });
  }

  createTimelineControl(map) {
    return L.control.timelineControl({ position: 'bottomcenter' }).addTo(map);
  }

  refreshMap(assets: ViewableAsset[], nearbyAssets: ViewableAsset[] = [], selectedAsset?: ViewableAsset): void {
    if (!this.map) {
      return;
    }
    let mapAssets;
    if (nearbyAssets?.length) {
      // @TODO: the following eight lines add all other assets to the map along with nearby assets.
      // This "feature" was turned off pending user feedback. Turn in back on (if needed) by uncommenting
      // the code below and removing the mapAssets = nearbyAssets; line
      // const m = new window.Map();
      // nearbyAssets.forEach(a => m.set(a.assetId, a));
      // assets.forEach(a => {
      //   if (!m.has(a.assetId)) {
      //     m.set(a.assetId, a);
      //   }
      // });
      // mapAssets = [...m.values()];
      const filteredNearbyAssets = nearbyAssets.filter(asset => asset?.latitude && asset?.longitude);
      mapAssets = selectedAsset ? [...filteredNearbyAssets, selectedAsset] : [...filteredNearbyAssets];
      this.nearbyAssets = nearbyAssets;
    } else {
      mapAssets = assets.filter(asset => asset?.latitude && asset?.longitude);
      this.nearbyAssets = null;
    }
    this.assets = assets.filter(asset => asset?.latitude && asset?.longitude);
    // creating a geoJSON layer for the maps layer, this explicitly excludes highlight and selected assets.
    const geoJsonAssets = [];
    for (let i = 0; i < mapAssets?.length; i++) {
      if (
        mapAssets[i].assetId !== this.selectedAsset?.assetId &&
        mapAssets[i].assetId !== this.highlightAsset?.assetId
      ) {
        const viewableAsset = { ...(mapAssets[i] as ViewableAsset) };
        viewableAsset.positionMultiplier = this.getPositionMultiplier(this.map.getZoom());
        geoJsonAssets.push({ ...viewableAsset });
      }
    }
    const geoJSON = this.geoJson.assetsToGeoJson(geoJsonAssets);
    this.handleClusterUpdate(geoJSON);

    const updatedHighlightAsset = assets.find(asset => asset.assetId === this.highlightAsset?.assetId);
    if (updatedHighlightAsset) {
      this.setHighlightMarker(updatedHighlightAsset);
    }
    const updatedSelectedAsset = assets.find(asset => asset.assetId === this.selectedAsset?.assetId);
    if (updatedSelectedAsset) {
      if (!objectShallowEquality(this.selectedAsset, updatedSelectedAsset)) {
        this.selectedAsset = updatedSelectedAsset;
        this.updateSelectedAsset();
      }
    }
    this.flyToAutoFollowedAsset();
    this.hideViewEntireTripControl();
    this.hideTimelineControl();
  }

  flyToAutoFollowedAsset() {
    if (this.followAssetEnabled && this.selectedAsset) {
      this.map.flyTo(latLng(this.selectedAsset.latitude, this.selectedAsset.longitude), this.followingAssetZoom);
    }
  }

  // ========== Marker and Feature Group Creation Methods ==========

  createPin(asset: ViewableAsset, highlight?: boolean, positionMultiplier?: number): Marker {
    const pin = marker([asset.latitude, asset.longitude], {
      icon: this.markerIconService.fetchMarkerIcon(asset, highlight, asset.assetName, null, positionMultiplier),
      title: asset.assetName,
      zIndexOffset: 100,
      riseOnHover: false
    });
    return pin;
  }

  createClusterGroup(): MarkerClusterGroup {
    const clusterGroup = L.markerClusterGroup(this.clusterLayerConfigService.clusterGroupOptions);

    clusterGroup.on('clusterclick', event => {
      this.handleClusterClick(event);
    });
    clusterGroup.on('animationend', () => {
      this.handleAnimationEnd();
    });

    return clusterGroup;
  }

  handleClusterUpdate(e: Event) {
    // the first time handleClusterUpdate is called (ie the map markers have been created), fire off a timing event
    if (this.clusterGroupUpdateCount == 0) {
      this.dataDog.newRumTiming('map_markers_loaded');
    }

    this.clusterGroupUpdateCount++;
    this.clusterLayer.clearLayers().addLayer(new L.GeoJSON(e, this.clusterLayerConfigService.clusterGroupOptions));
  }

  // ========== Pin (Marker) & Cluster Event Handlers ==========
  handleClusterClick(event) {
    if (this.map.getZoom() === this.map.getMaxZoom()) {
      event.layer.spiderfy();
    } else {
      event.layer.zoomToBounds(this.fitClusterBoundsOptions);
    }
  }

  handleAnimationEnd() {
    // we must manually refresh the highlight asset after the cluster animation in order to:
    // 1. prevent the temporary marker from rendering on top of permanent marker after a cluster is clicked
    // 2. add a temporary marker if the user zooms out and causes a highlight marker to pop into a cluster
    if (!this.highlightAsset) {
      return;
    }

    this.setHighlightMarker({ ...this.highlightAsset });
  }

  onIncidentClick(marker: any, polyline: any) {
    polyline?.addTo(this.map);
    marker.setOpacity(0);
  }

  onIncidentUnclick(marker: any, polyline: any) {
    polyline?.removeFrom(this.map);
    marker.setOpacity(1);
  }

  // ========== Map Event Handlers ==========
  handleIncidentOverlayAdd() {
    const bounds = this.map.getBounds();
    const zoom = this.map.getZoom();
    if (incidentGroupConfig.minZoom <= zoom && zoom < incidentGroupConfig.maxZoom) {
      this.incidentsService.getIncidentMarkers(bounds).subscribe(([markers, polylines]) => {
        for (const [id, mkr] of Object.entries(markers)) {
          if (!this.incidentIds.includes(id)) {
            this.incidentIds.push(id);
            mkr.on({
              popupopen: () => this.onIncidentClick(mkr, polylines[id]),
              popupclose: () => this.onIncidentUnclick(mkr, polylines[id])
            });
            this.incidentGroup.addLayer(mkr);
          }
        }
      });
    }
  }

  handleIncidentOverlayRemove() {
    this.incidentIds = [];
    this.incidentGroup.clearLayers();
  }

  handleMoveEnd = () => {
    this.settingsService
      .getSetting(MapSettings.MAP_INCIDENTS)
      .pipe(first(), distinctUntilKeyChanged('value'))
      .subscribe(incidentsSettings => {
        const incidentSettingsOn = incidentsSettings.value === 'true' ? true : false;
        if (incidentSettingsOn) {
          if (incidentGroupConfig.minZoom <= this.map.getZoom()) {
            this.handleIncidentOverlayAdd();
          } else {
            this.incidentIds = [];
            this.incidentGroup.clearLayers();
          }
        } else {
          this.incidentIds = [];
          this.incidentGroup.clearLayers();
        }
      });
  };

  // =========== Map Zoom and Hover Behavior ==========
  zoomToAssets(assets: ViewableAsset[]) {
    if (!this.map || assets.length === 0 || this.followAssetEnabled) {
      return;
    }

    const coordinates = assets.map(a => latLng(a?.latitude, a?.longitude));
    const boundingBox = latLngBounds(coordinates);
    let boundingMaxZoom = Math.max(this.map.getZoom(), this.leafletConfigService.defaultBoundingZoom);

    // Dividing by 2 in mobile view allows a proper zoom out enough for user to see clusters and asset pins properly in mobile view. Otherwise, we'd be zoomed in too closely and see no clusters/pins.
    boundingMaxZoom = this.isMobile ? boundingMaxZoom / 2 : boundingMaxZoom;
    this.map.fitBounds(boundingBox, { ...this.fitBoundsOptions, maxZoom: boundingMaxZoom });
  }

  zoomToFeatures(features) {
    if (!this.map || features.length === 0) {
      return;
    }

    const coordinates = features.map(a => a.geometry.coordinates);
    const boundingBox = latLngBounds(coordinates);

    const boundingMaxZoom = Math.max(this.map.getZoom(), this.leafletConfigService.defaultBoundingZoom);
    this.map.fitBounds(boundingBox, { ...this.fitBoundsOptions, maxZoom: boundingMaxZoom });
  }

  zoomToZone(zone: Zone) {
    if (zone === null || zone === undefined || zone.geometry.features.length === 0) return;
    // feature should use GEOMETRY if available - otherwise default to 0th element which should be CENTROID
    let geometryFeature =
      zone.geometry.features.find(f => f.properties.name == 'GEOMETRY') || zone.geometry.features[0];

    // need to reverse coords, zones API gives lon/lat
    geometryFeature = reverseLongLats(geometryFeature);
    this.zoomToFeatures([geometryFeature]);
  }

  setHighlightMarker(asset: ViewableAsset): void {
    if (this.highlightAsset) {
      this.clearHighlightMarker();
    }

    // doing this ensures we no longer see "X miles away" subtitle on a newly selected asset pin in the case a user selects a new asset view from nearby assets
    if (validGpsData(asset)) {
      this.highlightAsset = {
        ...asset,
        markerSubtitle: ''
      };
      const positionMultiplier = this.getPositionMultiplier(this.map.getZoom());
      this.highlightMarker = this.createPin(asset, true, positionMultiplier).setZIndexOffset(10010).addTo(this.map);
    }
  }

  clearHighlightMarker(): void {
    // remove any temporary markers from the map
    if (this.highlightMarker && this.map.hasLayer(this.highlightMarker)) {
      this.highlightMarker?.removeFrom(this.map);
    }
    this.highlightAsset = null;
    this.highlightMarker = null;
    this.refreshMap(this.assets, this.nearbyAssets, this.selectedAsset);
  }

  clearSelectedAsset(): void {
    if (this.selectedAssetMarker && this.map.hasLayer(this.selectedAssetMarker)) {
      this.selectedAssetMarker?.removeFrom(this.map);
    }
    this.selectedAsset = null;
    this.selectedAssetMarker = null;
    this.refreshMap(this.assets, this.nearbyAssets, null);
  }

  setSelectedAsset(asset: ViewableAsset): void {
    if (asset?.latitude && asset?.longitude) {
      if (this.selectedAsset?.assetId !== asset.assetId) {
        this.followAssetEnabled = false;
        this.followAssetControl?.setDisplayNotFollowing();
      }
      this.selectedAsset = asset;
      this.updateSelectedAsset();
    } else {
      this.clearSelectedAsset();
      this.followAssetControl?.setDisplayHidden();
      return;
    }
  }

  updateSelectedAsset() {
    if (this.selectedAssetMarker && this.map.hasLayer(this.selectedAssetMarker)) {
      this.selectedAssetMarker?.removeFrom(this.map);
    }
    if (this.selectedAsset) {
      const positionMultiplier = this.getPositionMultiplier(this.map.getZoom());
      this.selectedAssetMarker = this.createPin(this.selectedAsset, true, positionMultiplier)
        ?.setZIndexOffset(10000)
        ?.addTo(this.map);
    }
  }

  handleFollowAssetControlClick() {
    this.followAssetEnabled = !this.followAssetEnabled;

    if (this.followAssetEnabled) {
      this.followAssetControl.setDisplayFollowing();
      this.flyToAutoFollowedAsset();
    } else {
      this.followAssetControl?.setDisplayNotFollowing();
    }
  }

  handleViewEntireTripControlClick() {
    this.store.dispatch(clearRecentPathSelectedSegment());
  }

  toggleIo(i: number, isDisplayed: boolean): void {
    if (isDisplayed) {
      this.ioFeatureGroups[i].addTo(this.map);
    } else {
      this.ioFeatureGroups[i].removeFrom(this.map);
    }
  }

  getEstimatedPathFeature(clickLatLng: LatLng, prevPoint: PathFeature, nextPoint: PathFeature): PathFeature {
    // `nextPoint` is undefined whenever a user hovers over the timeline and scrubs all the way to the end of the trip
    // in this case, we don't need to create an estimated point, and we can just return the 'prevPoint' instead
    if (!nextPoint) {
      return {
        ...prevPoint,
        geometry: { ...prevPoint.geometry, coordinates: latLng(clickLatLng.lat, clickLatLng.lng) }
      };
    }

    const prevPointLatLng = latLng(prevPoint.geometry.coordinates[0], prevPoint.geometry.coordinates[1]);
    const nextPointLatLng = latLng(nextPoint.geometry.coordinates[0], nextPoint.geometry.coordinates[1]);
    const distanceFromPrevPoint = clickLatLng.distanceTo(prevPointLatLng);
    const distanceFromNextPoint = clickLatLng.distanceTo(nextPointLatLng);
    const distanceBetweenPoints = distanceFromPrevPoint + distanceFromNextPoint;
    const prevPointWeighting = distanceFromNextPoint / distanceBetweenPoints;
    const nextPointWeighting = distanceFromPrevPoint / distanceBetweenPoints;

    return {
      type: '', // unused field for estimated data points
      geometry: {
        type: '', // unused field for estimated data points
        coordinates: latLng(clickLatLng.lat, clickLatLng.lng)
      },
      properties: {
        id: null, // unused field for estimated data points
        companyId: null, // unused field for estimated data points
        ecmConnected: null, // unused field for estimated data points
        stateOfCharge:
          prevPoint.properties.stateOfCharge * prevPointWeighting +
          nextPoint.properties.stateOfCharge * nextPointWeighting,
        inputs: [null], // unused field for estimated data points
        assetId: prevPoint.properties.assetId,
        geoHash: '', // unused field for estimated data points
        heading: getHeading(clickLatLng, nextPointLatLng),
        speedUnits: prevPoint.properties.speedUnits,
        speed: prevPoint.properties.speed * prevPointWeighting + nextPoint.properties.speed * nextPointWeighting,
        acceleration:
          prevPoint.properties.acceleration * prevPointWeighting +
          nextPoint.properties.acceleration * nextPointWeighting,
        odometer:
          prevPoint.properties.odometer * prevPointWeighting + nextPoint.properties.odometer * nextPointWeighting,
        state: PathStatusTypes.DRIVING, // assume the unit must have driven from prev to next point},
        timeStamp: interpolateTime(prevPoint.properties.timeStamp, nextPoint.properties.timeStamp, nextPointWeighting),
        powerOn: true // assume the unit must be powered on to travel from prev to next point
      }
    };
  }

  createEstimatedPathInMotionMarker(pathFeature: PathFeature) {
    const { properties } = pathFeature;
    const latlng = latLng(pathFeature.geometry.coordinates.lat, pathFeature.geometry.coordinates.lng);
    const marker = this.pathLayerConfigService.createPathInMotionMarker(latlng, properties.heading);
    this.bindPopup(marker, properties);
    this.pathLayerConfigService.reverseGeocodePopup(marker, pathFeature, true);

    return marker;
  }

  isZTrakDevice(recentPath: FeatureGroup<any> & { features?: TripFeature[] }): boolean {
    return recentPath?.features[0]?.properties?.state === PathStatusTypes.EQUIPMENT;
  }

  showRecentPathLayer(recentPath = this.currentPath) {
    if (this.viewSubContext !== DetailsSubcontext.HISTORY && this.pathLayer && this.map.hasLayer(this.pathLayer)) {
      this.pathLayer?.removeFrom(this.map);
      return;
    }
    const timeLine = {
      pathPoints: [],
      segmentSelected: false,
      segmentIndex: this.selectedSegment
    };

    if (!recentPath || recentPath.features?.length === 0) {
      this.timeLinePoints$.next(timeLine);
      return;
    }
    if (this.pathLayer && this.map.hasLayer(this.pathLayer)) {
      this.pathLayer?.removeFrom(this.map);
    }

    this.arrowIconMarkers = [];
    let bluePath: Array<Polyline>;
    const greyPath: Array<Polyline> = [];
    let whiteBorder: Array<Polyline>;
    const features = recentPath?.features;

    const buildPathBorder = features => {
      const border: Array<Polyline> = [];
      for (let i = 0; i < features.length - 1; i += 1) {
        border.push(
          new Polyline([features[i].geometry.coordinates, features[i + 1].geometry.coordinates], {
            className: 'selected-path-border'
          })
        );
      }
      return border;
    };

    const buildPath = (features, selected) => {
      const path: Array<Polyline> = [];
      const arrowIncrement = Math.floor(features.length * this.arrowFrequency);
      for (let i = 0; i < features.length - 1; i += 1) {
        const segment = new Polyline([features[i].geometry.coordinates, features[i + 1].geometry.coordinates], {
          className: selected ? 'selected-path-segment' : 'deselected-path-segment'
        });

        const eventType = this.isMobile ? 'click' : 'mouseover click';
        if (selected) {
          segment.on(eventType, clickEvent => {
            const e = clickEvent as LeafletMouseEvent;
            const estimatedPathFeature = this.getEstimatedPathFeature(e.latlng, features[i], features[i + 1]);
            const marker = this.createEstimatedPathInMotionMarker(estimatedPathFeature);
            this.onMouseover$.next(features[i]);
            marker.addTo(this.map);
          });
        }

        if (selected && i % arrowIncrement === 0 && i !== 0 && i !== features.length - 1) {
          const arrowIcon = L.polylineDecorator(segment, {
            patterns: [
              {
                symbol: L.Symbol.marker({
                  rotate: true,
                  markerOptions: {
                    icon: L.icon({
                      className: this.arrowIconClassName,
                      iconUrl: 'assets/leaflet/chevron_white.png',
                      iconAnchor: [10, 10],
                      iconSize: [20, 20]
                    })
                  }
                })
              }
            ]
          });
          this.arrowIconMarkers.push(arrowIcon);
        }
        path.push(segment);
      }
      return path;
    };

    if (!this.tripSegments.length) {
      this.currentPath = recentPath;
      let segmentIdx = 0;
      this.tripSegments.push([]);
      // break path features up into trip segments
      for (let i = 0; i < features.length; i += 1) {
        this.tripSegments[segmentIdx].push(features[i]);
        if (features[i].tripEnd) {
          segmentIdx += 1;
          this.tripSegments.push([]);
          this.tripSegments[segmentIdx].push(features[i]); // next trip starts at end of current.
        }
      }
    }

    if (!this.isZTrakDevice(recentPath)) {
      if (this.selectedSegment === null) {
        whiteBorder = buildPathBorder(features);
        bluePath = buildPath(features, true);
        this.currentFeatures = features;
        timeLine.pathPoints = features;
      } else {
        // if a trip segment is selected, in order for it to be rendered on top of the other segments it must be
        // added to the path last.
        for (let i = 0; i < this.tripSegments.length - 1; i += 1) {
          if (i !== this.selectedSegment) {
            const path = buildPath(this.tripSegments[i], false);
            greyPath.push(...path);
          }
        }
        whiteBorder = buildPathBorder(this.tripSegments[this.selectedSegment]);
        bluePath = buildPath(this.tripSegments[this.selectedSegment], true);
        timeLine.segmentSelected = true;
        timeLine.pathPoints = this.tripSegments[this.selectedSegment];
      }
      this.timeLinePoints$.next(timeLine);
    }

    this.pathLayer = new L.GeoJSON(features, this.pathLayerConfigService.recentPathGroupOptions);
    if (!this.isZTrakDevice(recentPath)) {
      greyPath.forEach(item => this.pathLayer.addLayer(item));
      whiteBorder.forEach(item => this.pathLayer.addLayer(item));
      bluePath.forEach(item => this.pathLayer.addLayer(item));
      this.arrowIconMarkers.forEach(item => this.pathLayer.addLayer(item));
    }
    if (this.isZTrakDevice(recentPath)) {
      this.hideTimelineControl();
    } else {
      this.showTimelineControl();
    }
    this.pathLayer.addTo(this.map);

    this.ioFeatureGroups = this.pathLayerConfigService.createIoFeatureGroups(features);
    this.zoomToFeatures(
      this.selectedSegment === null || this.isZTrakDevice(recentPath)
        ? features
        : this.tripSegments[this.selectedSegment]
    );
  }

  clearRecentPathLayer() {
    if (this.pathLayer && this.map.hasLayer(this.pathLayer)) {
      this.pathLayer?.removeFrom(this.map);
    }

    this.ioFeatureGroups.forEach(featureGroup => {
      featureGroup.removeFrom(this.map);
    });

    this.map?.closePopup();
    this.tripSegments = [];
    this.currentPath = null;
  }

  showClusterLayer() {
    this.clusterLayer?.addTo(this.map);
  }

  clearClusterLayer() {
    if (this.clusterLayer) {
      this.map.removeLayer(this.clusterLayer);
    }
  }

  showSelectedLocation(position: Position) {
    if (this.map) {
      this.map.setView(position, 16);
      this.updateSelectedLocation(position);
    }
  }

  updateSelectedLocation(position: Position) {
    this.clearSelectedLocationMarker();
    this.selectedLocationMarker = this.createLocationPin(position).setZIndexOffset(10000).addTo(this.map);
  }

  createLocationPin(position: Position): Marker {
    const pin = marker([position.lat, position.lng], {
      icon: L.icon({ iconUrl: 'assets/leaflet/place.png' }),
      zIndexOffset: 100,
      riseOnHover: false
    });
    return pin;
  }
  getMapCenter() {
    return this.map?.getCenter();
  }

  clearSelectedLocationMarker() {
    if (this.selectedLocationMarker && this.map.hasLayer(this.selectedLocationMarker)) {
      this.selectedLocationMarker.removeFrom(this.map);
    }
  }

  cancelPendingRequest(viewSubContext) {
    if (viewSubContext != DetailsSubcontext.HISTORY) {
      this.httpCancelService.cancelPathApiPendingRequests();
    }
    if (viewSubContext != DetailsSubcontext.LIVE) {
      this.httpCancelService.cancelLocApiPendingRequests();
    }
  }

  onHoverTimeline(feature: PathFeature) {
    const estimatedPathFeature = this.getPathFeature(feature);
    if (this.pathMarker && this.map.hasLayer(this.pathMarker)) {
      this.pathMarker.removeFrom(this.map);
    }
    this.pathMarker = this.createPathMarker(estimatedPathFeature);
    this.pathMarker.addTo(this.map);
  }

  getPathFeature(feature: PathFeature) {
    const latlng = new LatLng(feature.geometry.coordinates[0], feature.geometry.coordinates[1]);
    const index = this.currentFeatures.findIndex(x => x.properties.id === feature.properties.id);
    const nextPoint =
      index === this.currentFeatures.length - 1 ? this.currentFeatures[index] : this.currentFeatures[index + 1];
    const estimatedPathFeature = this.getEstimatedPathFeature(latlng, this.currentFeatures[index], nextPoint);
    const properties = {
      ...estimatedPathFeature?.properties,
      powerOn: this.currentFeatures[index].properties.powerOn,
      state: this.currentFeatures[index].properties.state,
      timeStamp: feature.properties.timeStamp
    };
    return { ...estimatedPathFeature, properties: properties };
  }

  createPathMarker(pathFeature: PathFeature) {
    const { properties } = pathFeature;
    const latlng = latLng(pathFeature.geometry.coordinates.lat, pathFeature.geometry.coordinates.lng);
    const marker = this.pathLayerConfigService.createPathMarkers(latlng, properties);
    this.onBindPopup$.next({
      marker: marker,
      pathFeature: pathFeature
    });
    return marker;
  }

  bindPopup(marker: Marker, properties: PathProperties) {
    marker
      .bindPopup(this.pathLayerConfigService.getRecentPathPopupLoading(properties))
      .on('popupclose', () => {
        if (marker && this.map.hasLayer(marker)) {
          marker.removeFrom(this.map);
        }
      })
      .on('add', () => {
        this.pathMarker?.removeFrom(this.map);
        marker.openPopup();
      });
  }

  onBindPopup() {
    this.onBindPopup$
      .pipe(
        debounceTime(200),
        filter(obj => Boolean(obj)),
        takeUntil(this.onDestroy$)
      )
      .subscribe((obj: BindPopUp) => {
        const { properties } = obj.pathFeature;
        const marker: Marker = obj.marker;
        marker.bindPopup(this.pathLayerConfigService.getRecentPathPopupLoading(properties));
        marker.openPopup();
        marker.on('popupclose', () => {
          if (marker && this.map.hasLayer(marker)) {
            marker.removeFrom(this.map);
          }
        });
        this.pathLayerConfigService.reverseGeocodePopup(marker, obj.pathFeature, true);
      });
  }

  getPositionMultiplier(zoomScale: number): number {
    if (zoomScale >= ZoomScales['1000Mts'] && zoomScale <= ZoomScales['500Mts']) {
      return 1.9;
    } else if (zoomScale > ZoomScales['500Mts'] && zoomScale <= ZoomScales['300Mts']) {
      return 1.7;
    } else if (zoomScale > ZoomScales['300Mts'] && zoomScale <= ZoomScales['100Mts']) {
      return 1.4;
    } else if (zoomScale > ZoomScales['100Mts'] && zoomScale <= ZoomScales['50Mts']) {
      return 1.1;
    } else if (zoomScale > ZoomScales['50Mts'] && zoomScale <= ZoomScales['30Mts']) {
      return 0.8;
    } else {
      return 0;
    }
  }
}
