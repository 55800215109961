import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ZonarUiIconModule } from '@zonar-ui/icon';
import { ZonarUiLozengeModule } from '@zonar-ui/lozenge';
import { PipesModule } from '@app/modules/pipes/pipes.module';

import { EventBadgeComponent } from './components/event-badge/event-badge.component';
import { SeverityToTypePipe } from '@app/modules/events/pipes/severity-to-type.pipe';
import { ExpandableEventDetailsComponent } from '@app/modules/events/components/expandable-event-details/expandable-event-details.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [EventBadgeComponent, SeverityToTypePipe, ExpandableEventDetailsComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ZonarUiIconModule,
    ZonarUiLozengeModule,
    PipesModule,
    NgxSkeletonLoaderModule
  ],
  exports: [EventBadgeComponent, SeverityToTypePipe, ExpandableEventDetailsComponent]
})
export class EventsModule {}
