import { Component } from '@angular/core';

@Component({
  selector: 'app-rotate-message',
  templateUrl: './rotate-message.component.html',
  styleUrls: ['./rotate-message.component.scss']
})
export class RotateMessageComponent {
  constructor() {}
}
