import { Injectable } from '@angular/core';
import { AutocompleteActions } from '@app/store/autocomplete/actions';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { selectAutocompleteResults } from '@app/store/autocomplete/selectors/autocomplete.selectors';
import { HttpCancelService } from '@app/services/http-cancel.service';
import { clearAutocomplete } from '@app/store/autocomplete/actions/autocomplete.actions';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteFacade {
  constructor(private store: Store<AppState>, private cancelService: HttpCancelService) {}

  search(searchTerm: string): void {
    this.store.dispatch(AutocompleteActions.fetchAutocompleteResults({ searchParams: { searchTerm } }));
  }

  getAutocompleteResults() {
    return this.store.select(selectAutocompleteResults);
  }

  clearAutocompleteResults() {
    this.store.dispatch(clearAutocomplete());
  }
}
