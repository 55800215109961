import { Injectable } from '@angular/core';
import vectorTileLayer from 'leaflet-vector-tile-layer';
import {
  VectorTileAuthHeaderValues,
  VectorTileFetchOptions,
  VectorTileLayer,
  VectorTileOptions,
  VectorZoneLabelProperties
} from '../zones.model';
import { EnvironmentService } from '@app/services/environment.service';
import { AuthService } from '@auth0/auth0-angular';
import { PermissionsService } from '@zonar-ui/auth';
import { combineLatest, map, mergeMap, Observable, of, ReplaySubject, tap } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { tileLayerBaseOptions } from '@app/modules/location/services/leaflet-layer-configs/leaflet-config.service';

@Injectable({
  providedIn: 'root'
})
export class VectorTileLayerService {
  constructor(
    private getEnvService: EnvironmentService,
    private authService: AuthService,
    private permsService: PermissionsService
  ) {}

  zoneLabelProperties$ = new ReplaySubject<VectorZoneLabelProperties>(100);

  _getVectorTileLayer: (url: string, options: VectorTileOptions) => VectorTileLayer = vectorTileLayer;

  url = `${this.getEnvService.getEnvironmentProperty('coreEntityApiBase').url}/zones/tiles/search`;

  color = '#2C2C8B';
  authToken: string = null;
  authTokenDecoded: { exp?: number } = null;

  _jwtDecode(token: string): { exp?: number } {
    return jwtDecode(token);
  }

  _getAuthToken$(): Observable<string> {
    return this.authService.getAccessTokenSilently().pipe(
      tap(token => {
        // cache the token so we don't do constant Auth0 calls
        this.authToken = token;
        this.authTokenDecoded = this._jwtDecode(token);
      }),
      map(token => token)
    );
  }

  getAuthToken$(): Observable<string> {
    if (!this.authToken && !this.authTokenDecoded) {
      return this._getAuthToken$();
    }

    // if dates are same, we're at or close to expiry, so fetch a new token
    // e.g. "Mon Jan 8" === "Mon Jan 8" would be the compared values

    const isTokenExpired = new Date(this.authTokenDecoded.exp).toDateString() === new Date().toDateString();

    // not likely to hit here as a user could likely refresh/login/logout enough to never get here, but just in case someone just leaves their tab open forever
    if (isTokenExpired) {
      return this._getAuthToken$();
    }

    // if none of above apply, just send the cached token
    return of(this.authToken);
  }

  getAuthorizationHeaderValues$(companyId: string): Observable<VectorTileAuthHeaderValues> {
    return combineLatest([this.getAuthToken$(), this.permsService.getZonarOwnerId()]).pipe(
      map(([token, ownerId]) => {
        const bearer = `Bearer ${token}`;
        return {
          bearer,
          ownerId
        };
      })
    );
  }

  reverseLatLong(latLong: number[]): number[] {
    return [latLong[1], latLong[0]];
  }

  setZoneLabelProps(feature) {
    const p = feature.properties;
    const centroid = JSON.parse(p.centroid);
    centroid.coordinates = this.reverseLatLong(centroid.coordinates);
    const props: VectorZoneLabelProperties = {
      area: p.area,
      category_id: p.category_id,
      centroid,
      color: p.color,
      geometry_type: p.geometry_type,
      id: p.id,
      name: p.name
    };
    this.zoneLabelProperties$.next(props);
  }

  setVectorTileOpts(fetchOptions: VectorTileFetchOptions): VectorTileOptions {
    return {
      fetchOptions,
      style: feature => {
        this.setZoneLabelProps(feature);
        return {
          weight: 1,
          color: feature.properties?.color || this.color,
          interactive: true,
          fill: false
        };
      },
      maxZoom: tileLayerBaseOptions.maxZoom,
      minZoom: tileLayerBaseOptions.minZoom
    };
  }

  getVectorTileLayer$(companyId: string, divisionIds: string[]): Observable<VectorTileLayer> {
    return this.getAuthorizationHeaderValues$(companyId).pipe(
      mergeMap(authHeaders => {
        const fetchOptions: VectorTileFetchOptions = {
          headers: {
            Authorization: authHeaders.bearer,
            'Zonar-Owner-ID': authHeaders.ownerId,
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({ companyIds: [companyId], divisionIds })
        };
        const url = `${this.url}?z={z}&x={x}&y={y}`;

        return of(this._getVectorTileLayer(url, this.setVectorTileOpts(fetchOptions)));
      })
    );
  }
}
