import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAssets from '../reducers/assets.reducer';
import { Trip } from '@app/modules/location/models/trip.model';
import { PathStatusTypes } from '@app/modules/location/models/path-api.model';

export const selectAssetState = createFeatureSelector<fromAssets.AssetState>(fromAssets.assetFeatureKey);

export const selectAssetParams = createSelector(selectAssetState, state => {
  return state.assetsParams;
});

export const selectAllAssets = createSelector(selectAssetState, state => {
  return state.assets;
});

export const selectAssetCount = createSelector(selectAssetState, state => {
  return state.assets.length;
});

export const selectAssetsLoadError = createSelector(selectAssetState, state => {
  return state.assetsLoadError;
});

export const selectAssetsLoadState = createSelector(selectAssetState, state => {
  return state.assetsLoadState;
});

export const selectChosenAsset = createSelector(selectAssetState, state => {
  return state.selectedAsset;
});

export const selectIsAssetSelected = createSelector(selectAssetState, state => {
  return Boolean(state.selectedAsset);
});

export const selectNearbyAssets = createSelector(selectAssetState, state => {
  return state.nearbyAssets;
});

export const selectNearbyAssetsLoadState = createSelector(selectAssetState, state => {
  return state.nearbyAssetsLoadState;
});

export const selectNearbyAssetsLoadError = createSelector(selectAssetState, state => {
  return state.nearbyAssetsLoadError;
});

export const selectNearbyAssetsEnabled = createSelector(selectAssetState, state => {
  return state.nearbyAssetsEnabled;
});

export const selectReverseGeocode = createSelector(selectAssetState, state => {
  return state.reverseGeocoding;
});

export const selectReverseGeocodeStatus = createSelector(selectAssetState, state => {
  return state.reverseGeocodingLoadState;
});

export const selectReverseGeocodeError = createSelector(selectAssetState, state => {
  return state.reverseGeocodingLoadError;
});

export const selectRecentPath = createSelector(selectAssetState, state => {
  return state.recentPath?.pointData;
});

export const selectRecentPathLoadState = createSelector(selectAssetState, state => {
  return state.recentPathLoadState;
});

export const selectRecentPathLoadError = createSelector(selectAssetState, state => {
  return state.recentPathLoadError;
});

export const selectRecentPathStartTime = createSelector(selectAssetState, state => state.recentPathStartTime);

export const selectDeviceIsZTrakFromPathData = createSelector(selectAssetState, state => {
  return state.recentPath?.pointData?.features[0]?.properties?.state === PathStatusTypes.EQUIPMENT;
});

export const selectPathSummary = createSelector(selectAssetState, state => {
  return state?.recentPath?.summary;
});

export const selectPathTrips = createSelector(selectAssetState, state => {
  if (!state.recentPath) {
    return [];
  }

  if (state.recentPath.pointData.features.length === 1) {
    return [
      {
        start: state.recentPath.pointData.features[0],
        end: state.recentPath.pointData.features[0],
        tripDistance: 0,
        tripDuration: 0,
        endIdx: 0
      }
    ];
  }

  const segments: Trip[] = [];
  let segIdx = 0;
  let segment;

  state.recentPath.pointData.features.forEach((f, i) => {
    if (!f.isZTrak) {
      if (f.tripStart) {
        segment = { start: f };
        if (segIdx > 0) {
          segment = {
            ...segment,
            timeSincePreviousTrip: f.timeSincePreviousTrip
          };
        }
      }
      if (f.tripEnd) {
        segment = {
          ...segment,
          tripDistance: f.tripDistance,
          tripDuration: f.tripDuration,
          end: f,
          endIdx: i
        };
        segments.push(segment);
        segIdx++;
      }
    } else {
      segments.push({ start: f });
    }
  });
  return segments;
});

export const selectSelectedPathSegment = createSelector(selectAssetState, state => state.recentPathSelectedSegment);

export const selectSelectedAssetLoadingState = createSelector(selectAssetState, state => state.selectedAssetLoadState);
