import { Injectable, ComponentFactoryResolver, ViewContainerRef, ComponentRef, Injector } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedMapComponent } from '@app/modules/shared-map/shared-map/shared-map.component';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';

@Injectable({ providedIn: 'root' })
export class SharedMapService {
  public mapAssetsLoadingState$ = new BehaviorSubject<ResourceLoadState>(ResourceLoadState.INITIAL);
  componentRef: ComponentRef<SharedMapComponent>;
  currentViewContainerRef: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private router: Router
  ) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SharedMapComponent);
    this.componentRef = componentFactory.create(this.injector);
    // Subscribe to the component's events
    this.componentRef.instance.mapsAssetsLoadedStateChanged$.subscribe((state: ResourceLoadState) => {
      this.mapAssetsLoadingState$.next(state);
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && this.currentViewContainerRef) {
        this.detach(this.currentViewContainerRef);
      }
      if (event instanceof NavigationEnd && this.currentViewContainerRef) {
        this.attach(this.currentViewContainerRef);
      }
    });
  }

  attach(viewContainerRef: ViewContainerRef) {
    this.currentViewContainerRef = viewContainerRef;
    viewContainerRef.insert(this.componentRef.hostView);
  }

  detach(viewContainerRef: ViewContainerRef) {
    viewContainerRef.detach(viewContainerRef.indexOf(this.componentRef.hostView));
  }

  getMapAssetsLoadingState(): Observable<ResourceLoadState> {
    return this.mapAssetsLoadingState$.asObservable();
  }
}
