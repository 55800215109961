import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ZonarUiIconModule } from '@zonar-ui/icon';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { SharedModule } from '../shared/shared.module';

import { DesktopSidebarComponent } from './components/desktop-sidebar/desktop-sidebar.component';
import { SidenavToggleBtnComponent } from '@app/modules/desktop/components/sidenav-toggle-btn/sidenav-toggle-btn.component';
@NgModule({
  declarations: [DesktopSidebarComponent, SidenavToggleBtnComponent],
  imports: [CommonModule, FlexLayoutModule, ZonarUIMaterialModule, ZonarUiIconModule, SharedModule],
  exports: [DesktopSidebarComponent]
})
export class DesktopModule {}
