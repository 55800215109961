import { Injectable } from '@angular/core';

// this service is to have an app-wide boolean that determines if a user should see loading animations (loading chip spinner plus asset list skeletons) when seeing assets view
// On app init and viewing assets list first, they should see these animations
// If they go back to viewing assets from the asset details page (clicking close button), they should NOT see animations, keeping the UX smooth and uninterrupted as they navigate between selected asset view/assets view
@Injectable({
  providedIn: 'root'
})
export class LoadingAnimationService {
  constructor() {}

  shouldShowAssetsLoadingAnimations: boolean = true;
  assetsFetched: boolean = false;
  shouldShowMainAppSpinner: boolean = true;
}
