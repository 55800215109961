import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  constructor() {}

  getEnvironment() {
    return environment;
  }

  getEnvironmentProperty(key) {
    // eslint-disable-next-line security/detect-object-injection
    return environment[key];
  }
}
