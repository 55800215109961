import { DomUtil } from 'leaflet';

const visibleClass = 'view-entire-trip';
const hiddenClass = 'hidden';

(function () {
  function defineLeafletViewEntireTrip(L) {
    L.Control.ViewEntireTrip = L.Control.extend({
      displayText: 'View Entire Trip',
      onAdd(map) {
        const container = L.DomUtil.create('div', `leaflet-control-view-entire-trip ${hiddenClass}`);
        const p = L.DomUtil.create('p', 'view-entire-trip-control-text', container);
        this.pElement = p;

        container.addEventListener('click', () => {
          this.fire('click');
        });

        container.addEventListener('touchstart', () => {
          this.fire('touchstart');
        });

        return container;
      }
    });

    L.extend(L.Control.ViewEntireTrip.prototype, L.Evented.prototype);

    L.Control.ViewEntireTrip.prototype.setDisplayVisible = function () {
      const container = this.getContainer();
      DomUtil.removeClass(container, hiddenClass);
      DomUtil.addClass(container, visibleClass);
      this.pElement.innerHTML = this.displayText;
    };

    L.Control.ViewEntireTrip.prototype.setDisplayHidden = function () {
      const container = this.getContainer();
      DomUtil.removeClass(container, visibleClass);
      DomUtil.addClass(container, hiddenClass);
      this.pElement.innerHTML = '';
    };

    L.control.viewEntireTrip = (opts, displayText) => {
      const viewControl = new L.Control.ViewEntireTrip(opts);
      viewControl.displayText = displayText;
      return viewControl;
    };
  }

  if (typeof define === 'function' && define.amd) {
    // Try to add leaflet.loading to Leaflet using AMD
    define(['leaflet'], function (L) {
      defineLeafletViewEntireTrip(L);
    });
  } else {
    // Else use the global L
    defineLeafletViewEntireTrip(L);
  }
})();
