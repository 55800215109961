import { Trips } from '@app/modules/location/models/trip.model';
import { ReverseGeocoderData } from '@app/modules/reverse-geocoder/services/models/reverse-geocoder-response.model';
import { createAction, props } from '@ngrx/store';
import { AssetsParams, Asset } from '@app/modules/location-client/location-api.models';
import { Location } from '@app/core/models/zonar-accounts.model';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { FeatureToggleService } from '@app/modules/feature-toggles/services/feature-toggle.service';

export const enum AssetsActionsTypes {
  LOAD_ASSETS = '[Asset] Load Assets',
  LOAD_ASSETS_FAILURE = '[Asset] Load Assets Failure',
  LOAD_ASSETS_SUCCESS = '[Asset] Load Assets Success',
  LOAD_ASSETS_FOR_INTERVAL_SUCCESS = '[Asset] Load Assets for Interval Success',
  LOAD_ASSETS_ON_UPDATED_FILTERS_SUCCESS = '[Asset] Load Assets for Updated Filter Success',
  LOAD_SELECTED_ASSET = '[Asset] Load Selected Asset',
  LOAD_SELECTED_ASSET_HOME_LOCATION = '[Asset] Load Selected Asset Home Location',
  LOAD_SELECTED_ASSET_HOME_LOCATION_FAILURE = '[Asset] Load Selected Asset Home Location Failure',
  LOAD_SELECTED_ASSET_HOME_LOCATION_SUCCESS = '[Asset] Load Selected Asset Home Location Success',
  LOAD_SELECTED_ASSET_REVERSE_GEOCODING = '[Asset] Load Selected Asset Reverse Geocoding',
  LOAD_SELECTED_ASSET_REVERSE_GEOCODING_FAILURE = '[Asset] Load Selected Asset Geocoding Failure',
  LOAD_SELECTED_ASSET_REVERSE_GEOCODING_SUCCESS = '[Asset] Load Selected Asset Geocoding Success',
  UPDATE_SELECTED_ASSET = '[Asset] Update Selected Asset',
  SET_ASSET_PARAMS_FOR_UPDATES = '[Asset] Set Asset Params for Updates',
  LOAD_NEARBY_ASSETS = '[Asset] Load Nearby Assets',
  LOAD_NEARBY_ASSETS_SUCCESS = '[Asset] Load Nearby Assets Success',
  LOAD_NEARBY_ASSETS_FAILURE = '[Asset] Load Nearby Assets Failure',
  TOGGLE_NEARBY_ASSETS_ENABLED = '[Asset] Toggle Nearby Assets Enabled',
  CLEAR_NEARBY_ASSETS = '[Asset] Clear Nearby Assets',
  LOAD_RECENT_PATH = '[Asset] Load Recent Path',
  LOAD_RECENT_PATH_SUCCESS = '[Asset] Load Recent Path Success',
  LOAD_RECENT_PATH_FAILURE = '[Asset] Load Recent Path Failure',
  SET_RECENT_PATH_START_TIME = '[Asset] Set Recent Path Start Time',
  SET_RECENT_PATH_SELECTED_SEGMENT = '[Asset] Set Recent Path Selected Segment',
  CLEAR_RECENT_PATH_SELECTED_SEGMENT = '[Asset] Clear Recent Path Selected Segment',
  CLEAR_RECENT_PATH = '[Asset] Clear Recent Path',
  STOP_ASSETS_POLLING = '[Asset] Stop Assets Polling',
  START_ASSETS_POLLING = '[Asset] Start Assets Polling',
  UPDATE_ASSETS = '[Asset] Update Assets',
  START_ASSETS_POLLING_INITIAL_LOAD = '[Asset] Start Assets Polling on initial load',
  LOAD_SELECTED_ASSET_BY_ID = '[Asset] Load Selected Asset by ID',
  LOAD_SELECTED_ASSET_SUCCESS = '[Asset] Load Selected Asset Success',
  LOAD_SELECTED_ASSET_FAILURE = '[Asset] Load Selected Asset Failure',
  CLEAR_SELECTED_ASSET = '[Asset] Clear Selected Asset',
  SEARCH_ASSETS = '[Asset] Search Assets'
}

export const loadAssets = createAction(AssetsActionsTypes.LOAD_ASSETS, props<{ assetsParams: AssetsParams }>());

export const loadAssetsForIntervalSuccess = createAction(
  AssetsActionsTypes.LOAD_ASSETS_FOR_INTERVAL_SUCCESS,
  props<{ response: Asset[]; assetsParams: AssetsParams }>()
);

export const loadAssetsFailure = createAction(
  AssetsActionsTypes.LOAD_ASSETS_FAILURE,
  props<{ assetsLoadError: any }>()
);

export const loadAssetsSuccess = createAction(AssetsActionsTypes.LOAD_ASSETS_SUCCESS, props<{ response: Asset[] }>());

export const loadAssetsOnUpdatedFiltersSuccess = createAction(
  AssetsActionsTypes.LOAD_ASSETS_ON_UPDATED_FILTERS_SUCCESS
);

export const loadSelectedAsset = createAction(
  AssetsActionsTypes.LOAD_SELECTED_ASSET,
  props<{ selectedAsset: ViewableAsset }>()
);

export const loadHomeLocation = createAction(
  AssetsActionsTypes.LOAD_SELECTED_ASSET_HOME_LOCATION,
  props<{ locationId: string }>()
);

export const loadHomeLocationFailure = createAction(
  AssetsActionsTypes.LOAD_SELECTED_ASSET_HOME_LOCATION_FAILURE,
  props<{ getLocationError: any }>()
);

export const loadHomeLocationSuccess = createAction(
  AssetsActionsTypes.LOAD_SELECTED_ASSET_HOME_LOCATION_SUCCESS,
  props<{ response: Location }>()
);

export const loadReverseGeocoding = createAction(
  AssetsActionsTypes.LOAD_SELECTED_ASSET_REVERSE_GEOCODING,
  props<{ latitude: number; longitude: number }>()
);
export const loadReverseGeocodingSuccess = createAction(
  AssetsActionsTypes.LOAD_SELECTED_ASSET_REVERSE_GEOCODING_SUCCESS,
  props<{ data: ReverseGeocoderData }>()
);
export const loadReverseGeocodingError = createAction(
  AssetsActionsTypes.LOAD_SELECTED_ASSET_REVERSE_GEOCODING_FAILURE,
  props<{ error: any }>()
);
export const updateSelectedAsset = createAction(
  AssetsActionsTypes.UPDATE_SELECTED_ASSET,
  props<{ selectedAsset: ViewableAsset }>()
);

export const setAssetParamsForUpdates = createAction(
  AssetsActionsTypes.SET_ASSET_PARAMS_FOR_UPDATES,
  props<{ assetsParams: AssetsParams }>()
);

export const loadNearbyAssets = createAction(
  AssetsActionsTypes.LOAD_NEARBY_ASSETS,
  props<{ selectedAsset: ViewableAsset }>()
);

export const loadNearbyAssetsFailure = createAction(
  AssetsActionsTypes.LOAD_NEARBY_ASSETS_FAILURE,
  props<{ nearbyAssetsLoadError: any }>()
);

export const loadNearbyAssetsSuccess = createAction(
  AssetsActionsTypes.LOAD_NEARBY_ASSETS_SUCCESS,
  props<{ response: ViewableAsset[] }>()
);

export const clearNearbyAssets = createAction(AssetsActionsTypes.CLEAR_NEARBY_ASSETS);

export const toggleNearbyAssetsEnabled = createAction(AssetsActionsTypes.TOGGLE_NEARBY_ASSETS_ENABLED);

export const loadRecentPath = createAction(
  AssetsActionsTypes.LOAD_RECENT_PATH,
  props<{ selectedAsset: ViewableAsset }>()
);

export const loadRecentPathFailure = createAction(
  AssetsActionsTypes.LOAD_RECENT_PATH_FAILURE,
  props<{ recentPathLoadError: any }>()
);

export const loadRecentPathSuccess = createAction(
  AssetsActionsTypes.LOAD_RECENT_PATH_SUCCESS,
  // Having to create a composite type because apparently leaflet's API does not have a feature(s) plural
  props<{ response: Trips }>()
);

export const setRecentPathStartTime = createAction(
  AssetsActionsTypes.SET_RECENT_PATH_START_TIME,
  props<{ startTime: Date }>()
);

export const setRecentPathSelectedSegment = createAction(
  AssetsActionsTypes.SET_RECENT_PATH_SELECTED_SEGMENT,
  props<{ segment: number }>()
);

export const clearRecentPathSelectedSegment = createAction(AssetsActionsTypes.CLEAR_RECENT_PATH_SELECTED_SEGMENT);

export const clearRecentPath = createAction(AssetsActionsTypes.CLEAR_RECENT_PATH);

export const startAssetsPolling = createAction(AssetsActionsTypes.START_ASSETS_POLLING);
export const startAssetsPollingOnInitialLoad = createAction(AssetsActionsTypes.START_ASSETS_POLLING_INITIAL_LOAD);

export const updateAssets = createAction(AssetsActionsTypes.UPDATE_ASSETS, props<{ assetsParams: AssetsParams }>());

export const stopAssetsPolling = createAction(AssetsActionsTypes.STOP_ASSETS_POLLING);

export const loadSelectedAssetSuccess = createAction(
  AssetsActionsTypes.LOAD_SELECTED_ASSET_SUCCESS,
  props<{ asset: Asset }>()
);

export const loadSelectedAssetFailure = createAction(
  AssetsActionsTypes.LOAD_SELECTED_ASSET_FAILURE,
  props<{ failure: any }>()
);

export const clearSelectedAsset = createAction(AssetsActionsTypes.CLEAR_SELECTED_ASSET);
