import { HttpEvent } from '@angular/common/http';
import { Division } from '@app/core/models/zonar-accounts.model';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { ReverseGeocoderData } from '@app/modules/reverse-geocoder/services/models/reverse-geocoder-response.model';
import { EnvironmentService } from '@app/services/environment.service';
import { LatLng } from 'leaflet';
import * as moment from 'moment';
import 'moment-duration-format';
import { GeometryTypes } from '@app/modules/zones/zones.model';
import { IDivision } from '@zonar-ui/auth/lib/models/company.model';
import * as uiUtils from '@app/services/ui-utilities';
export const MILES_PER_METER = 0.000621371;
export const KM_PER_METER = 0.001;
export const MPH_PER_MM_SEC = 0.0022;
export const KPH_PER_MM_SEC = 0.0036;
// Regions:
export const EUROPE = 'EU';
// Zoom Scales
export enum ZoomScales {
  '5000Kms' = 2,
  '2000Kms' = 3,
  '1000Kms' = 4,
  '500Kms' = 5,
  '300Kms' = 6,
  '100Kms' = 7,
  '50Kms' = 8,
  '30Kms' = 9,
  '20Kms' = 10,
  '10Kms' = 11,
  '5000Mts' = 12,
  '2000Mts' = 13,
  '1000Mts' = 14,
  '500Mts' = 15,
  // Henceforth the asset path is distinguishable
  '300Mts' = 16,
  '100Mts' = 17,
  '50Mts' = 18,
  '30Mts' = 19
}

export const round = (value: number, precision: number = 0): number => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const mmPerSecToMph = (speed): number => {
  // convert asset speed in mm/sec to mph rounded to nearest whole value
  return Math.round(speed * MPH_PER_MM_SEC);
};

export const mmPerSecToKph = (speed): number => {
  return Math.round(speed * KPH_PER_MM_SEC);
};

export const metersToMiles = (meters: number): number => {
  return meters * MILES_PER_METER;
};

export const metersToKm = (meters: number): number => {
  return meters * KM_PER_METER;
};

export const processDivisionsFromCompanyApi = (divisions: Division[]): Division[] => {
  return divisions.filter(d => d.type.startsWith('LEGACY') && d.name && d.name.length);
};

export const getUniqueValues = (collection: object[], key: string) => {
  const saved = {};
  return collection.reduce((acc, cur) => {
    if (!saved[cur[key]]) {
      saved[cur[key]] = cur;
      (acc as Array<any>).push(cur);
    }
    return acc;
  }, []);
};

export const getMinutesSinceTimeStamp = (timestamp: string): number => {
  if (!timestamp) {
    return 0;
  }

  const now = moment();
  const eventTs = moment(timestamp);
  const minutes = now.diff(eventTs, 'minutes');

  return minutes >= 0 ? minutes : 0;
};

export const getDistance = (from: number[], to: number[]): number => {
  // return distance in meters
  const toRadian = degree => (degree * Math.PI) / 180;
  const lon1 = toRadian(from[1]);
  const lat1 = toRadian(from[0]);
  const lon2 = toRadian(to[1]);
  const lat2 = toRadian(to[0]);

  const deltaLat = lat2 - lat1;
  const deltaLon = lon2 - lon1;

  const a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
  const c = 2 * Math.asin(Math.sqrt(a));
  const EARTH_RADIUS = 6371;
  return c * EARTH_RADIUS * 1000;
};

export const validGpsData = asset => {
  return asset ? Boolean(asset.geoEventTs && asset.latitude && asset.longitude) : false;
};

// asset display text utilities
export const getStateZipText = (geocodeData: ReverseGeocoderData) => {
  return `${geocodeData.district} ${geocodeData.zipcode}`;
};

export const getCityStateZipText = (geocodeData: ReverseGeocoderData) => {
  return geocodeData.locality
    ? `${geocodeData.locality}, ${geocodeData.district} ${geocodeData.zipcode}`
    : getStateZipText(geocodeData);
};

export const getAddressCityStateZipText = (geocodeData: ReverseGeocoderData) => {
  return geocodeData.locality
    ? `${geocodeData.address}, ${geocodeData.locality}, ${geocodeData.district} ${geocodeData.zipcode}`
    : getStateZipText(geocodeData);
};

export const timeTextFormatNa = 'hh:mm A';

export const formatTimestamp = (timeStamp: string, timeTextFormat = timeTextFormatNa): string => {
  const datetime = moment.utc(timeStamp);
  return datetime.isValid() ? datetime.local().format(timeTextFormat) : '';
};

export const getUtcToLocalTs = (timeStamp: string) => {
  const datetime = moment.utc(timeStamp);
  return datetime.isValid() ? datetime.local().format() : '';
};

export const dateTimeTextFormatNa = 'MM/DD/YYYY hh:mm:ss A';

export const getDateTimeText = (timeStamp: string, dateTimeTextFormat = dateTimeTextFormatNa): string => {
  const datetime = moment.utc(timeStamp);
  return datetime.isValid() ? datetime.local().format(dateTimeTextFormat) : '';
};

export const getStateOfChargeText = (stateOfCharge: number): string => {
  return stateOfCharge > 0 ? `${Math.round(stateOfCharge * 10) / 10}%` : '';
};

export const interpolateTime = (ts1, ts2, fractionElapsed) => {
  const utc1 = moment.utc(ts1);
  const utc2 = moment.utc(ts2);
  const diff = utc2.diff(utc1);
  return utc1.add(diff * fractionElapsed, 'milliseconds').toISOString();
};

// this method is a copy/pasta from leaflet.geometryutil package, since our application is unable to import
// the geometryutil package due to a known issue.
// geometryutil package import issue: https://github.com/makinacorpus/Leaflet.GeometryUtil/blob/master/src/leaflet.geometryutil.js#L689
// source for this method: https://github.com/makinacorpus/Leaflet.GeometryUtil/blob/master/src/leaflet.geometryutil.js#L689
export const getHeading = (latLng1: LatLng, latLng2: LatLng): number => {
  const rad = Math.PI / 180;
  const lat1 = latLng1.lat * rad;
  const lat2 = latLng2.lat * rad;
  const lon1 = latLng1.lng * rad;
  const lon2 = latLng2.lng * rad;
  const y = Math.sin(lon2 - lon1) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);

  const bearing = ((Math.atan2(y, x) * 180) / Math.PI + 360) % 360;
  return bearing * 100; // the api returns headings in 100ths of a degree, so we replicate that functionality here
};

// deepCopy function derived from the lodash deep clone function taken from
// https://frontbackend.com/javascript/what-is-the-the-fastest-way-to-deep-clone-an-object-in-javascript
export const deepCopy = item => {
  if (!item) {
    return item;
  } // null, undefined values check

  const types = [Number, String, Boolean];
  let result;

  // normalizing primitives if someone did new String('aaa'), or new Number('444');
  types.forEach(type => {
    if (item instanceof type) {
      result = type(item);
    }
  });

  if (typeof result === 'undefined') {
    if (Object.prototype.toString.call(item) === '[object Array]') {
      result = [];
      item.forEach((child, index, array) => {
        result[index] = deepCopy(child);
      });
    } else if (typeof item === 'object') {
      // testing that this is DOM
      if (item.nodeType && typeof item.cloneNode === 'function') {
        result = item.cloneNode(true);
      } else if (!item.prototype) {
        // check that this is a literal
        if (item instanceof Date) {
          result = new Date(item);
        } else {
          // it is an object literal
          result = {};
          // tslint:disable-next-line:forin
          for (const i in item) {
            result[i] = deepCopy(item[i]);
          }
        }
      } else {
        result = item;
      }
    } else {
      result = item;
    }
  }

  return result;
};

export const isCancelledRequest = (response: HttpEvent<any>) => Boolean(response.type === 0);

export const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index.toString()])
  );
};

export const isLocalDevEnv = () => {
  // tests also run in environment = local and localhost as domain name
  // but tests are localhost:9876 instead of localhost:5000
  return window?.location?.origin?.includes('localhost:5000') || window?.location?.origin?.includes('local.dev');
};

export const isEUEnv = (envService: EnvironmentService): boolean => {
  return envService.getEnvironment()?.region === EUROPE;
};

export const extractIds = (items: IDivision[]): string[] => {
  return items.map(item => item.id);
};

export function deduplicateArray<Type>(arr: Type[]): Type[] {
  return [...new Set(arr)];
}

export const intersection = (array1: any[], array2: any[]) => array1.filter(item => array2.includes(item));

export const isBoolean = val => {
  return typeof val === 'boolean';
};

export const getDriverName = (asset: ViewableAsset) => {
  const driverFirstName = asset?.driverFirstName;
  const driverLastName = asset?.driverLastName;
  if (driverFirstName && driverLastName) {
    return `${driverFirstName} ${driverLastName}`;
  }
  if (driverFirstName && !driverLastName) {
    return driverFirstName;
  }
  if (!driverFirstName && driverLastName) {
    return driverLastName;
  }
  return null;
};

export const toTitleCase = (str: string): string => {
  return str.replace(/([^\W_]+[^\s-]*) */g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const discardEmptyValues = entity => {
  if (entity?.value) return entity;
};

// RFC for geojson actually sets coordinates to long/lat instead of lat long. The Leaflet libraries do not automatically reverse this to be the correct lat/long so we have to manually reverse the coordinates ourselves. Yes, it sucks as much as this sounds.
// See https://www.rfc-editor.org/rfc/rfc7946.txt:  "A position is an array of numbers.  There MUST be two or more elements.  The first two elements are longitude and latitude...""
export const reverseLongLats = feature => {
  let reversedLatLng = [];

  if (feature.geometry.type === GeometryTypes.POINT) {
    reversedLatLng = [feature.geometry.coordinates[1], feature.geometry.coordinates[0]];
  } else {
    feature.geometry.coordinates.forEach(coord => {
      if (feature.geometry.type === GeometryTypes.MULTIPOLYGON) {
        coord.forEach(innerCoord => {
          innerCoord.forEach(ic => {
            const flipped = [ic[1], ic[0]];
            reversedLatLng.push(flipped);
          });
        });
      } else if (feature.geometry.type === GeometryTypes.LINESTRING) {
        reversedLatLng.push([coord[1], coord[0]]);
      }
      // regular basic Polygon (includes circles and rectangles)
      else {
        coord.forEach(c => {
          const flipped = [c[1], c[0]];
          reversedLatLng.push(flipped);
        });
      }
    });
  }

  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: reversedLatLng
    }
  };
};
