import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { GetCompanyDivisionsParams, GetCompanyParams } from '../core/models/zonar-accounts.model';
import { environment as env } from '@environments/environment';
import { forkJoin, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoreCompanyApiService {
  private COMPANY_RESOURCE = 'companies';
  private DIVISIONS_RESOURCE = 'divisions';
  private LOCATIONS_RESOURCE = 'locations';

  constructor(private http: HttpClient) {}
  private _getAll(url, params) {
    const firstPageParams: HttpParams = new HttpParams({ fromObject: { ...params, page: 1 } });
    const firstPage = this.http.get(url, { params: firstPageParams, observe: 'response' });

    return firstPage.pipe(
      switchMap(res => {
        const pageCount = +res.headers.get('x-page-count');
        const remainingPages = [];
        for (let i = 2; i <= pageCount; i++) {
          const nextPageParams: HttpParams = new HttpParams({ fromObject: { ...params, page: i } });
          const nextPage = this.http.get(url, { params: nextPageParams, observe: 'response' });
          remainingPages.push(nextPage);
        }
        return forkJoin([of(res), ...remainingPages]) as Observable<Array<HttpResponse<any>>>;
      }),
      map(allResponses => {
        return allResponses.reduce((acc, res) => [...acc, ...res.body], []);
      })
    );
  }

  private _getData(url: string, params?: any): Observable<any> {
    if (params) {
      const httpParams: HttpParams = new HttpParams({ fromObject: params as any });
      return this.http.get(url, { params: httpParams, observe: 'response' });
    }
    return this.http.get(url, { observe: 'response' });
  }

  getCompanies(params?: GetCompanyParams) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}`;
    return this._getData(url, params);
  }

  getCompanyDivisions(companyId: string, params?: GetCompanyDivisionsParams) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.DIVISIONS_RESOURCE}`;
    return this._getAll(url, params);
  }

  getDivisions(params?: GetCompanyDivisionsParams) {
    const url = `${env.coreEntityApiBase.url}/${this.DIVISIONS_RESOURCE}`;
    return this._getData(url, params);
  }

  getLocation(companyId, locationId, params?: GetCompanyDivisionsParams) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.LOCATIONS_RESOURCE}/${locationId}`;
    return this._getData(url, params);
  }
}
