import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { AutoSuggestionResponse, AutoSuggestRequest } from '../models/auto-suggestion.model';
import { EnvironmentService } from '@app/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class HereApiService {
  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  private _apiKey: string = this.envService.getEnvironmentProperty('here').apiKeyV3;
  url: string = 'https://autosuggest.search.hereapi.com/v1/autosuggest';

  public getAutoSuggestions(params: AutoSuggestRequest): Observable<AutoSuggestionResponse> {
    const fullParams = { ...params, apiKey: this._apiKey, limit: '5', lang: 'en' };
    const httpParams: HttpParams = new HttpParams({ fromObject: fullParams as any });
    return this.http.get<AutoSuggestionResponse>(this.url, { params: httpParams });
  }
}
