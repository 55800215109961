import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { LocationsComponent } from './modules/location/containers/locations/locations.component';
export const routes: Routes = [
  {
    path: 'ping',
    loadChildren: () => import('@app/modules/ping/ping.module').then(m => m.PingModule)
  },
  {
    path: 'assets/:assetId/history',
    loadChildren: () => import('@app/modules/asset-history/asset-history.module').then(m => m.AssetHistoryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'assets/:assetId/live',
    loadChildren: () =>
      import('@app/modules/asset-details-live-container/asset-details-live.module').then(m => m.AssetDetailsLiveModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'assets',
    canActivate: [AuthGuard],
    component: LocationsComponent
  },
  {
    path: 'location',
    redirectTo: 'assets',
    pathMatch: 'full'
  },

  {
    path: '',
    redirectTo: 'assets',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
