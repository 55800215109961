import { Directive, ViewContainerRef, OnInit } from '@angular/core';
import { SharedMapService } from '@app/services/shared-map.service';

@Directive({
  selector: '[appSharedMapSingleton]'
})
export class SharedMapDirective implements OnInit {
  constructor(private viewContainerRef: ViewContainerRef, private sharedMapService: SharedMapService) {}

  ngOnInit(): void {
    this.sharedMapService.attach(this.viewContainerRef);
  }
}
