import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewEncapsulation
} from '@angular/core';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Orientation } from '@app/modules/platform/store/reducers/platform.reducer';
import { AssetDetailsFacade } from '@app/modules/asset-details-live-container/facade/asset-details-facade.service';
@Component({
  selector: 'app-mobile-container',
  templateUrl: './mobile-container.component.html',
  styleUrls: ['./mobile-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MobileContainerComponent implements OnInit, OnChanges {
  constructor(public locationFacade: LocationFacade, public assetDetailsFacade: AssetDetailsFacade) {}

  showRotationMessage: boolean;
  onDestroy$ = new Subject<void>();
  @Input() isMapShown = true;
  @Input() text: string;
  @Input() showIcon: boolean = false;
  @Output() toggleMobileViewContext: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.locationFacade
      .getOrientation()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(orientation => {
        this.showRotationMessage = orientation === Orientation.LANDSCAPE;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isMapShown?.currentValue !== undefined) {
      this.isMapShown = changes?.isMapShown?.currentValue;
    }
  }
}
