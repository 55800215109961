import { animate, style, transition, trigger } from '@angular/animations';

export const onContentPaneChange = trigger('onContentPaneChange', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(-100%)' }),
    animate(
      '150ms',
      style({
        opacity: 1,
        transform: 'translateX(0)'
      })
    )
  ]),
  transition(':leave', [animate('150ms', style({ opacity: 0, transform: 'translateX(-100%)' }))])
]);
