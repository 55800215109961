import { Layer, Marker } from 'leaflet';

export enum ZoneStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ANY = 'ANY'
}

export enum GeometryTypes {
  POINT = 'Point',
  LINESTRING = 'LineString',
  POLYGON = 'Polygon',
  MULTIPOINT = 'MultiPoint',
  MULTILINESTRING = 'MultiLineString',
  MULTIPOLYGON = 'MultiPolygon'
}

export interface GTCxFeature {
  type: string;
  geometry: {
    type: string;
    coordinates: number[] | number[][] | number[][][] | number[][][][];
  };
  properties?: {
    visible?: boolean;
    name?: string;
    categoryId?: string;
    color?: string;
    categoryName?: string;
    id?: string;
  };
  id?: string;
}

export interface Zone {
  category?: Category;
  id: string;
  name: string;
  categoryId: string;
  status: string;
  geometry: {
    type: string;
    features: GTCxFeature[];
  };
  created?: string;
  modified?: string;
  managedEntity?: {
    managedSourceId?: string | null;
    managedSource?: string | null;
    externallyManaged?: boolean;
  };
}

export interface Category {
  id: string;
  name: string;
  companyId: string;
  divisions: string[];
  color: string;
  type: string;
  visible: boolean;
  status: string;
  created?: string;
  modified?: string;
  managedEntity?: {
    managedSourceId?: string;
    managedSource?: string;
    externallyManaged?: boolean;
  };
}

export enum Resources {
  ZONES = 'zones',
  CATEGORIES = 'categories'
}

export interface Centroid {
  type: GeometryTypes.POINT;
  coordinates: number[];
}

export interface VectorZoneLabelProperties {
  area: number;
  category_id: string;
  centroid: Centroid;
  color: string;
  geometry_type: GeometryTypes;
  id: string;
  name: string;
}

export interface GTCxZoneLabel extends Marker {
  zoneArea: number;
  id: string;
  name: string;
  properties: VectorZoneLabelProperties;
  latLong: number[];
  x?: number;
  y?: number;
  distance?: number;
}

export interface ZoneApiParams {
  companyId: string;
  divisionId?: string;
  categoryId?: string;
  q?: string;
  per_page?: number;
  sort?: string;
}

export interface VectorTileAuthHeaderValues {
  bearer: string;
  ownerId: string;
}

export interface VectorTileFetchOptions {
  headers: {
    Authorization: string;
    'Zonar-Owner-ID'?: string;
    'Content-Type'?: string;
  };
  method?: string;
  body?: string;
}

// interfaces reference: https://gitlab.com/jkuebart/Leaflet.VectorTileLayer#layer-options
export interface VectorTileOptions {
  fetchOptions?: VectorTileFetchOptions;
  style?: Function;
  minZoom?: number;
  maxZoom?: number;
}

export interface VectorTileLayer extends Layer {
  setStyle: Function;
}
