import { DomUtil, DomEvent } from 'leaflet';

const hiddenClass = 'hidden';

(function () {
  function defineLeafletTimelineControl(L) {
    L.Control.TimelineControl = L.Control.extend({
      onAdd(map) {
        const container = DomUtil.get('timelineControl');
        DomUtil.addClass(container, hiddenClass);
        DomEvent.disableClickPropagation(container);
        DomEvent.disableScrollPropagation(container);
        return container;
      }
    });

    L.extend(L.Control.TimelineControl.prototype, L.Evented.prototype);

    L.Control.TimelineControl.prototype.setDisplayVisible = function () {
      const container = this.getContainer();
      DomUtil.removeClass(container, hiddenClass);
    };

    L.Control.TimelineControl.prototype.setDisplayHidden = function () {
      const container = this.getContainer();
      DomUtil.addClass(container, hiddenClass);
    };

    L.control.timelineControl = opts => {
      return new L.Control.TimelineControl(opts);
    };
  }

  if (typeof define === 'function' && define.amd) {
    // Try to add leaflet.loading to Leaflet using AMD
    define(['leaflet'], function (L) {
      defineLeafletTimelineControl(L);
    });
  } else {
    // Else use the global L
    defineLeafletTimelineControl(L);
  }
})();
