import { Injectable } from '@angular/core';
import { EStatus, PermissionsService } from '@zonar-ui/auth';
import { EnvironmentService } from './environment.service';
import { IDivision, IDivisionMap } from '@zonar-ui/auth/lib/models/company.model';

@Injectable({ providedIn: 'root' })
export class DivisionMappingService {
  divisionMap: IDivisionMap;

  constructor(private permissionsService: PermissionsService, private envService: EnvironmentService) {
    this.permissionsService.getDivisionMap().subscribe(divisionMap => {
      this.divisionMap = divisionMap;
    });
  }

  public getActiveDivisions(): IDivision[] {
    if (this.divisionMap) {
      return Object.values(this.divisionMap).filter(division => division.status === EStatus.ACTIVE);
    } else {
      return [];
    }
  }

  public getChildDivisionsForParentIds(parentIds: string[]): IDivision[] {
    return this.getActiveDivisions().filter(division => parentIds.includes(division.parentId));
  }

  public getDivisionsForIds(divisionIds: string[]): IDivision[] {
    return this.getActiveDivisions().filter(division => divisionIds.includes(division.id));
  }

  private _getMatchingDivision = locationId => {
    return Object.values(this.divisionMap).find(division => {
      if (division.locationId === locationId) {
        return true;
      }
      return false;
    });
  };

  public getLocationNameById(locationId) {
    let matchingDivision;
    // need this if/else statement in case a user goes immediately to /assets/:id, the permissions have not been set yet due to async timing
    if (this.divisionMap) {
      matchingDivision = this._getMatchingDivision(locationId);
    } else {
      this.permissionsService.getDivisionMap().subscribe(divisionMap => {
        this.divisionMap = divisionMap;
        matchingDivision = this._getMatchingDivision(locationId);
      });
    }

    return matchingDivision?.name || null;
  }
}
