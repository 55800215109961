import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import {
  selectDevice,
  selectIsMobile,
  selectOrientation
} from '@app/modules/platform/store/selectors/platform.selectors';

@Injectable({
  providedIn: 'root'
})
export class PlatformFacade {
  constructor(private store: Store<AppState>) {}

  // ===== Store Selectors =====
  getDevice() {
    return this.store.select(selectDevice);
  }

  getOrientation() {
    return this.store.select(selectOrientation);
  }

  getIsMobile() {
    return this.store.select(selectIsMobile);
  }
}
