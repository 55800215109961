<div class="info-container">
<app-dialog class="info-dialog">
  <h2>
    {{ translateService.get(translations.appAssetList.assetList) | async | translate }}
  </h2>
  <p>
    {{ translateService.get(translations.appAssetList.assetListInfoText) | async | translate }}
  </p>
</app-dialog>
</div>
