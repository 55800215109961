<mat-chip-list class="filter-chip-list">
    <mat-chip
        class="filter-chip"
        *ngFor="let value of chipValues| slice:0:chipListSliceEnd; trackBy: trackFn"
        (removed)="removeChip(value)"
        [removable]="true"
        [value]="value.id">
        {{value.name}}
        <zui-icon icon="cancel" matChipRemove class="filter-chip-icon"></zui-icon>
    </mat-chip>
    <mat-chip
        class="more-filters-chip filter-chip"
        *ngIf="chipValues?.length > 4"
        (click)="moreFilters()">
        +{{chipValues.length - 3}} more filters
        <zui-icon icon="remove_red_eye" matChipTrailingIcon color="accent" class="filter-chip-icon"></zui-icon>
    </mat-chip>
</mat-chip-list>
