export const languageFallbackMap = [
  {
    languageAbbreviation: 'de',
    useLanguage: 'de-DE'
  },
  {
    languageAbbreviation: 'it',
    useLanguage: 'it-IT'
  },
  {
    languageAbbreviation: 'es',
    useLanguage: 'es-ES'
  },
  {
    languageAbbreviation: 'fr',
    useLanguage: 'fr-FR'
  },
  {
    languageAbbreviation: 'en',
    useLanguage: 'en-GB'
  },
  {
    languageAbbreviation: 'bg',
    useLanguage: 'bg-BG'
  },
  {
    languageAbbreviation: 'cs',
    useLanguage: 'cs-CS'
  },
  {
    languageAbbreviation: 'el',
    useLanguage: 'el-EL'
  },
  {
    languageAbbreviation: 'lt',
    useLanguage: 'lt-LT'
  },
  {
    languageAbbreviation: 'pl',
    useLanguage: 'pl-PL'
  },
  {
    languageAbbreviation: 'pt',
    useLanguage: 'pt-PT'
  },
  {
    languageAbbreviation: 'ro',
    useLanguage: 'ro-RO'
  },
  {
    languageAbbreviation: 'hr',
    useLanguage: 'hr-HR'
  },
  {
    languageAbbreviation: 'sk',
    useLanguage: 'sk-SK'
  },
  {
    languageAbbreviation: 'sl',
    useLanguage: 'sl-SL'
  },
  {
    languageAbbreviation: 'tr',
    useLanguage: 'tr-TR'
  },
  {
    languageAbbreviation: 'mk',
    useLanguage: 'mk-MK'
  },
  {
    languageAbbreviation: 'et',
    useLanguage: 'et-ET'
  }
];
