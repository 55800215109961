import { createAction, props } from '@ngrx/store';
import { DriverProfle } from '@app/modules/driver/models/driver.models';
import { DriverAsset } from '@app/modules/driver/models/driver.models';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';

export const enum DriverActionTypes {
  GET_DRIVER_PROFILE = '[Driver] Get Driver Profile',
  SET_DRIVER_PROFILE = '[Driver] Set Driver Profile',
  SET_DRIVER_PROFILE_FAILURE = '[Driver] Set Driver Profile Failure',
  SET_DRIVER_PROFILE_LOADING_STATUS = '[Driver] Set Driver Profile Loading Status',
  CLEAR_DRIVER_PROFILE = '[Driver] Clear Driver Profile',
  GET_DRIVER_ASSET_PROFILE = '[Driver] Get Driver Asset Profile',
  SET_DRIVER_ASSET_PROFILE = '[Driver] Set Driver Asset Profile',
  SET_DRIVER_ASSET_PROFILE_FAILURE = '[Driver] Set Driver Asset Profile Failure'
}

export const loadDriverProfile = createAction(
  DriverActionTypes.GET_DRIVER_PROFILE,
  props<{ driverProfileId: string }>()
);

export const loadDriverProfileSuccess = createAction(
  DriverActionTypes.SET_DRIVER_PROFILE,
  props<{ driverProfile: DriverProfle }>()
);

export const loadDriverProfileFailure = createAction(
  DriverActionTypes.SET_DRIVER_PROFILE_FAILURE,
  props<{ driverProfileFailure: any }>()
);

export const loadDriverAssetProfile = createAction(
  DriverActionTypes.GET_DRIVER_ASSET_PROFILE,
  props<{ asset: ViewableAsset }>()
);

export const loadDriverAssetProfileSuccess = createAction(
  DriverActionTypes.SET_DRIVER_ASSET_PROFILE,
  props<{ driverAssetProfile: DriverAsset[] }>()
);

export const loadDriverAssetProfileFailure = createAction(
  DriverActionTypes.SET_DRIVER_ASSET_PROFILE_FAILURE,
  props<{ driverAssetProfileFailure: any }>()
);

export const clearDriverProfile = createAction(DriverActionTypes.CLEAR_DRIVER_PROFILE);
