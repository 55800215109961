import { Component, OnInit } from '@angular/core';
import { PermissionsService } from '@zonar-ui/auth';
import { IUser } from '@zonar-ui/auth/lib/models/user.model';
import { IUserProfile } from '@zonar-ui/auth/lib/models/user-profile.model';
import { Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { IUserGroupPolicy } from '@zonar-ui/auth/lib/models/user-group-policy.model';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  user$: Subject<IUser> = new Subject<IUser>();
  userProfile$: Subject<IUserProfile[]> = new Subject<IUserProfile[]>();
  userPolicy$: Subject<IUserGroupPolicy[]> = new Subject<IUserGroupPolicy[]>();
  constructor(private permissions: PermissionsService) {}

  ngOnInit(): void {
    this.permissions
      .getIsPermissionsLoaded()
      .pipe(filter(is => is))
      .subscribe(_ => {
        this.permissions
          .getUsers()
          .pipe(first(users => Boolean(users.length)))
          .subscribe(users => {
            this.user$.next(users[0]);
          });
        this.permissions
          .getUserProfiles()
          .pipe(first(profiles => Boolean(profiles.length)))
          .subscribe(profiles => {
            this.userProfile$.next(profiles);
          });
        this.permissions
          .getUserGroupPolicies()
          .pipe(first(policies => Boolean(policies.length)))
          .subscribe(policies => {
            this.userPolicy$.next(policies);
          });
      });
  }
}
