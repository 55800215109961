import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { LatLngBounds, tileLayer } from 'leaflet';
import { Observable } from 'rxjs';
import { EnvironmentService } from '@app/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class HereTrafficService {
  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  private _apiKey = this.envService.getEnvironmentProperty('here').apiKeyV3;

  getIncidents(bounds: LatLngBounds): Observable<any> {
    const bbox = `bbox:${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`;
    const url = `https://data.traffic.hereapi.com/v7/incidents?in=${bbox}&locationReferencing=shape&apiKey=${this._apiKey}`;
    return this.http.request('GET', url);
  }

  getTrafficOverlay() {
    const url = `https://traffic.maps.hereapi.com/v3/flow/mc/{z}/{x}/{y}/png8?apiKey=${this._apiKey}`;
    return tileLayer(url, { maxZoom: 20, detectRetina: true });
  }
}
