import { ChangeDetectionStrategy, Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SearchFiltersControlModel } from '@app/modules/location/models/search-filters-control.model';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersComponent implements OnInit, DoCheck {
  constructor(private fb: UntypedFormBuilder, private locationFacade: LocationFacade) {}

  @Input() formControls: SearchFiltersControlModel[];

  @Output() getFilterChanges = new EventEmitter<any>();

  filtersFormGroup: UntypedFormGroup = this.fb.group({});
  formGroupKeys: string[];
  formGroupData = {};
  selections = {};
  formGroupOptionsLength = {};
  dropdownsSetFromState = false;

  private onDestroy$ = new Subject<void>();

  ngOnInit() {
    this.setFormGroupKeys();
    this.setFormGroupData();
    this.filtersFormGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(changes => {
      // form controls don't exist until some point within this subscription's timeline
      // we need to prevent calling it more than once AND deal with the potential for an infinite
      // loop caused by changing the dropdown within the dropdown change handler
      if (!this.dropdownsSetFromState && this.formControlsInitialized()) {
        this.setFormGroupValuesFromState(changes);
      }
      if (this.areObjectsIdentical(this.selections, changes)) {
        return;
      }

      this.selections = { ...this.selections, ...changes };

      this.getFilterChanges.emit(this.selections);
    });
  }

  formControlsInitialized() {
    // check that form controls exist for each control in this.formControls
    return this.formControls.every(control => this.filtersFormGroup.contains(control.controlName));
  }

  areObjectsIdentical(object1, object2) {
    if (JSON.stringify(object1) === JSON.stringify(object2)) {
      return true;
    }

    // handles the case of two objects that are essentially the same, but their keys are in different orders
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < Object.keys(object2).length; i++) {
      const key = Object.keys(object2)[i];
      const obj1Value = object1[key];
      const obj2Value = object2[key];
      if (obj2Value) {
        if (JSON.stringify(obj1Value) !== JSON.stringify(obj2Value)) {
          return false;
        }
      }
    }
    return true;
  }

  ngDoCheck() {
    if (this.formControls) {
      this.setFormGroupData();
    }
  }

  setFormGroupKeys() {
    if (this.formControls) {
      this.formGroupKeys = this.formControls.map(ctrl => ctrl.controlName);
    }
  }

  setFormGroupData() {
    if (this.formControls) {
      this.formControls.forEach(form => {
        const zform = {
          selectOptionsControlName: form.controlName,
          label: form.label || form.controlName,
          placeholder: form.placeholder || `Search by ${form.controlName}`,
          options: form.options,
          isMultiple: form.isMultiple === false ? false : true,
          sortByStringTitle: form.sortByStringTitle || false,
          disabled: true
        };
        this.formGroupOptionsLength[form.controlName] = zform.options.length;
        this.formGroupData[form.controlName] = zform;
      });
    }
  }

  setFormGroupValuesFromState(formChanges) {
    this.dropdownsSetFromState = true;
    this.locationFacade
      .getAllFilters()
      .pipe(take(1))
      .subscribe(filters => {
        Object.keys(this.filtersFormGroup.controls)
          .filter(controlName => filters.filter[controlName])
          .map(controlName => {
            switch (controlName) {
              case 'company':
                this.filtersFormGroup.controls.company.setValue(filters.filter.company.id);
                break;
              case 'divisions':
              case 'locations':
                this.filtersFormGroup.controls[controlName].setValue(filters.filter[controlName].map(f => f.id));
                break;
              default:
            }
          });
      });
  }
}
