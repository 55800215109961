import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { environment as env } from '@environments/environment';

import { PermissionsService } from '@zonar-ui/auth';
import { catchError, switchMap, take } from 'rxjs/operators';
import * as Settings from '@app/modules/location/models/settings.model';
import {
  defaultLocalStorageSettings,
  MapSettings,
  SettingInfo,
  SettingParams,
  SettingsMap,
  SettingsOwnerType
} from '@app/modules/location/models/settings.model';
import { DataDogService } from '@app/services/data-dog.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsApiService {
  SETTING_RESOURCE = 'settings';

  // TODO: Temporary adding the text "beta2" into url as workaround.
  apiUrl = `${env.coreEntityApiBase.url}beta2/${this.SETTING_RESOURCE}`;
  userProfileId: string;
  userSettingsCache: SettingsMap = new Map<MapSettings, SettingInfo>();
  userSettingsCache$ = new BehaviorSubject<SettingsMap>(this.userSettingsCache);
  localStorageKey = 'map_settings';
  constructor(
    private http: HttpClient,
    public permissionsService: PermissionsService,
    private datadog: DataDogService
  ) {
    this.getAllSettings().pipe(take(1)).subscribe();
  }

  getAllSettings(): Observable<Map<MapSettings, SettingInfo>> {
    if (this.userSettingsCache.size) {
      return of(this.userSettingsCache);
    }
    if (env.useSettingsApi) {
      return this.permissionsService.getUserProfiles().pipe(
        switchMap(profiles => {
          this.userProfileId = profiles[0].id;
          const params = {
            ownerId: profiles[0].id,
            ownerType: SettingsOwnerType.USERPROFILE,
            applicationId: env.auth.applicationId
          };
          const httpParams: HttpParams = new HttpParams({ fromObject: params as any });
          return this.http.get(this.apiUrl, { params: httpParams, observe: 'response' });
        }),
        switchMap(httpResults => {
          const results = httpResults.body as SettingInfo[];
          if (results && results.length >= 0) {
            (results as SettingInfo[]).forEach(r => this.userSettingsCache.set(r.name as MapSettings, r));
            this.userSettingsCache$.next(this.userSettingsCache);
            return of(this.userSettingsCache) as Observable<Map<MapSettings, SettingInfo>>;
          } else {
            return of(null);
          }
        }),
        catchError(err => {
          this.datadog.addRumError(new Error(err));
          return EMPTY;
        })
      );
    }
    // else use local storage
    this.userSettingsCache = this.localStorageArrayToMap();
    this.userSettingsCache$.next(this.userSettingsCache);
    return of(this.userSettingsCache);
  }
  getSetting(name: Settings.MapSettings): Observable<SettingInfo> {
    if (this.userSettingsCache.has(name)) {
      return of(this.userSettingsCache.get(name));
    }
    // if map size is > 0 but name isn't included then it hasn't been set yet.
    if (this.userSettingsCache.size > 0) {
      return of(null);
    }
    if (env.useSettingsApi) {
      if (this.userProfileId) {
        const params = {
          ownerId: this.userProfileId,
          ownerType: SettingsOwnerType.USERPROFILE,
          name
        };
        const httpParams: HttpParams = new HttpParams({ fromObject: params as any });
        return this.http.get(this.apiUrl, { params: httpParams, observe: 'response' }).pipe(
          switchMap(results => {
            if (results.body && results.body[0]) {
              this.cacheSetting(results.body[0] as SettingInfo);
              return of(results.body[0]) as Observable<SettingInfo>;
            } else {
              return of(null);
            }
          }),
          catchError(err => {
            this.datadog.addRumError(new Error(err));
            return EMPTY;
          })
        );
      }
      return this.permissionsService.getUserProfiles().pipe(
        switchMap(profiles => {
          this.userProfileId = profiles[0].id;
          const params = {
            ownerId: profiles[0].id,
            ownerType: SettingsOwnerType.USERPROFILE,
            name
          };
          const httpParams: HttpParams = new HttpParams({ fromObject: params as any });
          return this.http.get(this.apiUrl, { params: httpParams, observe: 'response' });
        }),
        switchMap(results => {
          if (results.body && results.body[0]) {
            this.cacheSetting(results.body[0] as SettingInfo);
            return of(results.body[0]) as Observable<SettingInfo>;
          } else {
            return of(null);
          }
        }),
        catchError(err => {
          this.datadog.addRumError(new Error(err));
          return EMPTY;
        })
      );
    }
    // local storage and settings not cached
    this.userSettingsCache = this.localStorageArrayToMap();
    if (this.userSettingsCache.has(name)) {
      return of(this.userSettingsCache.get(name));
    }
    return of(null);
  }

  updateSettingById(settingId: string, value): Observable<SettingInfo> {
    const params: SettingParams = {
      value,
      ownerType: SettingsOwnerType.USERPROFILE
    };
    const url = `${this.apiUrl}/${settingId}`;
    return this._updateSetting(url, params);
  }

  updateSettingByName(name: MapSettings, value): Observable<SettingInfo> {
    if (env.useSettingsApi) {
      const params: SettingParams = {
        value,
        ownerType: SettingsOwnerType.USERPROFILE
      };
      const settingId = this.userSettingsCache.get(name)?.id;
      if (!settingId) {
        return EMPTY;
      }
      const url = `${this.apiUrl}/${settingId}`;
      return this._updateSetting(url, params);
    }
    // use localStorage
    const settingInfo = {
      id: 'unused',
      ownerId: 'unused',
      ownerType: SettingsOwnerType.USERPROFILE,
      name,
      value
    };
    this.cacheSetting(settingInfo);
    return of(this.userSettingsCache.get(name));
  }

  _updateSetting(url: string, params: SettingParams): Observable<SettingInfo> {
    return this.http.patch(url, params, { observe: 'response' }).pipe(
      switchMap(patchResults => {
        if (patchResults.ok) {
          const httpParams = new HttpParams({ fromObject: { ownerType: SettingsOwnerType.USERPROFILE } });
          return this.http.get(url, { params: httpParams, observe: 'response' });
        }
        return EMPTY;
      }),
      switchMap(getResults => {
        if (getResults.ok) {
          this.cacheSetting(getResults.body as SettingInfo);
          return of(getResults.body as SettingInfo);
        }
        return EMPTY;
      })
    );
  }

  createSetting(params: SettingParams): Observable<SettingInfo> {
    return this.http.post(this.apiUrl, params, { observe: 'response' }).pipe(
      switchMap(results => {
        if (results.ok) {
          this.cacheSetting(results.body[0]);
          return of(results.body[0]);
        }
        return of(null);
      })
    );
  }

  saveSetting(settingName: MapSettings, value): Observable<SettingInfo> {
    if (this.userSettingsCache.has(settingName)) {
      const existingSetting = this.userSettingsCache.get(settingName);
      if (existingSetting.value !== value) {
        if (env.useSettingsApi) {
          return this.updateSettingById(existingSetting.id, value);
        } else {
          const newSetting = { ...existingSetting, value };
          this.cacheSetting(newSetting);
          return of(newSetting);
        }
      }
      return of(existingSetting);
    }
    if (env.useSettingsApi) {
      return this.getSetting(settingName).pipe(
        take(1),
        switchMap(result => {
          if (result) {
            return this.updateSettingById(result.id, value);
          }
          if (this.userProfileId) {
            const params = {
              ownerId: this.userProfileId,
              ownerType: SettingsOwnerType.USERPROFILE,
              applicationId: env.auth.applicationId,
              name: settingName,
              value
            };
            return this.createSetting(params);
          }
          return this.permissionsService.getUserProfiles().pipe(
            take(1),
            switchMap(profiles => {
              this.userProfileId = profiles[0].id;
              const params = {
                ownerId: this.userProfileId,
                ownerType: SettingsOwnerType.USERPROFILE,
                applicationId: env.auth.applicationId,
                name: settingName,
                value
              };
              return this.createSetting(params);
            })
          );
        })
      );
    }
    // local storage in use
    const settingInfo = {
      id: 'unused',
      ownerId: 'unused',
      ownerType: SettingsOwnerType.USERPROFILE,
      name: settingName,
      value
    };
    this.cacheSetting(settingInfo);
    return of(this.userSettingsCache.get(settingName));
  }

  cacheSetting(setting: SettingInfo) {
    if (!setting) {
      return;
    }
    this.userSettingsCache.set(setting.name as MapSettings, setting);
    this.userSettingsCache$.next(this.userSettingsCache);
    if (!env.useSettingsApi) {
      this.mapToLocalStorage(this.userSettingsCache);
    }
  }

  localStorageArrayToMap(): Map<MapSettings, SettingInfo> {
    const settingsString = localStorage.getItem(this.localStorageKey);
    const settings = settingsString ? JSON.parse(settingsString) : defaultLocalStorageSettings;
    if (!settingsString) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(settings));
    }
    const settingsMap = new Map<MapSettings, SettingInfo>(settings.map(s => [s.name, s]));
    // settings?.forEach(s => settingsMap.set(s.name as MapSettings, s));
    return settingsMap;
  }

  mapToLocalStorage(settingsMap) {
    const settingsArray = Array.from(settingsMap.values());
    localStorage.setItem(this.localStorageKey, JSON.stringify(settingsArray));
  }
}
