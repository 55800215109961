<mat-card>
  <div fxLayout='row' fxLayoutAlign='space-between' fxLayoutGap='1rem'>
    <div class='map-marker' fxFlex='5'>
      <ngx-skeleton-loader appearance='circle' [theme]="{width: '24px', height: '24px'}"></ngx-skeleton-loader>
    </div>
    <div fxFlex='50'>
      <ngx-skeleton-loader count='1' [theme]='{"width": "75%", "margin-bottom": "0px"}' ></ngx-skeleton-loader>
      <ngx-skeleton-loader count='1' ></ngx-skeleton-loader>

    </div>
    <div fxFlex='20'>
      <ngx-skeleton-loader></ngx-skeleton-loader>
    </div>
  </div>
</mat-card>
