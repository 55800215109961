import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Self
} from '@angular/core';
import { filter } from 'rxjs/operators';
import { EntityResource } from '@app/store/filters/models/filters.model';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { Translations } from '@app/core/services/i18n/translations.service';
import { TranslateService } from '@zonar-ui/i18n';

@Component({
  selector: 'app-filter-chip-list',
  templateUrl: './filter-chip-list.component.html',
  styleUrls: ['./filter-chip-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterChipListComponent implements OnInit, OnChanges {
  @Input() chipValues = new Array<EntityResource>();
  @Output() chipListEvent = new EventEmitter<boolean>();
  @Output() chipRemoved = new EventEmitter<string>();
  @Output() moreFiltersClick = new EventEmitter<boolean>();
  @Output() filterChipsHeight = new EventEmitter<any>();
  chipListSliceEnd;
  translated;
  filterText;

  constructor(
    private changeDetector: ChangeDetectorRef,
    @Self() private element: ElementRef,
    public translations: Translations,
    public translateService: TranslateService
  ) {
    this.translations.translationsLoadState
      .pipe(filter(loadstate => loadstate === ResourceLoadState.LOAD_SUCCESSFUL))
      .subscribe(() => {
        this.translated = this.translateService.instant([this.translations.powerfilter.powerOn]);
        this.filterText = this.translated[this.translations.powerfilter.powerOn];
      });
  }

  ngOnInit(): void {
    this.setChipListSliceEnd();
  }

  ngOnChanges(): void {
    this.chipListEvent.emit(true);
    this.setChipListSliceEnd();
    this.changeDetector.detectChanges();
    this.filterChipsHeight.emit(getComputedStyle(this.element.nativeElement).height);
  }

  removeChip(value) {
    this.chipValues = this.chipValues.filter(v => v !== value);
    this.setChipListSliceEnd();
    this.chipRemoved.emit(value);
    this.chipListEvent.emit(true);
    const height = getComputedStyle(this.element.nativeElement).height || '0px';
    this.filterChipsHeight.emit(height);
  }

  moreFilters() {
    this.moreFiltersClick.emit(true);
  }

  setChipListSliceEnd() {
    this.chipValues.find(chip => {
      if (chip.name == 'Powered On') {
        chip.name = this.filterText;
      }
    });
    this.chipListSliceEnd = this.chipValues?.length < 5 ? this.chipValues?.length : 3;
  }
  trackFn(index, value) {
    return value.id;
  }
}
