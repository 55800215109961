<mat-card data-e2e-asset-list-item data-cy="asset-list-item" class="asset-list-item-card" (mouseenter)="hoverOver.emit(asset)" (mouseleave)="hoverOut.emit()"
    (click)="itemClick.emit(asset)">

    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1rem">
        <div class="map-marker" fxFlex="10">
            <img alt="Heading {{asset.className}}" ngClass.gt-sm="marker-icon-web" ngClass.lt-md="marker-icon-mobile"
                class="{{ asset.className }}" src="{{ asset.iconUrl }}" />
        </div>
        <div fxFlex="60" class="asset-list-item-name-driver-container">
            <div class="asset-list-heading">{{ asset.assetName }}</div>
            <div class="asset-list-subtext" *ngIf="asset.driverName">{{asset.driverName}}</div>
        </div>
        <div fxFlex="30">
            <div class="asset-list-sidebar">{{ asset.sidebarMessage }}</div>
        </div>
    </div>
    <div *appIfFeatureEnabled="'voltron-live-enabled'" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1rem" data-cy="voltron-badge">
        <div fxFlex="10"></div>
        <div fxFlex="75" data-cy="app-event-badge"><app-event-badge [events]="asset.openEvents"></app-event-badge></div>
        <div fxFlex></div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1rem">
        <div fxFlex="10"></div>
        <div fxFlex="60">
            <div [ngClass]="!asset.connectionIssue && asset.geoEventTs && asset.longitude && asset.latitude ? 'asset-list-subtext' : 'missing-gps-data'">
                {{ asset.subTitle }}
            </div>
            <!-- <div>
                <app-asset-io-badge *ngIf="assetIoService.assetIoFeatureEnabled() && assetIoService.hasIoInputs(asset)"
                    [asset]="asset"></app-asset-io-badge>
            </div> -->
        </div>
        <div fxFlex="30"></div>
    </div>

</mat-card>
