<h4>{{ translateService.get(translations.appCompanyFilters.company) | async | translate }}</h4>
<ng-container>
    <app-chip-selection-list
        *ngIf="(searchFiltersDivisionsCount | async) > 1"
        #accountFilter
        [options]="accountAutocompleteOptions"
        (getChips)="getChips($event, 'divisions')"
        [label]="translateService.get(translations.appCompanyFilters.account) | async | translate"
        [sortByTitle]="true"
    >
    </app-chip-selection-list>

    <app-chip-selection-list
        #locationFilter
        [options]="locationAutocompleteOptions"
        (getChips)="getChips($event, 'locations')"
        [label]="translateService.get(translations.appCompanyFilters.location) | async | translate"
        [sortByTitle]="true"
    >
    </app-chip-selection-list>
</ng-container>
