import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAutocomplete from '../reducers/autocomplete.reducer';

export const selectAutocompleteState = createFeatureSelector<fromAutocomplete.AutocompleteState>(
  fromAutocomplete.autocompleteFeatureKey
);

export const selectAutocompleteResults = createSelector(selectAutocompleteState, state => {
  return { assets: state.assets, drivers: state.drivers };
});

export const selectAutocompleteSearchParams = createSelector(selectAutocompleteState, state => {
  return state.searchParams;
});

export const selectAutocompleteLoadState = createSelector(selectAutocompleteState, state => {
  return state.autocompleteLoadState;
});
