import { AssetsParams } from '@app/modules/location-client/location-api.models';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { ReverseGeocoderData } from '@app/modules/reverse-geocoder/services/models/reverse-geocoder-response.model';
import * as FilterActions from '@app/store/filters/actions/filters.actions';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { createReducer, on } from '@ngrx/store';
import * as moment from 'moment';
import * as AssetsActions from '../actions/assets.actions';
import { Trips } from '@app/modules/location/models/trip.model';

export const assetFeatureKey = 'assets';

export interface AssetState {
  assetsParams: AssetsParams;
  assets: ViewableAsset[];
  assetsLoadError: object;
  assetsLoadState: ResourceLoadState;
  nearbyAssets?: ViewableAsset[];
  nearbyAssetsLoadState?: ResourceLoadState;
  nearbyAssetsLoadError?: object;
  nearbyAssetsEnabled: boolean;
  recentPath?: Trips;
  recentPathLoadState?: ResourceLoadState;
  recentPathLoadError?: object;
  recentPathSelectedSegment?: number;
  reverseGeocoding?: ReverseGeocoderData;
  reverseGeocodingLoadState: ResourceLoadState;
  reverseGeocodingLoadError?: object;
  recentPathStartTime?: Date;
  selectedAsset?: ViewableAsset;
  selectedAssetLoadState?: ResourceLoadState;
  selectedAssetLoadError?: object;
}

export const initialState: AssetState = {
  assetsParams: null,
  assets: [],
  assetsLoadError: null,
  assetsLoadState: ResourceLoadState.INITIAL,
  nearbyAssetsEnabled: true,
  recentPathLoadState: null,
  reverseGeocoding: null,
  reverseGeocodingLoadState: ResourceLoadState.INITIAL,
  reverseGeocodingLoadError: null,
  recentPathStartTime: moment().startOf('day').toDate(),
  recentPathSelectedSegment: null
};

export const reducer = createReducer(
  initialState,

  on(AssetsActions.loadAssets, (state, { assetsParams }) => {
    return { ...state, assetsParams, assetsLoadState: ResourceLoadState.LOADING };
  }),

  on(AssetsActions.loadAssetsSuccess, (state, { response }) => {
    return {
      ...state,
      assets: response,
      assetsLoadError: null,
      assetsLoadState: ResourceLoadState.LOAD_SUCCESSFUL
    };
  }),

  on(AssetsActions.loadAssetsForIntervalSuccess, (state, { response }) => {
    let { selectedAsset } = state;
    if (selectedAsset) {
      const newAsset = response.find(asset => asset.assetId === selectedAsset.assetId);
      // creating a new object based on the current selected asset and what changed in the new asset.
      // as the spread operator is evaluated in order, this should update anything that has changed.
      selectedAsset = { ...selectedAsset, ...newAsset };
      return {
        ...state,
        assets: response,
        selectedAsset,
        assetsLoadError: null,
        assetsLoadState: ResourceLoadState.LOAD_SUCCESSFUL
      };
    }
    return {
      ...state,
      assets: response,
      assetsLoadError: null,
      assetsLoadState: ResourceLoadState.LOAD_SUCCESSFUL
    };
  }),

  on(AssetsActions.loadAssetsFailure, (state, { assetsLoadError }) => {
    return { ...state, assetsLoadError, assetsLoadState: ResourceLoadState.LOAD_FAILURE };
  }),

  on(AssetsActions.loadSelectedAsset, (state, { selectedAsset }) => {
    const nearbyAssets = selectedAsset ? state.nearbyAssets : null;
    return {
      ...state,
      selectedAsset,
      nearbyAssets,
      reverseGeocoding: null,
      reverseGeocodingLoadState: ResourceLoadState.INITIAL,
      reverseGeocodingLoadError: null,
      selectedAssetLoadState: ResourceLoadState.LOAD_SUCCESSFUL
    };
  }),

  on(AssetsActions.loadNearbyAssets, (state, { selectedAsset }) => {
    return { ...state, nearbyAssetsLoadState: ResourceLoadState.LOADING };
  }),

  on(AssetsActions.loadNearbyAssetsSuccess, (state, { response }) => {
    return {
      ...state,
      nearbyAssets: response,
      nearbyAssetsLoadError: null,
      nearbyAssetsLoadState: ResourceLoadState.LOAD_SUCCESSFUL
    };
  }),

  on(AssetsActions.loadNearbyAssetsFailure, (state, { nearbyAssetsLoadError }) => {
    return { ...state, nearbyAssetsLoadError, nearbyAssetsLoadState: ResourceLoadState.LOAD_FAILURE };
  }),

  on(AssetsActions.clearNearbyAssets, state => {
    return { ...state, nearbyAssets: null, nearbyAssetsLoadState: ResourceLoadState.INITIAL };
  }),

  on(AssetsActions.toggleNearbyAssetsEnabled, state => {
    return { ...state, nearbyAssetsEnabled: !state.nearbyAssetsEnabled };
  }),

  on(FilterActions.clearFilters, () => {
    return { ...initialState };
  }),

  on(AssetsActions.loadReverseGeocoding, (state, { latitude, longitude }) => {
    return { ...state, reverseGeocoding: null, reverseGeocodingLoadState: ResourceLoadState.LOADING };
  }),
  on(AssetsActions.loadReverseGeocodingSuccess, (state, { data }) => {
    return { ...state, reverseGeocoding: data, reverseGeocodingLoadState: ResourceLoadState.LOAD_SUCCESSFUL };
  }),
  on(AssetsActions.loadReverseGeocodingError, (state, { error }) => {
    return {
      ...state,
      reverseGeocoding: null,
      reverseGeocodingLoadState: ResourceLoadState.LOAD_FAILURE,
      reverseGeocodingLoadError: error
    };
  }),
  on(AssetsActions.updateSelectedAsset, (state, { selectedAsset }) => {
    return {
      ...state,
      selectedAsset
    };
  }),

  on(AssetsActions.loadRecentPath, state => {
    return {
      ...state,
      recentPathLoadState: ResourceLoadState.LOADING,
      recentPath: null,
      recentPathSelectedSegment: null
    };
  }),

  on(AssetsActions.loadRecentPathSuccess, (state, { response }) => {
    return { ...state, recentPath: response, recentPathLoadState: ResourceLoadState.LOAD_SUCCESSFUL };
  }),

  on(AssetsActions.loadRecentPathFailure, (state, { recentPathLoadError }) => {
    return { ...state, recentPathLoadError, recentPathLoadState: ResourceLoadState.LOAD_FAILURE };
  }),

  on(AssetsActions.clearRecentPath, state => {
    return {
      ...state,
      recentPath: null,
      recentPathLoadState: ResourceLoadState.INITIAL,
      recentPathSelectedSegment: null
    };
  }),

  on(AssetsActions.setRecentPathStartTime, (state, { startTime }) => {
    return { ...state, recentPathStartTime: startTime };
  }),

  on(AssetsActions.setRecentPathSelectedSegment, (state, { segment }) => {
    return { ...state, recentPathSelectedSegment: segment };
  }),

  on(AssetsActions.clearRecentPathSelectedSegment, state => {
    return { ...state, recentPathSelectedSegment: null };
  }),

  on(AssetsActions.loadSelectedAssetFailure, (state, error) => {
    return {
      ...state,
      selectedAssetLoadState: ResourceLoadState.LOAD_FAILURE,
      selectedAssetLoadError: error
    };
  }),

  on(AssetsActions.clearSelectedAsset, state => {
    return {
      ...state,
      selectedAsset: null,
      selectedAssetLoadState: ResourceLoadState.INITIAL,
      selectedAssetLoadError: null
    };
  })
);
