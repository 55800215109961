import { createReducer, on } from '@ngrx/store';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import * as PlacesAction from '../actions/places.actions';

import { AutoSuggestionResponse, AutoSuggestRequest } from '@app/modules/location/models/auto-suggestion.model';

export const placesFeatureKey = 'places';

export interface placesState {
  placesParams: AutoSuggestRequest;
  placesResult: AutoSuggestionResponse;
  placesLoadError: object;
  placesLoadState: ResourceLoadState;
}

export const initialState: placesState = {
  placesParams: null,
  placesResult: null,
  placesLoadError: null,
  placesLoadState: ResourceLoadState.INITIAL
};
export const reducer = createReducer(
  initialState,

  on(PlacesAction.loadPlacesResult, (state, { params }) => {
    return { ...state, params, placesLoadState: ResourceLoadState.LOADING };
  }),

  on(PlacesAction.loadPlacesResultSuccess, (state, { data }) => {
    return { ...state, placesResult: data, placesLoadState: ResourceLoadState.LOAD_SUCCESSFUL };
  }),

  on(PlacesAction.loadPlacesResultError, (state, { error }) => {
    return {
      ...state,
      placesResult: null,
      placesLoadState: ResourceLoadState.LOAD_FAILURE,
      placesLoadStateError: error
    };
  }),

  on(PlacesAction.clearPlacesResult, () => {
    return { ...initialState };
  })
);
