import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowser } from '@angular/platform-browser';
import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';

if (environment.production) {
  enableProdMode();
}
platformBrowser()
  .bootstrapModule(AppModule)
  .then(
    moduleRef => {
      // To enable angular change detector debugging uncomment below promise
      //   const applicationRef = moduleRef.injector.get(ApplicationRef);
      //   const componentRef = applicationRef.components[0];
      //   // allows to run `ng.profiler.timeChangeDetection();`
      //   enableDebugTools(componentRef);
    },
    error => {
      const el = document.getElementById('error-section');
      const spinnerEl = document.getElementById('app-spinner');
      console.log(`An error has occurred ${error}`);
      spinnerEl.hidden = true;
      el.hidden = false;
    }
  )
  .catch(err => console.error(err));
