import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/core/core.module';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { UserInfoComponent } from './components/user-info/user-info.component';

@NgModule({
  imports: [CommonModule, ZonarUIMaterialModule, CoreModule, ClipboardModule],
  declarations: [UserInfoComponent]
})
export class UserInfoModule {}
