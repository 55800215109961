import { InjectionToken } from '@angular/core';
import { FiltersState } from '@app/store/filters/models/filters.model';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '@environments/environment';
import * as fromAsset from './asset/reducers/assets.reducer';
import * as fromFilters from './filters/reducers/filters.reducer';
import * as fromPlatform from '../modules/platform/store/reducers/platform.reducer';
import * as fromPermissions from './permissions/reducers/permissions.reducer';
import * as fromPlaces from './places/reducers/places.reducer';
import * as fromRouter from './router/reducers/router.reducer';
import { initialPermissionsState, PermissionsState } from '@app/store/permissions/models/permissions.models';
import { routerReducer } from '@ngrx/router-store';
import * as fromDriver from './driver/reducer/driver.reducer';
import * as fromAutocomplete from './autocomplete/reducers/autocomplete.reducer';

export interface AppState {
  [fromAsset.assetFeatureKey]: fromAsset.AssetState;
  [fromPlaces.placesFeatureKey]: fromPlaces.placesState;
  [fromFilters.filtersFeatureKey]: FiltersState;
  [fromPlatform.platformFeatureKey]: fromPlatform.PlatformState;
  [fromPermissions.permissionsFeatureKey]: PermissionsState;
  [fromRouter.routerFeatureKey]: fromRouter.RouterState;
  [fromDriver.driverFeatureKey]: fromDriver.DriverState;
  [fromAutocomplete.autocompleteFeatureKey]: fromAutocomplete.AutocompleteState;
}

export const initialAppState = {
  [fromAsset.assetFeatureKey]: fromAsset.initialState,
  [fromPlaces.placesFeatureKey]: fromPlaces.initialState,
  [fromFilters.filtersFeatureKey]: fromFilters.initialFiltersState,
  [fromPlatform.platformFeatureKey]: fromPlatform.initialState,
  [fromPermissions.permissionsFeatureKey]: initialPermissionsState,
  [fromRouter.routerFeatureKey]: fromRouter.initialState,
  [fromDriver.driverFeatureKey]: fromDriver.initialState,
  [fromAutocomplete.autocompleteFeatureKey]: fromAutocomplete.initialState
};

export const reducers: ActionReducerMap<AppState> = {
  [fromAsset.assetFeatureKey]: fromAsset.reducer,
  [fromPlaces.placesFeatureKey]: fromPlaces.reducer,
  [fromFilters.filtersFeatureKey]: fromFilters.reducer,
  [fromPlatform.platformFeatureKey]: fromPlatform.reducer,
  [fromPermissions.permissionsFeatureKey]: fromPermissions.reducer,
  router: routerReducer,
  [fromDriver.driverFeatureKey]: fromDriver.reducer,
  [fromAutocomplete.autocompleteFeatureKey]: fromAutocomplete.reducer
};

export function stateSetter(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: any, action: any) {
    if (action.type === 'SET_ROOT_STATE') {
      return action.payload;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = environment.hmr ? [stateSetter] : [];

export const REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('App Reducers');
export const reducersProvider = { provide: REDUCERS_TOKEN, useValue: reducers };
