import { Component, OnDestroy, Input } from '@angular/core';
import { Map, Control, DomUtil, ControlPosition, LatLng } from 'leaflet';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
@Component({
  selector: 'app-incident-control',
  templateUrl: './incident-control.component.html',
  styleUrls: ['./incident-control.component.scss']
})
export class IncidentControlComponent implements OnDestroy {
  private _map: Map;
  public incidentControl: Control;
  @Input() position: ControlPosition;

  @Input()
  set map(map: Map) {
    if (map) {
      this._map = map;
      const IncidentControl = Control.extend({
        onAdd(map: Map) {
          return DomUtil.get('incidentControl');
        },
        onRemove(map: Map) {}
      });

      this.incidentControl = new IncidentControl({
        position: this.position
      }).addTo(map);
    }
  }
  get map(): Map {
    return this._map;
  }

  constructor(public translateService: TranslateService, public translations: Translations) {}

  ngOnDestroy() {
    this._map.removeControl(this.incidentControl);
  }

  onClick(e): void {
    const host = environment.gtcxUrl.incidentInvestigation;
    const redirectUri: string = encodeURI(location.href);
    const { lat, lng } = this.map.getCenter();
    const mapZoom: string = encodeURI(this.map.getZoom().toString());
    const url = `${host}?lat=${lat}&lng=${lng}&zoom=${mapZoom}&redirect_uri=${redirectUri}`;
    location.href = url;
  }
}
