import { AssetsParams, PostBodyConfig } from '@app/modules/location-client/location-api.models';
import { environment } from '@environments/environment';
import { isBoolean } from '@app/modules/shared/utilities/utilities';
import { FiltersState } from '@app/store/filters/models/filters.model';

/**
 * This method should be used until we refactor filter state. Takes the FilterState and converts it to PostBodyConfig.
 * Can optionally feed an override config which will use values from that instead of the FiltersState on a per-property basis.
 * @param filters the filter state object
 * @param overrides (optional) a PostBodyConfig containing those props for which you want to override filter state
 */
export const postBodyConfigFromFilters = (filters: FiltersState, overrides?: PostBodyConfig): PostBodyConfig => {
  const divisions = overrides?.divisionIds
    ? overrides.divisionIds
    : []
        .concat(
          filters.filter.divisions.filter(d => d.legacyType === 'LEGACY'),
          filters.filter.locations
        )
        .map(d => d.id);

  const geofeature = overrides?.geojson
    ? overrides.geojson
    : filters.filter.zone
    ? JSON.stringify(
        (filters.zone.geometry.features.find(f => f.properties.name == 'GEOMETRY') || filters.zone.geometry.features[0])
          .geometry
      )
    : undefined;

  return {
    companyId: overrides?.companyId ? overrides.companyId : filters.filter.company.id,
    divisionIds: divisions.length ? divisions : undefined,
    powerOn: overrides?.powerOn ? overrides.powerOn : filters.filter.powerOn ? true : undefined, // API can't handle 'false' powerOn
    sortBy: overrides?.sortBy ? overrides.sortBy : filters.filter.sortAttribute,
    sortOrder: overrides?.sortOrder ? overrides.sortOrder : filters.filter.sortOrder,
    searchTerms: overrides?.searchTerms ? overrides.searchTerms : filters.filter.searchTerms,
    geojson: geofeature,
    pageSize: overrides?.pageSize ? overrides.pageSize : environment.liveUpdate.pageSize,
    offset: overrides?.offset ? overrides.offset : undefined,
    northEast: overrides?.northEast ? overrides.northEast : filters.filter.northEast,
    southWest: overrides?.southWest ? overrides.southWest : filters.filter.southWest
  };
};

// TODO: deprecate when we can. These params are used to some extent by the autocomplete service, which we cannot break in the near term
// method is used in assets.effects, autocomplete.effects, filter.effects
export const buildAssetsParams = (params: AssetsParams, filters?: FiltersState): AssetsParams => {
  const sharedParams = {
    pageSize: environment.liveUpdate.pageSize
  };

  // this must be a direct query for a specific asset
  if (params?.assetIds?.length) {
    return {
      ...sharedParams,
      assetIds: params.assetIds
    };
  }

  // this must be a direct query for a specific driver
  if (params?.driverProfileId) {
    const assetParams: AssetsParams = {
      ...sharedParams,
      driverProfileId: params.driverProfileId,
      companyId: params.companyId,
      active: true
    };
    if (params?.divisionId) {
      assetParams.divisionId = params.divisionId;
    }
    return assetParams;
  }

  const newAssetParams = <AssetsParams>{
    ...sharedParams,
    requireDevice: true
  };
  newAssetParams.companyId = params?.companyId ? params.companyId : filters.filter?.company?.id;
  newAssetParams.active = params?.active ? params.active : true;
  delete newAssetParams.divisionIds;
  delete newAssetParams.accountDivisions;
  const divisionIds = filters.filter.locations.map(l => l.id);
  const accountDivisions = filters.filter.divisions.filter(l => l.legacyType === 'LEGACY').map(l => l.id);
  if (divisionIds.length) {
    newAssetParams.divisionIds = divisionIds;
  } else if (accountDivisions.length) {
    newAssetParams.accountDivisions = accountDivisions;
  }
  if (isBoolean(filters.filter?.powerOn) && filters.filter.powerOn) {
    newAssetParams.powerOn = filters.filter.powerOn;
  } else {
    delete newAssetParams.powerOn;
  }
  if (filters.filter?.driver?.id) {
    newAssetParams.driverProfileId = filters.filter.driver.id;
  }
  newAssetParams.searchTerms = filters.filter?.searchTerms;
  if (filters.filter.zone) {
    // Note: Location API Rx uses Postgis function which accepts only the geometry portion of a single feature (not entire geojson)
    let geometryFeature =
      filters.zone.geometry.features.find(f => f.properties.name == 'GEOMETRY') || filters.zone.geometry.features[0];
    newAssetParams.geojson = JSON.stringify(geometryFeature.geometry);
  }

  return newAssetParams;
};

export const objectShallowEquality = <Type>(previous: Type, next: Type): boolean => {
  // https://blog.bitsrc.io/how-to-compare-objects-in-javascript-f4eafef807fc
  let len = null;

  if (!previous || !next) {
    return false;
  }
  if (Object.keys(previous).length !== Object.keys(next).length) {
    return false;
  } else {
    len = Object.keys(previous).length;
  }
  let matches = 0;
  Object.keys(previous).forEach(key => {
    if (previous[key] === next[key]) {
      matches += 1;
    }
  });
  return matches === len;
};
