// Angular
import { Injectable } from '@angular/core';

// ngrx
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// RxJS
import { of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

// App
import { AppState } from '@app/store';
import * as AssetActions from '../../asset/actions/assets.actions';
import * as FiltersActions from '../actions/filters.actions';
import * as fromFilters from '../selectors/filters.selectors';
import * as SearchFiltersActions from '@app/store/filters/actions/search-filters.actions';
import { environment as env } from '@environments/environment';
import { buildAssetsParams } from '@app/modules/location-client/utilities';
import { selectAssetParams } from '@app/store/asset/selectors/assets.selectors';

@Injectable()
export class FilterEffects {
  constructor(private actions$: Actions, private store: Store<AppState>) {}

  loadAssetsOnUpdatedFilters$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          FiltersActions.applyFilters,
          FiltersActions.applySorting,
          FiltersActions.removeFilter,
          FiltersActions.clearFilters
        ),
        concatLatestFrom(() => [this.store.select(fromFilters.selectAllFilters), this.store.select(selectAssetParams)]),
        map(([_, filters, params]) => {
          const assetsParams = buildAssetsParams(params, filters);
          if (assetsParams?.companyId) {
            this.store.dispatch(AssetActions.loadAssets({ assetsParams }));
            this.store.dispatch(AssetActions.loadAssetsOnUpdatedFiltersSuccess());
          }
        })
      );
    },
    { dispatch: false }
  );

  setCompanyFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FiltersActions.setCompany),
      concatLatestFrom(() => [this.store.select(fromFilters.selectCompany)]),
      filter(([action, companyFilter]) => action?.company !== companyFilter?.id),
      switchMap(([action, companyFilter]) => {
        return [
          SearchFiltersActions.clearSearchFiltersDivisions(),
          FiltersActions.clearDivisions(),
          FiltersActions.clearZone(),
          AssetActions.loadSelectedAsset(null),
          SearchFiltersActions.getSearchFiltersDivisions({ companyId: action.company })
        ];
      })
    )
  );

  // If we clear a company filter as a zonar user, we should also reset the division state.
  clearDivisionsOnClearCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FiltersActions.clearCompany),
      switchMap(_ => of(FiltersActions.clearDivisions()))
    )
  );

  clearZoneOnClearCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FiltersActions.clearCompany),
      switchMap(_ => of(FiltersActions.clearZone()))
    )
  );

  loadLocationsOnSetDivisionsFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FiltersActions.setDivisions),
      concatLatestFrom(() => this.store.select(fromFilters.selectAllFilters)),
      switchMap(([action, filters]) => {
        const { divisions } = action;
        if (divisions?.length !== 1) {
          return of(SearchFiltersActions.clearSearchFiltersLocations());
        }
        const div = filters.divisions.find(d => d.id === divisions[0]);
        return of(
          SearchFiltersActions.getSearchFiltersLocations({
            companyId: div.companyId,
            legacyAccountCode: div.legacyAccountCode,
            params: { page: 1, per_page: env.apiRequestPageSize }
          })
        );
      })
    )
  );
}
