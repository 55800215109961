import { Injectable } from '@angular/core';
import * as PermissionsActions from '../actions/permissions.actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { PermissionsService } from '@zonar-ui/auth';
import { Perms } from '@app/store/permissions/models/permissions.models';
import { selectAllPermissions } from '@app/store/permissions/selectors/permissions.selectors';
import { CoreCompanyApiService } from '@app/services/core-company-api.service';
import { DataDogService } from '@app/services/data-dog.service';
import { ICompany, IDivision, IDivisionMap } from '@zonar-ui/auth/lib/models/company.model';

@Injectable()
export class PermissionsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private companyApiService: CoreCompanyApiService,
    private permissionsService: PermissionsService,
    private dataDog: DataDogService
  ) {}

  loadPermissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PermissionsActions.loadPermissions),
      switchMap(_ => {
        return combineLatest([
          this.permissionsService.getIsPermissionsLoaded(),
          this.permissionsService.hasPermission(Perms.READ_COMPANY),
          this.permissionsService.getIsZonarUser(),
          this.permissionsService.getDivisionMap(),
          this.permissionsService.getCompaniesForPerm('read', 'company'),
          this.permissionsService.getLegacyAccountsForPerm('read', 'company'),
          this.permissionsService.getLegacyLocationsForPerm('read', 'company'),
          this.permissionsService.getRequestStartTime()
        ]);
      }),
      filter(
        ([permsLoaded, readCompany, isZonarUser, divMap, companies, explicitAccounts, locations, requestStartTime]: [
          boolean,
          boolean,
          boolean,
          IDivisionMap,
          [ICompany],
          [IDivision],
          [IDivision],
          number
        ]) => {
          return !!permsLoaded && !!companies && !!explicitAccounts && !!locations && !!divMap;
        }
      ),
      mergeMap(
        ([
          permsLoaded,
          readCompany,
          isZonarUser,
          divisionMap,
          companies,
          explicitAccounts,
          locations,
          requestStartTime = 0
        ]: [boolean, boolean, boolean, IDivisionMap, [ICompany], [IDivision], [IDivision], number]) => {
          const companyIds = Array.from(new Set(companies.map(co => co.id)));
          const readCompanies = isZonarUser || companyIds.length > 1;
          if (isZonarUser) {
            return of(
              PermissionsActions.savePermissions({
                readCompany,
                readCompanies,
                isZonarUser,
                divisionMap,
                companies: [],
                divisions: [],
                locations: [],
                requestStartTime
              })
            );
          }

          // some location-locked users will have access to a location, but not the parent account for that location.
          // essentially, although they do not have explicit access to the parent location, they have implicit access to it
          // such implicit accounts must appear in the account select dropdown, so we fetch them here
          const explicitLegacyAccountCodes = new Set(explicitAccounts.map(account => account.legacyAccountCode));
          const implicitLegacyAccountCodes = new Set(
            locations
              .map(l => l.legacyAccountCode)
              .filter(legacyAccountCode => Boolean(legacyAccountCode))
              .filter(legacyAccountCode => !explicitLegacyAccountCodes.has(legacyAccountCode))
          );

          const implicitAccountRequests: Observable<any>[] = [...implicitLegacyAccountCodes].map(accountCode => {
            return this.companyApiService.getDivisions({ type: 'LEGACY', legacyAccountCode: accountCode });
          });

          if (implicitAccountRequests.length === 0) {
            return of(
              PermissionsActions.savePermissions({
                readCompany,
                readCompanies,
                isZonarUser,
                divisionMap,
                companies,
                divisions: explicitAccounts,
                locations,
                requestStartTime
              })
            );
          }

          return forkJoin(implicitAccountRequests).pipe(
            map(responses => {
              const implicitAccounts = responses.reduce((acc, res) => acc.concat(res.body), []);
              const allAccounts = [...explicitAccounts, ...implicitAccounts];

              return PermissionsActions.savePermissions({
                readCompany,
                readCompanies,
                isZonarUser,
                divisionMap,
                companies,
                divisions: allAccounts,
                locations,
                requestStartTime
              });
            })
          );
        }
      )
    );
  });

  loadEntitiesOnSavePerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionsActions.savePermissions),
      concatLatestFrom(() => this.store.select(selectAllPermissions)),
      switchMap(([_, { isZonarUser, companies, divisions }]) => {
        // for zonar users both these properties should be []
        return of(
          PermissionsActions.saveEntities({
            companies: isZonarUser ? [] : companies,
            divisions: isZonarUser ? [] : divisions
          })
        );
      })
    )
  );
}
